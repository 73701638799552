import React from "react";
import { Outlet } from "react-router-dom";
import Navbar from "../Components/Navbar/Navbar";
import Cookies from "../Components/Cookies/Cookies";
import KijekaButton from "../Components/KijekaButton/KijekaButton";
import Footer from "../Components/Footer/Footer";

const Home = () => {
  return (
    <div>
      <Navbar />
      <Outlet />
      <Cookies />
      <KijekaButton />
      <Footer />
    </div>
  );
};

export default Home;
