import React, { useEffect } from "react";
import LikeShare from "./LikeShare";
import AOS from "aos";
import parse from "html-react-parser";
import Stars from "./Stars";
const ProductCard = ({ title, link, description, img, rating, stars, setProductShown, index, liked }) => {
	useEffect(() => {
		AOS.init();
	}, []);
	return (
		// <Link to='/product-details'>
		<div data-aos="fade-down" data-aos-duration="1000" className="w-auto pb-[26px] px-4 h-[230px] rounded-[10px] border-[2px] hover:border-[#004B95] drop-shadow bg-white shadow-[0px_4px_20px_rgba(166,166,166,0.3)]">
			<div className="flex justify-between items-center ">
				<a href={"/product/" + link} className={`text-start font-poppins font-bold text-[16px] leading-[131%] tracking-[0.055em] text-[#004B95]`}>
					{title}
				</a>
				<LikeShare liked={liked} title={title} isCategory={false} link={"/product/" + link} image={img} />
			</div>
			<a href={"/product/" + link}>
				<img className="w-[80%] mx-[10%] h-[30%] object-contain relative" src={img} alt="/" />
			</a>
			{/* rating */}
			<Stars rating={rating} stars={stars} />
			{/* description */}
			{/* <a href={"/product/" + link} className="font-normal text-left text-[10px] pt-[3px] pb-[11px] leading-[14.35px] tracking-[5.5%] text-[#004B95] discriptionTruncate h-12 mb-3">
				{parse(description)}
			</a> */}

			<div className="text-center ">
				<a href={"/product/" + link}>
					<button className="text-center text-[#FFFFFF] bg-[#004B95] w-full h-[40px] rounded-[5px] font-poppins font-medium text-[18px] leading-[25.83px] tracking-[6%]" onClick={() => setProductShown(index)}>
						Read More
					</button>
				</a>
			</div>
		</div>
		// {/* </Link> */}
	);
};

export default ProductCard;
