import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import parse from "html-react-parser";
import axios from "axios";

const JoinDetail = () => {
	document.title = "Kijeka | Career Details";

	const params = useParams();
	const careerLink = params["link"];
	const [careerData, setCareerData] = useState({
		jobTitle: "",
		schedule: "",
		jobCategory: "",
		requisitionNumber: "",
		detailDiscription: "",
	});
	useEffect(() => {
		axios
			.get(process.env.REACT_APP_SERVER + "/api/careerList/")
			.then((res) => {
				let data = res.data.data;
				let flag = false;
				for (let i = 0; i < data.length; i++) {
					if (data[i]["requisitionNumber"] === careerLink) {
						setCareerData(data[i]);
						flag = true;
					}
				}
				if (flag === false) {
					window.location.href = "/career";
				}
			})
			.catch((err) => {
				console.log(err);
			});
	}, []);

	return (
		<>
			<div className="box h-[5rem] w-[25rem] relative flex justify-start items-start ml-[2rem] group mt-24">
				<div className="h-14 w-1.5 bg-[#004B95] absolute mr-[1rem] origin-left group-hover:w-[18rem] duration-300 group"></div>
				<p className="text-[28px] absolute font-bold text-[#004B95] mt-[0.5rem] ml-[1rem] group-hover:text-white group-hover:translate-x-5 duration-300">Let's join We</p>
			</div>
			<div className="my-5 ml-[3rem]">
				<h1 className="text-[#004895] text-[1.2rem]  font-bold xl:text-[1.2rem] xl:text-bold">{careerData["jobTitle"]}</h1>
			</div>
			<div className="flex max-lg:space-x-3 lg:space-x-3 max-sm:ml-10 max-sm:space-x-1 md:ml-10 max-lg:ml-10 my-3 lg:ml-[3rem]">
				<p className="text-[#101010] max-sm:text-[15px]  text-[0.7rem] sm:text-[0.7rem] md:text-[1.1rem] md:font-semibold xl:text-[1rem] lg:text-[1rem] lg:font-bold">
					Schedule : <span className="text-[1rem] md:text-[1.2rem] max-sm:text-[0.9rem] font-normal">{careerData["schedule"]}</span>
				</p>
				<p className="text-[#101010] max-sm:text-[15px] text-[0.7rem] sm:text-[0.7rem] md:text-[1.1rem] md:font-semibold xl:text-[1rem] lg:text-[1rem] lg:font-bold">
					Job Category :<span className="text-[1rem] md:text-[1.2rem] max-sm:text-[0.8rem] font-normal">{careerData["jobCategory"]}</span>
				</p>
				<p className="text-[#101010] max-sm:text-[15px] text-[0.7rem] sm:text-[0.7rem] md:text-[1.1rem] md:font-semibold xl:text-[1rem] lg:text-[1rem] lg:font-bold">
					Requisition Number : <span className="text-[1rem] md:text-[1.2rem] font-normal max-sm:text-[0.8rem]">{careerData["requisitionNumber"]}</span>
				</p>
			</div>
			<div className="my-5 ml-[3rem]">
				<p className="text-[#004895] md:text-[1.2rem] text-[1rem]  font-bold xl:text-[1.2rem] xl:text-bold">Join Detail</p>
			</div>
			<div className="my-5 ml-[3rem]">{parse(careerData["detailDiscription"])}</div>
			<div className="my-5 max-sm:w-[40%] ml-[3rem]">
				<a href={"/job-apply/" + careerData["requisitionNumber"]} className="py-2.5  px-5 mr-2 mb-2 text-sm font-medium text-[#004895] focus:outline-none bg-white rounded-lg border border-[#004895] hover:bg-gray-100 hover:text-blue-700 focus:z-10 focus:ring-4 focus:ring-gray-200  ">
					Apply Now
				</a>
			</div>
		</>
	);
};

export default JoinDetail;
