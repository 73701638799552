import React, { useState, useEffect } from "react";
import { NavLink } from "react-router-dom";
import axios from "axios";
import { Link } from "react-router-dom";

//Styles
import "./Navbar.css";

const Navbar = () => {
  //Variables
  const [isNavExpanded, setIsNavExpanded] = useState(false);
  const [activeMenu, setActiveMenu] = useState("abc");
  const [isScrolled, setIsScrolled] = useState();
  const [category, setCategory] = useState([]);
  const [searchProduct, setSearchProduct] = useState([]);
  const [searchCategory, setSearchCategory] = useState([]);
  const [cartData, setCartData] = useState([]);
  const [selectedLanguage, setSelectedLanguage] = useState("EN");

  //Fetching the data
  useEffect(() => {
    axios
      .get(process.env.REACT_APP_SERVER + "/api/categorySideBar/")
      .then((data) => {
        setCategory(data["data"]["data"]);
        setCartData(JSON.parse(localStorage.getItem("products")));
      })
      .catch((error) => console.log(error));
  }, []);

  //Navbar links
  const navlinks = [
    ["Home", "/"],
    ["Our Products", "/"],
    ["About Us", "/"],
    ["Blog", "/"],
    ["Contact", "/"],
    ["Careers", "/"],
  ];

  //Navbar toggle on scroll
  window.addEventListener("mousemove", () => {
    if (window.location.pathname.toString() === "/") {
      if (window.scrollY > 20) {
        setIsScrolled(true);
      }
      if (window.scrollY < 20) {
        setIsScrolled(false);
      }
    } else {
      setIsScrolled(true);
    }
  });
  window.addEventListener("scroll", () => {
    if (window.location.pathname.toString() === "/") {
      if (window.scrollY > 20) {
        setIsScrolled(true);
      }
      if (window.scrollY < 20) {
        setIsScrolled(false);
      }
    } else {
      setIsScrolled(true);
    }
  });

  window.onload = function () {
    if (window.location.pathname.toString() === "/") {
      if (window.scrollY > 20) {
        setIsScrolled(true);
      }
      if (window.scrollY < 20) {
        setIsScrolled(false);
      }
    } else {
      setIsScrolled(true);
    }

    // Google translate
    const googleTranslateElementInit = () => {
      new window.google.translate.TranslateElement(
        {
          pageLanguage: "en",
          autoDisplay: true,
        },
        "google_translate_element"
      );
    };
    var addScript = document.createElement("script");
    addScript.setAttribute(
      "src",
      "//translate.google.com/translate_a/element.js?cb=googleTranslateElementInit"
    );
    document.body.appendChild(addScript);
    window.googleTranslateElementInit = googleTranslateElementInit;
    setInterval(() => {
      try {
        document.getElementById(":1.container").style.transform = "scale(0)";
        document.getElementById(":1.container").parentElement.style.transform =
          "scale(0)";
        document.getElementsByTagName("body")[0].style.top = "0px";
        document.getElementById("google_translate_element").style.transform =
          "scale(0)";
      } catch (e) {}
    }, 500);
  };

  if (activeMenu === "abc") {
    if (window.location.pathname.toString() === "/") {
      setActiveMenu("Home");
    } else if (
      window.location.pathname.toString() === "/blog" ||
      window.location.pathname.toString().includes("/blog") ||
      window.location.pathname.toString().split("/")[1] === ""
    ) {
      setActiveMenu("Blog");
    } else if (window.location.pathname.toString() === "/about") {
      setActiveMenu("About Us");
    } else if (window.location.pathname.toString() === "/contact") {
      setActiveMenu("Contact");
    } else if (
      window.location.pathname.toString() === "/careers" ||
      window.location.pathname.toString().includes("/career-details/") ||
      window.location.pathname.toString().includes("/job-apply")
    ) {
      setActiveMenu("Careers");
    } else if (window.location.pathname.toString() === "/page-not-found") {
      setActiveMenu("PageNotFound");
    } else if (
      window.location.pathname.toString() === "/our-products" ||
      window.location.pathname.toString().includes("/product") ||
      window.location.pathname.toString().split("/")[1] !== ""
    ) {
      setActiveMenu("Our Products");
    } else {
      setActiveMenu("Home");
    }
  }

  return (
    // Navbar container
    <div
      className={
        "flex items-center justify-between py-3 px-2  md:px-8 fixed top-0 w-full z-50 transition-all duration-300 " +
        (isScrolled ? " bg-white " : " bg-transparent ") +
        (isScrolled ? " shadow-lg " : " ")
      }
      id="navbarContainer"
    >
      {/* Logo */}
      <div className="absolute top-0 left-0 scale-0 -translate-y-[400%] w-0 h-0 ">
        <div id="google_translate_element"></div>
      </div>
      <div className="md:w-32 w-28">
        <a href="https://www.kijeka.com/" target="_blank" title="Kijeka">
          <img
            src={
              isScrolled
                ? "/static/images/KijekaLogo.png"
                : "/static/images/KijekaLogoWhite.png"
            }
            className="w-full h-full object-cover"
            alt="Logo"
            title="Kijeka"
            loading="lazy"
          />
        </a>
      </div>

      {/* NavLinks */}
      <div>
        <ul
          className={
            isNavExpanded
              ? "fixed top-[54px] left-0 flex flex-col items-center justify-center h-screen w-full bg-white"
              : "lg:flex items-center hidden relative"
          }
        >
          {navlinks.map((link, index) => {
            if (link[0] === "Our Products") {
              return (
                <li
                  className={isNavExpanded ? "m-3 relative" : "mx-3 relative"}
                  key={index}
                  onMouseEnter={() => {
                    document
                      .getElementById("subCategoryDropdown")
                      .classList.remove("scale-y-0");
                    document
                      .getElementById("subCategoryDropdown")
                      .classList.remove("opacity-0");
                  }}
                  onMouseLeave={() => {
                    document
                      .getElementById("subCategoryDropdown")
                      .classList.add("scale-y-0");
                    document
                      .getElementById("subCategoryDropdown")
                      .classList.add("opacity-0");
                  }}
                >
                  <NavLink
                    to={link[1]}
                    className={
                      isNavExpanded
                        ? "flex flex-col text-kijeka-blue font-semibold " +
                          (activeMenu === link[0]
                            ? " navbar-active "
                            : " navbar-inactive ")
                        : () =>
                            isScrolled
                              ? activeMenu === link[0]
                                ? "navbar-active"
                                : "navbar-inactive"
                              : activeMenu === link[0]
                              ? "navbar-active-white"
                              : "navbar-inactive-white"
                    }
                    onClick={() => {
                      setIsNavExpanded(false);
                      setActiveMenu("abc");
                    }}
                  >
                    {link[0]}
                  </NavLink>
                  <div
                    id="subCategoryDropdown"
                    className="absolute top-5 pt-5 -left-[80%] md:-left-[200%] scale-y-0 opacity-0 transition-all duration-300 origin-top shadow-xl rounded-lg overflow-hidden"
                  >
                    <div className="bg-white/70 backdrop-blur w-max p-10 md:grid md:grid-rows-6 md:grid-flow-col flex flex-col justify-center gap-x-10 py-5 ">
                      {category.map((item, index) => (
                        <div
                          key={index}
                          className="text-kijeka-blue font-medium text-sm my-2 scale-100 origin-left hover:scale-110 hover:font-semibold transition-all duration-300"
                        >
                          <a
                            target="_blank"
                            href={"https://www.kijeka.com/" + item[1]}
                          >
                            &#x2022; {item[0]}
                          </a>
                        </div>
                      ))}
                    </div>
                  </div>
                </li>
              );
            } else {
              return (
                <li className={isNavExpanded ? "p-5 " : "mx-3"} key={index}>
                  <NavLink
                    to={link[1]}
                    className={
                      isNavExpanded
                        ? "flex flex-col text-kijeka-blue font-semibold " +
                          (activeMenu === link[0]
                            ? " navbar-active "
                            : " navbar-inactive ")
                        : () =>
                            isScrolled
                              ? activeMenu === link[0]
                                ? "navbar-active"
                                : "navbar-inactive"
                              : activeMenu === link[0]
                              ? "navbar-active-white"
                              : "navbar-inactive-white"
                    }
                    onClick={() => {
                      setIsNavExpanded(false);
                      setActiveMenu("abc");
                    }}
                  >
                    {link[0]}
                  </NavLink>
                </li>
              );
            }
          })}
        </ul>
      </div>

      <div
        className="flex items-center gap-8 relative max-sm:gap-2 "
        onMouseLeave={() => {
          document.getElementById("searchOverlay").classList.add("scale-y-0");
          document.getElementById("searchOverlay").classList.add("opacity-0");
          document.getElementById("dummyPathForSearch").style.height = 0;
          document.getElementById("dummyPathForSearch").style.width = 0;
        }}
      >
        {/* Hamburge Menu */}
        <button
          className="lg:hidden xl:hidden outline-none"
          onClick={() => {
            setIsNavExpanded(!isNavExpanded);
          }}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            className="h-5 w-5"
            viewBox="0 0 20 20"
            fill={isScrolled ? "#004B95" : "#ffffff"}
          >
            <path
              fillRule="evenodd"
              d="M3 5a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 10a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM9 15a1 1 0 011-1h6a1 1 0 110 2h-6a1 1 0 01-1-1z"
              clipRule="evenodd"
            />
          </svg>
        </button>
      </div>
    </div>
  );
};

export default Navbar;
