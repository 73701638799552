import React from "react";
import Contact from "./Contact/Contact";
import ContactUsForm from "./Contact/ContactUsForm";
import Map from "./Contact/Map";
import { Helmet } from "react-helmet";

const ContactUs = () => {
	return (
		<div className="mt-24 mb-10 w-full">
			<Helmet>
				<meta charSet="utf-8" />
				<title>Get in Touch: Contact Kijeka for Material Handling Solutions</title>

				<meta name="title" content="Get in Touch: Contact Kijeka for Material Handling Solutions"></meta>
				<meta name="description" content="Contact Kijeka for all your material handling equipment needs. Find our plant and office addresses, customer care number, and overseas contact details. Connect with us on the Ariba network and explore our map to locate our offices conveniently. Get in touch with Kijeka today!"></meta>
				<meta name="keywords" content="contact information, plant address, office address, customer care, overseas contact, Ariba network, map location"></meta>
				<meta http-equiv="Content-Type" content="text/html; charset=utf-8"></meta>
				<meta property="og:type" content="website"></meta>
				<meta property="og:url" content="https://kijeka.com/contact"></meta>
				<meta property="og:title" content="Contact Us : Kijeka Engineers Private Limited"></meta>
				<meta property="og:description" content="The contact page for Kijeka, a renowned provider of top-notch handling equipment solutions for various industries worldwide, is designed to facilitate seamless communication between potential clients and the company's representatives."></meta>
				<meta property="og:image" content="/static/images/KijekaMeta.png"></meta>
				<meta property="twitter:card" content="summary_large_image"></meta>
				<meta property="twitter:url" content="https://kijeka.com/contact"></meta>
				<meta property="twitter:title" content="Contact Us : Kijeka Engineers Private Limited"></meta>
				<meta property="twitter:description" content="The contact page for Kijeka, a renowned provider of top-notch handling equipment solutions for various industries worldwide, is designed to facilitate seamless communication between potential clients and the company's representatives."></meta>
				<meta property="twitter:image" content="/static/images/KijekaMeta.png"></meta>
				<link rel="canonical" href="https://kijeka.com/contact" />
			</Helmet>
			<Contact />
			<ContactUsForm />
			<Map />
			{/* <LinePath /> */}
		</div>
	);
};

export default ContactUs;
