import React from "react";
import Mail from "./Svg/Mail";
import Phone from "./Svg/Phone";

const Contact = () => {
	return (
		<>
			<Contact_Title />
			<Contact_Details />
		</>
	);
};

export default Contact;

const Contact_Title = () => {
	return (
		<div className="relative flex justify-start items-center group mb-4 px-10 mt-5">
			<div className="h-14 w-1.5 max-md:h-12 bg-kijeka-blue mr-[1rem] origin-left group-hover:w-[15rem] duration-300 group"></div>
			<p className="text-2xl max-sm:text-[22px] absolute font-bold text-kijeka-blue ml-[1rem] group-hover:text-white group-hover:translate-x-5 duration-300">Contact</p>
		</div>
	);
};

const Contact_Details = () => {
	return (
		<div className="flex justify-center items-center mt-10 px-12 max-md:flex max-md:flex-col">
			<div className="flex max-sm:w-[90%] flex-col items-start justify-between gap-y-10 w-[33.33%] max-md:w-full max-md:items-center">
				<div className="max-sm:w-[70%] max-lg:w-[80%]">
					<img src={"/static/images/boutique.png"} alt="" />
				</div>
				<div>
					<p className="text-[22px] max-sm:text-[20px] text-kijeka-blue font-bold tracking-widest">Office</p>
					<p className="text-[16px] max-sm:mt-3 max-sm:text-[14px] text-kijeka-blue w-[70%] max-sm:w-full">
						KIJEKA ENGINEERS PRIVATE LIMITED <br /> 404, 4th floor, "ANUSHRI",Near Bank of Baroda,Ashram Road, Usmanpura,AHMEDABAD - 380 013,Gujarat State,India.
					</p>
				</div>
			</div>
			<div className="flex flex-col max-sm:flex-col-reverse items-center justify-center gap-y-10 w-[33.33%] max-md:w-full max-md:items-center max-md:my-10">
				<div>
					<p className="text-[22px] max-sm:text-[20px] text-kijeka-blue font-bold tracking-widest">Plant Address</p>
					<p className="text-[16px] max-sm:mt-3 text-kijeka-blue max-sm:text-[14px] max-sm:w-full w-[80%]">
						KIJEKA ENGINEERS PRIVATE LIMITED <br /> Plot No. E-344, Road No. 26, Nr. UGVCL Office,GIDC BOL Sanand II, Nr.Rasulpura Village,Taluka SanandDistrict Ahmedabad 382170,Gujarat State, India
					</p>
				</div>
				<div className="max-sm:w-[70%] max-lg:w-[90%]">
					<img src={"/static/images/Group.png"} alt="" />
				</div>
			</div>
			<div className="flex flex-col items-center justify-between gap-y-10 w-[33.33%] max-md:w-full max-md:items-center">
				<div className="max-sm:w-[70%] max-lg:w-[70%]">
					<img src={"/static/images/phone.png"} alt="" />
				</div>
				<div>
					<p className="text-[22px] max-sm:text-[20px] text-kijeka-blue font-bold tracking-widest">Contact</p>
					{/* Contact Number  */}
					<div className="flex flex-col max-lg:mt-10 justify-center gap-y-5 mt-3 mb-6">
						<div className="lg:flex items-center  justify-between gap-x-2 max-sm:text-[14px] max-sm:w-full text-[16px] ">
							<div className="max-lg:absolute max-lg:pt-6">
								<Phone />
							</div>
							<p className="text-kijeka-blue max-lg:pl-6">Customer Care:</p>
							<p className="text-kijeka-blue max-lg:pl-6">+91-98795 45352</p>
						</div>
						<div className="flex items-center justify-start gap-x-2">
							<Phone />
							<p className="text-kijeka-blue">+91-63592 37729</p>
						</div>
					</div>
					{/* Email Address  */}
					<div className="flex flex-col justify-center gap-y-5">
						<div className="flex items-center justify-start gap-x-2 cursor-pointer">
							<Mail />
							<a href="mailto:info@kijeka.com" className="text-kijeka-blue">
								info@kijeka.com
							</a>
						</div>
						<div className="flex items-center justify-start gap-x-2 cursor-pointer">
							<Mail />
							<a href="mailto:overseas@kijeka.com" className="text-kijeka-blue">
								overseas@kijeka.com
							</a>
						</div>
						<div className="flex items-center justify-start gap-x-2 cursor-pointer">
							<Mail />
							<a href="mailto:help@kijeka.com" className="text-kijeka-blue">
								help@kijeka.com
							</a>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};
