import React, {useEffect, useState} from "react";
import {Link, NavLink} from "react-router-dom";
import axios from "axios";

//Styles
import "./Navbar.css";

const Navbar = () => {
    //Variables
    const [isNavExpanded, setIsNavExpanded] = useState(false);
    const [activeMenu, setActiveMenu] = useState("abc");
    const [isScrolled, setIsScrolled] = useState();
    const [category, setCategory] = useState([]);
    const [searchProduct, setSearchProduct] = useState([]);
    const [searchCategory, setSearchCategory] = useState([]);
    const [cartData, setCartData] = useState([]);
    const [selectedLanguage, setSelectedLanguage] = useState("EN");

    //Fetching the data
    useEffect(() => {
        axios
            .get(process.env.REACT_APP_SERVER + "/api/categorySideBar/")
            .then((data) => {
                setCategory(data["data"]["data"]);
                setCartData(JSON.parse(localStorage.getItem("products")));
            })
            .catch((error) => console.log(error));
    }, []);

    //Navbar links
    const navlinks = [
        ["Home", "/"],
        ["Best Products", "/our-products"],
        ["About Us", "/about"],
        ["Blog", "/blog"],
        ["Contact", "/contact"],
        ["Careers", "/careers"],
    ];

    //Navbar toggle on scroll
    window.addEventListener("mousemove", () => {
        if (window.location.pathname.toString() === "/") {
            if (window.scrollY > 20) {
                setIsScrolled(true);
            }
            if (window.scrollY < 20) {
                setIsScrolled(false);
            }
        } else {
            setIsScrolled(true);
        }
    });
    window.addEventListener("scroll", () => {
        if (window.location.pathname.toString() === "/") {
            if (window.scrollY > 20) {
                setIsScrolled(true);
            }
            if (window.scrollY < 20) {
                setIsScrolled(false);
            }
        } else {
            setIsScrolled(true);
        }
    });

    window.onload = function () {
        if (window.location.pathname.toString() === "/") {
            if (window.scrollY > 20) {
                setIsScrolled(true);
            }
            if (window.scrollY < 20) {
                setIsScrolled(false);
            }
        } else {
            setIsScrolled(true);
        }

        // Google translate
        const googleTranslateElementInit = () => {
            new window.google.translate.TranslateElement(
                {
                    pageLanguage: "en",
                    autoDisplay: true,
                },
                "google_translate_element"
            );
        };
        var addScript = document.createElement("script");
        addScript.setAttribute("src", "//translate.google.com/translate_a/element.js?cb=googleTranslateElementInit");
        document.body.appendChild(addScript);
        window.googleTranslateElementInit = googleTranslateElementInit;
        setInterval(() => {
            try {
                document.getElementById(":1.container").style.transform = "scale(0)";
                document.getElementById(":1.container").parentElement.style.transform = "scale(0)";
                document.getElementsByTagName("body")[0].style.top = "0px";
                document.getElementById("google_translate_element").style.transform = "scale(0)";
            } catch (e) {
            }
        }, 500);
    };

    if (activeMenu === "abc") {
        if (window.location.pathname.toString() === "/") {
            setActiveMenu("Home");
        } else if (window.location.pathname.toString() === "/blog" || window.location.pathname.toString().includes("/blog") || window.location.pathname.toString().split("/")[1] === "") {
            setActiveMenu("Blog");
        } else if (window.location.pathname.toString() === "/about") {
            setActiveMenu("About Us");
        } else if (window.location.pathname.toString() === "/contact") {
            setActiveMenu("Contact");
        } else if (window.location.pathname.toString() === "/careers" || window.location.pathname.toString().includes("/career-details/") || window.location.pathname.toString().includes("/job-apply")) {
            setActiveMenu("Careers");
        } else if (window.location.pathname.toString() === "/page-not-found") {
            setActiveMenu("PageNotFound");
        } else if (window.location.pathname.toString() === "/our-products" || window.location.pathname.toString().includes("/product") || window.location.pathname.toString().split("/")[1] !== "") {
            setActiveMenu("Best Products");
        } else {
            setActiveMenu("Home");
        }
    }

    return (
        // Navbar container
        <div
            className={"flex items-center justify-between py-3 px-5 fixed top-0 w-full z-50 transition-all duration-300 " + (isScrolled ? " bg-white " : " bg-transparent ") + (isScrolled ? " shadow-lg " : " ")}
            id="navbarContainer">
            {/* Logo */}
            <div className="absolute top-0 left-0 scale-0 -translate-y-[400%] w-0 h-0 ">
                <div id="google_translate_element"></div>
            </div>
            <div className="md:w-32 w-28">
                <a href="/" title="Kijeka">
                    <img src={isScrolled ? "/static/images/KijekaLogo.png" : "/static/images/KijekaLogoWhite.png"}
                         className="w-full h-full object-cover" alt="Logo" title="Kijeka" loading="lazy"/>
                </a>
            </div>

            {/* NavLinks */}
            <div>
                <ul className={isNavExpanded ? "fixed top-[54px] left-0 flex flex-col items-center justify-center h-screen w-full bg-white" : "lg:flex items-center hidden relative"}>
                    {navlinks.map((link, index) => {
                        if (link[0] === "Best Products") {
                            return (
                                <li
                                    className={isNavExpanded ? "m-3 relative" : "mx-3 relative"}
                                    key={index}
                                    onMouseEnter={() => {
                                        document.getElementById("subCategoryDropdown").classList.remove("scale-y-0");
                                        document.getElementById("subCategoryDropdown").classList.remove("opacity-0");
                                    }}
                                    onMouseLeave={() => {
                                        document.getElementById("subCategoryDropdown").classList.add("scale-y-0");
                                        document.getElementById("subCategoryDropdown").classList.add("opacity-0");
                                    }}
                                >
                                    <NavLink
                                        to={link[1]}
                                        className={isNavExpanded ? "flex flex-col text-kijeka-blue font-semibold " + (activeMenu === link[0] ? " navbar-active " : " navbar-inactive ") : () => (isScrolled ? (activeMenu === link[0] ? "navbar-active" : "navbar-inactive") : activeMenu === link[0] ? "navbar-active-white" : "navbar-inactive-white")}
                                        onClick={() => {
                                            setIsNavExpanded(false);
                                            setActiveMenu("abc");
                                        }}
                                    >
                                        {link[0]}
                                    </NavLink>
                                    <div id="subCategoryDropdown"
                                         className="absolute top-5 pt-5 -left-[80%] md:-left-[200%] scale-y-0 opacity-0 transition-all duration-300 origin-top shadow-xl rounded-lg overflow-hidden">
                                        <div
                                            className="bg-white/70 backdrop-blur w-max p-10 md:grid md:grid-rows-6 md:grid-flow-col flex flex-col justify-center gap-x-10 py-5 ">
                                            {category.map((item, index) => (
                                                <div key={index}
                                                     className="text-kijeka-blue font-medium text-sm my-2 scale-100 origin-left hover:scale-110 hover:font-semibold transition-all duration-300">
                                                    <a href={"/" + item[1]}>&#x2022; {item[0]}</a>
                                                </div>
                                            ))}
                                        </div>
                                    </div>
                                </li>
                            );
                        } else {
                            return (
                                <li className={isNavExpanded ? "p-5 " : "mx-3"} key={index}>
                                    <NavLink
                                        to={link[1]}
                                        className={isNavExpanded ? "flex flex-col text-kijeka-blue font-semibold " + (activeMenu === link[0] ? " navbar-active " : " navbar-inactive ") : () => (isScrolled ? (activeMenu === link[0] ? "navbar-active" : "navbar-inactive") : activeMenu === link[0] ? "navbar-active-white" : "navbar-inactive-white")}
                                        onClick={() => {
                                            setIsNavExpanded(false);
                                            setActiveMenu("abc");
                                        }}
                                    >
                                        {link[0]}
                                    </NavLink>
                                </li>
                            );
                        }
                    })}
                </ul>
            </div>

            <div
                className="flex items-center gap-8 relative max-sm:gap-2 "
                onMouseLeave={() => {
                    document.getElementById("searchOverlay").classList.add("scale-y-0");
                    document.getElementById("searchOverlay").classList.add("opacity-0");
                    document.getElementById("dummyPathForSearch").style.height = 0;
                    document.getElementById("dummyPathForSearch").style.width = 0;
                }}
            >
                {/* Searchbar */}
                <div className="relative group md:w-48 flex items-center justify-end h-10 ">
                    <div id="dummyPathForSearch"
                         className={"absolute top-0 right-0 z-0 min-w-full min-h-32 w-32 h-0"}></div>

                    {isScrolled ? (
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 22 22" fill="none"
                             className="absolute top-1/2 -translate-y-1/2 right-4 group-hover:right-4 group-focus:right-4 w-4 h-4 transition-all duration-300">
                            <circle cx="8.03713" cy="8.11061" r="6.76271" stroke="#004B95" strokeWidth="2"/>
                            <path d="M14.7998 14.8735L20.2744 20.0261" stroke="#004B95" strokeWidth="2"
                                  strokeLinecap="round"/>
                        </svg>
                    ) : (
                        <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg"
                             className="absolute top-1/2 -translate-y-1/2 right-4 group-hover:right-4 group-focus:right-4 w-4 h-4 transition-all duration-300">
                            <circle cx="8.03713" cy="8.11061" r="6.76271" stroke="white" strokeWidth="2"/>
                            <path d="M14.7998 14.8735L20.2744 20.0261" stroke="white" strokeWidth="2"
                                  strokeLinecap="round"/>
                        </svg>
                    )}

                    <input
                        type="text"
                        placeholder="Search"
                        id="navSearchBar"
                        onKeyUp={() => {
                            let navSearchBar = document.getElementById("navSearchBar").value;

                            if (navSearchBar.length > 0) {
                                document.getElementById("searchOverlay").classList.remove("scale-y-0");
                                document.getElementById("searchOverlay").classList.remove("opacity-0");
                                axios.get(process.env.REACT_APP_SERVER + "/api/searchDatabase/?searchQuery=" + navSearchBar).then((res) => {
                                    let data = res["data"]["data"];
                                    setSearchProduct(data["products"]);
                                    setSearchCategory(data["categories"]);
                                    setTimeout(() => {
                                        document.getElementById("dummyPathForSearch").style.height = document.getElementById("searchOverlay").offsetHeight + "px";
                                        document.getElementById("dummyPathForSearch").style.width = document.getElementById("searchOverlay").offsetWidth + "px";
                                    }, 300);
                                });
                            } else {
                                document.getElementById("searchOverlay").classList.add("scale-y-0");
                                document.getElementById("searchOverlay").classList.add("opacity-0");
                                document.getElementById("dummyPathForSearch").style.height = 0;
                                document.getElementById("dummyPathForSearch").style.width = 0;
                            }
                        }}
                        className={"bg-transparent z-10 border outline-none rounded-lg text-sm px-3 py-1 transition-all duration-300 md:w-36 w-[100px] md:group-hover:w-48 md:focus:w-48 " + (isScrolled ? " border-kijeka-blue " : " border-white ") + (isScrolled ? " text-kijeka-blue " : " text-white ") + (isScrolled ? "placeholder:text-kijeka-blue" : "placeholder:text-white")}
                    />
                    {/* Searchbar dropdown */}
                    <div id="searchOverlay"
                         className="absolute md:top-[38px] top-10 md:right-0 scale-y-0 opacity-0 origin-top w-max min-w-[12rem] mt-5 bg-white/90 backdrop-blur rounded-lg transition-all duration-300 shadow-lg z-50">
                        {searchProduct.map((item, index) => (
                            <a href={"/product/" + item[3]} key={index}
                               className="flex items-center gap-8 px-2 py-2 border-b border-[rgba(166,166,166,0.3)] last-of-type:border-none">
                                <div className="flex items-center gap-2">
                                    <div className="">
                                        <img src={process.env.REACT_APP_SERVER + "/media/images/" + item[2]}
                                             className="w-8 h-8 object-contain" alt=""/>
                                    </div>
                                    <p className=" discriptionTruncate-1 text-kijeka-blue text-sm font-medium w-full">{item[0]}</p>
                                </div>
                            </a>
                        ))}
                        {() => {
                            if (searchProduct.length > 0) {
                                return <hr/>;
                            }
                        }}
                        {searchCategory.map((item, index) => (
                            <a href={item[1]} key={index}
                               className="flex items-center w-full gap-8 px-2 py-2 border-b border-[rgba(166,166,166,0.3)] last-of-type:border-none">
                                <div className="flex items-center gap-2">
                                    <p className="discriptionTruncate-1 text-kijeka-blue text-sm font-medium w-full">{item[0]}</p>
                                </div>
                            </a>
                        ))}
                    </div>
                </div>

                {/* Cart */}
                <div
                    className="md:block cursor-pointer relative"
                    onClick={() => {
                        document.getElementById("cartOverlay").classList.toggle("scale-y-0");
                        document.getElementById("cartOverlay").classList.toggle("opacity-0");
                        if (!document.getElementById("languageOverlay").classList.contains("scale-y-0")) {
                            document.getElementById("languageOverlay").classList.add("scale-y-0");
                            document.getElementById("languageOverlay").classList.add("opacity-0");
                        }
                        if (!document.getElementById("searchOverlay").classList.contains("scale-y-0")) {
                            document.getElementById("searchOverlay").classList.add("scale-y-0");
                            document.getElementById("searchOverlay").classList.add("opacity-0");
                        }
                        setCartData(JSON.parse(localStorage.getItem("products")));
                    }}
                >
                    {cartData.length > 0 || JSON.parse(localStorage.getItem("products")).length > 0 ? (
                        <div id="countForCart"
                             className="bg-kijeka-blue w-5 h-5 rounded-full absolute bottom-3 left-3 text-white text-center text-sm">
                            {cartData.length > 0 ? cartData.length : JSON.parse(localStorage.getItem("products")).length.toString()}
                        </div>
                    ) : (
                        <div id="countForCart"
                             className="bg-kijeka-blue opacity-0 w-5 h-5 rounded-full absolute bottom-3 left-3 text-white text-center text-sm">
                            0
                        </div>
                    )}

                    {isScrolled ? (
                        <svg width="25" height="25" viewBox="0 0 20 27" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path
                                fillRule="evenodd"
                                clipRule="evenodd"
                                d="M14.5626 3.32863L15.5793 3.45525C17.926 3.74751 19.6875 5.74199 19.6875 8.10681V21.2186C19.6875 23.5147 17.9772 25.4512 15.6987 25.735C11.9142 26.2063 8.08583 26.2063 4.30134 25.735C2.02282 25.4512 0.3125 23.5147 0.3125 21.2186V8.10681C0.3125 5.74199 2.07399 3.74751 4.42069 3.45525L5.43738 3.32863C5.8401 1.87757 7.17067 0.8125 8.75 0.8125H11.25C12.8293 0.8125 14.1599 1.87757 14.5626 3.32863ZM5.3125 5.23366L4.65241 5.31587C3.2444 5.49123 2.1875 6.68792 2.1875 8.10681V21.2186C2.1875 22.5688 3.19323 23.7075 4.53307 23.8744C8.16367 24.3266 11.8363 24.3266 15.4669 23.8744C16.8068 23.7075 17.8125 22.5688 17.8125 21.2186V8.10681C17.8125 6.68792 16.7556 5.49123 15.3476 5.31587L14.6875 5.23366V6.75C14.6875 7.26777 14.2678 7.6875 13.75 7.6875H6.25C5.73223 7.6875 5.3125 7.26777 5.3125 6.75V5.23366ZM7.1875 4.25C7.1875 3.38705 7.88706 2.6875 8.75 2.6875H11.25C12.1129 2.6875 12.8125 3.38705 12.8125 4.25V5.8125H7.1875V4.25Z"
                                fill="#004B95"
                            />
                            <path
                                d="M14.6875 12.6875C14.6875 12.1697 14.2678 11.75 13.75 11.75H6.25C5.73223 11.75 5.3125 12.1697 5.3125 12.6875C5.3125 13.2053 5.73223 13.625 6.25 13.625H13.75C14.2678 13.625 14.6875 13.2053 14.6875 12.6875Z"
                                fill="#004B95"/>
                            <path
                                d="M13.4375 16.4375C13.4375 15.9197 13.0178 15.5 12.5 15.5H6.25C5.73223 15.5 5.3125 15.9197 5.3125 16.4375C5.3125 16.9553 5.73223 17.375 6.25 17.375H12.5C13.0178 17.375 13.4375 16.9553 13.4375 16.4375Z"
                                fill="#004B95"/>
                        </svg>
                    ) : (
                        <svg width="25" height="25" viewBox="0 0 20 27" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path
                                fillRule="evenodd"
                                clipRule="evenodd"
                                d="M14.5626 3.32863L15.5793 3.45525C17.926 3.74751 19.6875 5.74199 19.6875 8.10681V21.2186C19.6875 23.5147 17.9772 25.4512 15.6987 25.735C11.9142 26.2063 8.08583 26.2063 4.30134 25.735C2.02282 25.4512 0.3125 23.5147 0.3125 21.2186V8.10681C0.3125 5.74199 2.07399 3.74751 4.42069 3.45525L5.43738 3.32863C5.8401 1.87757 7.17067 0.8125 8.75 0.8125H11.25C12.8293 0.8125 14.1599 1.87757 14.5626 3.32863ZM5.3125 5.23366L4.65241 5.31587C3.2444 5.49123 2.1875 6.68792 2.1875 8.10681V21.2186C2.1875 22.5688 3.19323 23.7075 4.53307 23.8744C8.16367 24.3266 11.8363 24.3266 15.4669 23.8744C16.8068 23.7075 17.8125 22.5688 17.8125 21.2186V8.10681C17.8125 6.68792 16.7556 5.49123 15.3476 5.31587L14.6875 5.23366V6.75C14.6875 7.26777 14.2678 7.6875 13.75 7.6875H6.25C5.73223 7.6875 5.3125 7.26777 5.3125 6.75V5.23366ZM7.1875 4.25C7.1875 3.38705 7.88706 2.6875 8.75 2.6875H11.25C12.1129 2.6875 12.8125 3.38705 12.8125 4.25V5.8125H7.1875V4.25Z"
                                fill="white"
                            />
                            <path
                                d="M14.6875 12.6875C14.6875 12.1697 14.2678 11.75 13.75 11.75H6.25C5.73223 11.75 5.3125 12.1697 5.3125 12.6875C5.3125 13.2053 5.73223 13.625 6.25 13.625H13.75C14.2678 13.625 14.6875 13.2053 14.6875 12.6875Z"
                                fill="white"/>
                            <path
                                d="M13.4375 16.4375C13.4375 15.9197 13.0178 15.5 12.5 15.5H6.25C5.73223 15.5 5.3125 15.9197 5.3125 16.4375C5.3125 16.9553 5.73223 17.375 6.25 17.375H12.5C13.0178 17.375 13.4375 16.9553 13.4375 16.4375Z"
                                fill="white"/>
                        </svg>
                    )}
                    {/* cartoverlay */}
                    <div
                        className="absolute right-0 top-14 min-w-48 scale-y-0 opacity-0 origin-top w-max bg-white/70 backdrop-blur rounded-lg transition-all duration-300 shadow-lg z-50"
                        id="cartOverlay"
                        onMouseLeave={() => {
                            document.getElementById("cartOverlay").classList.add("scale-y-0");
                            document.getElementById("cartOverlay").classList.add("opacity-0");
                        }}
                    >
                        <div className="overflow-y-scroll max-h-[300px]">
                            {cartData.map((item, index) => {
                                document.getElementById("countForCart").innerText = cartData.length;
                                return (
                                    <div key={index} id={"cart-" + item.productName}
                                         className="flex items-center gap-8 px-2 py-2 border-b border-[rgba(166,166,166,0.3)] last-of-type:border-none">
                                        <div className="flex items-center gap-2">
                                            <div className="border border-kijeka-blue rounded-lg overflow-hidden">
                                                <img src={item.image} className="w-10 h-10 object-contain" alt=""/>
                                            </div>
                                            <p className="truncate text-kijeka-blue text-sm font-medium  w-32">{item.productName}</p>
                                        </div>
                                        <p
                                            onClick={() => {
                                                let temp = cartData;
                                                temp.splice(index, 1);
                                                setCartData(temp);
                                                document.getElementById("cart-" + item.productName).remove();
                                                localStorage.setItem("products", JSON.stringify(temp));
                                                setTimeout(() => {
                                                    window.location.reload();
                                                }, 500);
                                            }}
                                            className="bg-gray-100 px-2 py-1 text-gray-400 text-xs font-semibold rounded-full hover:bg-red-500 hover:text-white cursor-pointer transition-all duration-300 my-2 md:my-0 w-fit"
                                        >
                                            Remove
                                        </p>
                                    </div>
                                );
                            })}
                        </div>
                        <div className="flex justify-end m-2">
                            {cartData.length > 0 ? (
                                <Link to={"/add-to-inquiry"}>
                                    <p className="text-white font-medium text-sm bg-kijeka-blue rounded px-4 py-1">Send</p>
                                </Link>
                            ) : (
                                <Link to={"/our-products"}>
                                    <p className="text-white font-medium text-sm bg-kijeka-blue rounded px-4 py-1">Add
                                        Product to Cart</p>
                                </Link>
                            )}
                        </div>
                    </div>
                </div>

                <div className="flex items-center gap-1 relative cursor-pointer">
                    {/* language */}
                    <div
                        className="flex items-center gap-1"
                        onClick={() => {
                            document.getElementById("languageOverlay").classList.toggle("scale-y-0");
                            document.getElementById("languageOverlay").classList.toggle("opacity-0");
                            if (!document.getElementById("cartOverlay").classList.contains("scale-y-0")) {
                                document.getElementById("cartOverlay").classList.add("scale-y-0");
                                document.getElementById("cartOverlay").classList.add("opacity-0");
                            }
                            if (!document.getElementById("searchOverlay").classList.contains("scale-y-0")) {
                                document.getElementById("searchOverlay").classList.add("scale-y-0");
                                document.getElementById("searchOverlay").classList.add("opacity-0");
                            }
                        }}
                        onBlur={() => {
                            document.getElementById("languageOverlay").classList.add("scale-y-0");
                            document.getElementById("languageOverlay").classList.add("opacity-0");
                        }}
                    >
                        {isScrolled ? (
                            <svg width="22" height="22" viewBox="0 0 24 22" fill="none"
                                 xmlns="http://www.w3.org/2000/svg">
                                <path
                                    d="M8.9375 14.4556C9.39558 17.6218 10.4931 19.8517 11.7742 19.8517C13.0553 19.8517 14.1528 17.6218 14.6108 14.4556C13.7119 14.3439 12.7601 14.283 11.7742 14.283C10.7882 14.283 9.83642 14.3439 8.9375 14.4556Z"
                                    fill="#004B95"/>
                                <path
                                    d="M8.78352 9.23462C8.73446 9.85182 8.70801 10.4923 8.70801 11.151C8.70801 11.8096 8.73446 12.4501 8.78352 13.0673C9.73112 12.9524 10.7324 12.8915 11.7747 12.8915C12.817 12.8915 13.8182 12.9524 14.7658 13.0673C14.8149 12.4501 14.8413 11.8096 14.8413 11.151C14.8413 10.4923 14.8149 9.85182 14.7658 9.23462C13.8182 9.3495 12.817 9.41041 11.7747 9.41041C10.7324 9.41041 9.73112 9.3495 8.78352 9.23462Z"
                                    fill="#004B95"/>
                                <path
                                    d="M14.5586 19.4781C16.711 18.8852 18.5422 17.6244 19.7597 15.9604C18.8094 15.4281 17.5632 14.994 16.1234 14.7009C15.8451 16.586 15.3302 18.284 14.5586 19.4781Z"
                                    fill="#004B95"/>
                                <path
                                    d="M8.99013 19.4782C8.2181 18.2841 7.70366 16.5861 7.42536 14.7007C5.98556 14.9938 4.73896 15.4279 3.78906 15.9602C5.00691 17.6245 6.83771 18.8857 8.99013 19.4782Z"
                                    fill="#004B95"/>
                                <path
                                    d="M8.99013 2.82397C6.83771 3.41681 5.00653 4.67767 3.78906 6.34164C4.73935 6.8739 5.98556 7.308 7.42536 7.60111C7.70366 5.71609 8.21848 4.018 8.99013 2.82397Z"
                                    fill="#004B95"/>
                                <path
                                    d="M3.05237 14.7539C4.18512 14.1236 5.61687 13.6262 7.26482 13.305C7.20541 12.5957 7.17474 11.8728 7.17474 11.1506C7.17474 10.4284 7.20541 9.7055 7.26482 8.99653C5.61687 8.67528 4.18512 8.17757 3.05237 7.54761C2.50114 8.64569 2.19141 9.8649 2.19141 11.1506C2.19141 12.4363 2.50114 13.6555 3.05237 14.7539Z"
                                    fill="#004B95"/>
                                <path
                                    d="M11.7742 2.44946C10.4931 2.44946 9.39558 4.6794 8.9375 7.84557C9.83642 7.9573 10.7882 8.0182 11.7742 8.0182C12.7601 8.0182 13.7119 7.9573 14.6108 7.84557C14.1528 4.6794 13.0553 2.44946 11.7742 2.44946Z"
                                    fill="#004B95"/>
                                <path
                                    d="M20.4966 7.54736C19.3639 8.17768 17.9321 8.67503 16.2842 8.99628C16.3436 9.7056 16.3743 10.4285 16.3743 11.1507C16.3743 11.8729 16.3436 12.5958 16.2842 13.3047C17.9321 13.626 19.3639 14.1237 20.4966 14.7537C21.0479 13.6556 21.3576 12.4364 21.3576 11.1507C21.3576 9.86501 21.0479 8.6458 20.4966 7.54736Z"
                                    fill="#004B95"/>
                                <path
                                    d="M14.5586 2.82397C15.3306 4.018 15.8451 5.71609 16.1234 7.60146C17.5632 7.30835 18.8098 6.87426 19.7597 6.34199C18.5418 4.67767 16.711 3.41646 14.5586 2.82397Z"
                                    fill="#004B95"/>
                            </svg>
                        ) : (
                            <svg width="22" height="22" viewBox="0 0 23 24" fill="none"
                                 xmlns="http://www.w3.org/2000/svg">
                                <path
                                    d="M8.66309 15.7456C9.12117 19.2874 10.2187 21.7819 11.4998 21.7819C12.7809 21.7819 13.8783 19.2874 14.3364 15.7456C13.4375 15.6206 12.4857 15.5525 11.4998 15.5525C10.5138 15.5525 9.562 15.6206 8.66309 15.7456Z"
                                    fill="white"/>
                                <path
                                    d="M8.50911 9.90527C8.46004 10.5956 8.43359 11.3119 8.43359 12.0486C8.43359 12.7852 8.46004 13.5016 8.50911 14.1918C9.45671 14.0634 10.458 13.9952 11.5003 13.9952C12.5425 13.9952 13.5438 14.0634 14.4914 14.1918C14.5405 13.5016 14.5669 12.7852 14.5669 12.0486C14.5669 11.3119 14.5405 10.5956 14.4914 9.90527C13.5438 10.0338 12.5425 10.1019 11.5003 10.1019C10.458 10.1019 9.45671 10.0338 8.50911 9.90527Z"
                                    fill="white"/>
                                <path
                                    d="M14.2842 21.3631C16.4366 20.7001 18.2678 19.2899 19.4852 17.4289C18.535 16.8336 17.2887 16.3481 15.8489 16.0203C15.5706 18.1285 15.0558 20.0277 14.2842 21.3631Z"
                                    fill="white"/>
                                <path
                                    d="M8.71572 21.363C7.94368 20.0276 7.42925 18.1284 7.15095 16.0198C5.71115 16.3476 4.46455 16.8331 3.51465 17.4284C4.7325 19.2898 6.5633 20.7004 8.71572 21.363Z"
                                    fill="white"/>
                                <path
                                    d="M8.71572 2.73413C6.5633 3.39717 4.73212 4.80735 3.51465 6.66838C4.46493 7.26368 5.71115 7.74919 7.15095 8.07701C7.42925 5.96875 7.94407 4.06956 8.71572 2.73413Z"
                                    fill="white"/>
                                <path
                                    d="M2.77796 16.0793C3.91071 15.3742 5.34246 14.8179 6.99041 14.4585C6.93099 13.665 6.90033 12.8564 6.90033 12.0485C6.90033 11.2406 6.93099 10.432 6.99041 9.63888C5.34246 9.27952 3.91071 8.72277 2.77796 8.01807C2.22673 9.24643 1.91699 10.6103 1.91699 12.0485C1.91699 13.4867 2.22673 14.8506 2.77796 16.0793Z"
                                    fill="white"/>
                                <path
                                    d="M11.4998 2.31519C10.2187 2.31519 9.12117 4.80967 8.66309 8.35147C9.562 8.47645 10.5138 8.54459 11.4998 8.54459C12.4857 8.54459 13.4375 8.47645 14.3364 8.35147C13.8783 4.80967 12.7809 2.31519 11.4998 2.31519Z"
                                    fill="white"/>
                                <path
                                    d="M20.2222 8.01782C19.0895 8.72291 17.6577 9.27928 16.0098 9.63864C16.0692 10.4321 16.0998 11.2408 16.0998 12.0486C16.0998 12.8565 16.0692 13.6652 16.0098 14.4582C17.6577 14.8176 19.0895 15.3744 20.2222 16.0791C20.7735 14.8507 21.0832 13.4869 21.0832 12.0486C21.0832 10.6104 20.7735 9.24657 20.2222 8.01782Z"
                                    fill="white"/>
                                <path
                                    d="M14.2842 2.73413C15.0562 4.06956 15.5706 5.96875 15.8489 8.0774C17.2887 7.74958 18.5353 7.26407 19.4852 6.66878C18.2674 4.80735 16.4366 3.39678 14.2842 2.73413Z"
                                    fill="white"/>
                            </svg>
                        )}

                        <p className={(isScrolled ? "text-kijeka-blue" : "text-white") + " hidden md:block"}>{selectedLanguage}</p>

                        {isScrolled ? (
                            <svg width="10" height="9" viewBox="0 0 11 9" fill="none" xmlns="http://www.w3.org/2000/svg"
                                 className="hidden md:block">
                                <path d="M5.55998 8.76855L10.5087 0.986159H0.61127L5.55998 8.76855Z" fill="#004B95"/>
                            </svg>
                        ) : (
                            <svg width="11" height="8" viewBox="0 0 11 8" fill="none" xmlns="http://www.w3.org/2000/svg"
                                 className="hidden md:block">
                                <path d="M5.28459 7.29126L10.2333 0.860603H0.33588L5.28459 7.29126Z" fill="white"/>
                            </svg>
                        )}
                    </div>
                    {/* languageoverlay */}
                    <div
                        className="bg-white/70 backdrop-blur absolute top-10 md:-left-[50%] -left-[170%] scale-y-0 opacity-0 origin-top transition-all duration-300 w-max min-w-[100px] rounded-lg"
                        id="languageOverlay"
                        onMouseLeave={() => {
                            document.getElementById("languageOverlay").classList.add("scale-y-0");
                            document.getElementById("languageOverlay").classList.add("opacity-0");
                        }}
                    >
                        <p
                            onClick={() => {
                                let language = "en";
                                let elementGoogle = document.getElementById("google_translate_element");
                                elementGoogle.getElementsByTagName("select")[0].value = language;
                                elementGoogle.getElementsByTagName("select")[0].dispatchEvent(new Event("change"));
                                document.getElementById(":1.container").style.transform = "scale(0)";
                                document.getElementById("google_translate_element").style.transform = "scale(0)";
                                setSelectedLanguage("EN");
                            }}
                            className="px-4 py-2 border-b border-[rgba(166,166,166,0.3)] hover:tracking-[1px] text-kijeka-blue cursor-pointer text-sm font-medium"
                        >
                            English
                        </p>
                        <p
                            onClick={() => {
                                let language = "fr";
                                let elementGoogle = document.getElementById("google_translate_element");
                                elementGoogle.getElementsByTagName("select")[0].value = language;
                                elementGoogle.getElementsByTagName("select")[0].dispatchEvent(new Event("change"));
                                document.getElementById(":1.container").style.transform = "scale(0)";
                                document.getElementById("google_translate_element").style.transform = "scale(0)";
                                setSelectedLanguage("FR");
                            }}
                            className="px-4 py-2 border-b border-[rgba(166,166,166,0.3)] hover:tracking-[1px] text-kijeka-blue cursor-pointer text-sm font-medium"
                        >
                            French
                        </p>

                        <p
                            onClick={() => {
                                let language = "zh-CN";
                                let elementGoogle = document.getElementById("google_translate_element");
                                elementGoogle.getElementsByTagName("select")[0].value = language;
                                elementGoogle.getElementsByTagName("select")[0].dispatchEvent(new Event("change"));
                                document.getElementById(":1.container").style.transform = "scale(0)";
                                document.getElementById("google_translate_element").style.transform = "scale(0)";
                                setSelectedLanguage("CH");
                            }}
                            className="px-4 py-2 border-b border-[rgba(166,166,166,0.3)] hover:tracking-[1px] text-kijeka-blue cursor-pointer text-sm font-medium"
                        >
                            Chinese
                        </p>
                        <p
                            onClick={() => {
                                let language = "ar";
                                let elementGoogle = document.getElementById("google_translate_element");
                                elementGoogle.getElementsByTagName("select")[0].value = language;
                                elementGoogle.getElementsByTagName("select")[0].dispatchEvent(new Event("change"));
                                document.getElementById(":1.container").style.transform = "scale(0)";
                                document.getElementById("google_translate_element").style.transform = "scale(0)";
                                setSelectedLanguage("AR");
                            }}
                            className="px-4 py-2 border-b border-[rgba(166,166,166,0.3)] hover:tracking-[1px] text-kijeka-blue cursor-pointer text-sm font-medium"
                        >
                            Arabic
                        </p>
                    </div>
                </div>

                {/* Hamburge Menu */}
                <button
                    className="lg:hidden xl:hidden outline-none"
                    onClick={() => {
                        setIsNavExpanded(!isNavExpanded);
                    }}
                >
                    <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5" viewBox="0 0 20 20"
                         fill={isScrolled ? "#004B95" : "#ffffff"}>
                        <path fillRule="evenodd"
                              d="M3 5a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 10a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM9 15a1 1 0 011-1h6a1 1 0 110 2h-6a1 1 0 01-1-1z"
                              clipRule="evenodd"/>
                    </svg>
                </button>
            </div>
        </div>
    );
};

export default Navbar;
