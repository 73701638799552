import React, { useState, useEffect } from "react";
import axios from "axios";
import { useParams } from "react-router-dom";
import { Helmet } from "react-helmet";
// Components
import BlogCategoriesCard from "./BlogCategoriesCard";
import BlogItemsDisplay from "./BlogItemsDisplay";
import BlogsList from "./BlogsList";
import ShareProduct from "../ShareProduct/ShareProduct";

// Data
import listdData from "./listdData.json";
const Blog = () => {
	window.scrollTo(0, 0);

	const params = useParams();
	const blogLink = params["link"];

	const [category, setCategory] = useState([]);
	const [readEnabled, setReadEnabled] = useState(false);
	const [blogs, setBlogs] = useState([
		{
			author: "",
			blogLink: "",
			category: "",
			description: "",
			sources: "",
			title: "",
			views: 0,
		},
	]);
	const [selectedBlog, setSelectedBlog] = useState(blogs[0]);
	useEffect(() => {
		axios
			.get(process.env.REACT_APP_SERVER + "/api/categorySideBar/")
			.then((data) => setCategory(data["data"]["data"]))
			.catch((error) => console.log(error));

		if (blogs.length === 1 && blogs[0]["blogLink"] === "") {
			axios
				.get(process.env.REACT_APP_SERVER + "/api/blogdata/")
				.then((data) => {
					setBlogs(data["data"]["data"]);
					if (blogLink === undefined) {
						var el = document.createElement("html");
						el.innerHTML = data["data"]["data"][0]["description"];
						data["data"]["data"][0]["metadescription"] = el.innerText.slice(0, 170);
						setSelectedBlog(data["data"]["data"][0]);
						window.history.pushState(null, null, "/blog/" + data["data"]["data"][0]["blogLink"]);
					} else {
						let flag = 0;
						for (let i = 0; i < data["data"]["data"].length; i++) {
							if (data["data"]["data"][i]["blogLink"] === blogLink) {
								var el = document.createElement("html");
								el.innerHTML = data["data"]["data"][i]["description"];
								data["data"]["data"][i]["metadescription"] = el.innerText.slice(0, 170);
								setSelectedBlog(data["data"]["data"][i]);
								flag = 1;
								break;
							}
						}
						if (flag === 0) {
							window.location.href = "/page-not-found";
						}
					}
				})
				.catch((error) => console.log(error));
		}
	}, []);
	return (
		<>
			<Helmet>
				<meta charSet="utf-8" />
				<title>{selectedBlog["title"]}</title>

				<meta name="title" content={selectedBlog["title"]}></meta>
				<meta name="description" content={selectedBlog["metadescription"]}></meta>
				<meta name="keywords" content="material handling, equipment manufacturing, industry trends, tips, insights, blog articles, informative content, industry updates"></meta>
				<meta http-equiv="Content-Type" content="text/html; charset=utf-8"></meta>
				<meta property="og:type" content="website"></meta>
				<meta property="og:url" content={"https://kijeka.com/blog/" + blogLink}></meta>
				<meta property="og:title" content={selectedBlog["title"]}></meta>
				<meta property="og:description" content={selectedBlog["metadescription"]}></meta>
				<meta property="og:image" content="/static/images/KijekaMeta.png"></meta>
				<meta property="twitter:card" content="summary_large_image"></meta>
				<meta property="twitter:url" content={"https://kijeka.com/blog/" + blogLink}></meta>
				<meta property="twitter:title" content={selectedBlog["title"]}></meta>
				<meta property="twitter:description" content={selectedBlog["metadescription"]}></meta>
				<meta property="twitter:image" content="/static/images/KijekaMeta.png"></meta>
				<link rel="canonical" href={"https://kijeka.com/blog/" + blogLink} />
			</Helmet>
			<ShareProduct />
			<div className="relative flex justify-start items-center group lg:px-10 px-4 lg:mt-24 mt-20">
				<div className="h-14 w-1.5 max-md:h-12 bg-kijeka-blue mr-[1rem] origin-left group-hover:w-[15rem] duration-300 group"></div>
				<p className="text-2xl max-sm:text-[22px] absolute font-bold text-kijeka-blue ml-[1rem] group-hover:text-white group-hover:translate-x-5 duration-300">Latest News</p>
			</div>
			<div className="lg:flex flex-sm:grid lg:pl-[30px] px-4 sm:grid-cols-4 max-sm:overflow-hidden lg:mt-10 mt-5">
				<div className="col-span-1">
					<BlogsList listdData={category} />
				</div>
				<div className="col-span-3 lg:pl-[8%] lg:pr-[4.43%] ">
					<BlogItemsDisplay readEnabled={readEnabled} setReadEnabled={setReadEnabled} listdData={listdData} blogData={selectedBlog} />
				</div>
			</div>
			<div className="flex lg:ml-[60px] px-4 lg:px-0 py-[46px]">
				<div className="w-[6px] bg-[#004B95] hover:w-[240px] cursor-pointer  transition-all duration-500 font-poppins font-bold text-[#004B95] text-[28px] leading-[42px] hover:text-[white] hover:translate-x-1 gap-2 ">
					<p className="pl-[30px]">Recent</p>
				</div>
			</div>
			<div className="grid md:grid-cols-2 lg:grid-cols-3 grid-cols-1 gap-[22px] lg:px-[52px] px-4 mb-10">
				{blogs.map((item, index) => {
					return (
						<div
							onClick={() => {
								setSelectedBlog(blogs[index]);
								setReadEnabled(false);
								window.scrollTo(0, 0);
								// set location to the blog link
								window.history.pushState(null, null, "/blog/" + blogs[index]["blogLink"]);
							}}
						>
							<BlogCategoriesCard key={index} data={item} />
						</div>
					);
				})}
			</div>
		</>
	);
};

export default Blog;
