import React, { useState } from "react";
import ShareButton from "../AllProducts/ShareButton";
import parse from "html-react-parser";

const BlogCategoriesCard = ({ data }) => {
	const [current, setCurrent] = useState(false);

	const handleOnClick = () => {
		setCurrent((prev) => !prev);
	};
	return (
		<div className="flex flex-col group max-sm:h-[850px] h-[190px] max-h-[500px] overflow-hidden rounded-[10px] bg-[#FFFFFF] shadow-[0px_4px_20px_rgba(166,166,166,0.5)] border-[2px] hover:border-[#004B95] relative">
			<div className="cursor-pointer gap-[5px] max-sm:flex-col  max-sm:h-[200px] h-[250px] flex justify-between ">
			<div className="lg:min-h-[270px] min-w-[180px] min-h-[200px] max-h-[200px] object-contain">
				<img className=" object-contain" src={process.env.REACT_APP_SERVER + "/media/images/blog/" + data.image} alt="/" />
				</div>
				<div className="flex flex-col max-sm:pr-[10px] relative">
					<div className="flex justify-between pt-[10px]">
						<p className="font-semibold mt-1 sm:text-[15px] max-sm:mt-0 max-sm:font-semibold max-sm:tracking-[0%] text-[12px] max-sm:text-[18px] max-sm:leading-[25px] max-sm:pl-[2px] leading-[15px] tracking-[5%] text-kijeka-blue">{data.title}</p>
						<div className="flex flex-col justify-center items-center gap-2 mr-[2px] ">
							{/* like */}
							<svg className=" cursor-pointer" onClick={handleOnClick} width={25} height={17} viewBox="0 0 25 25" fill={current === false ? "none" : "red "}>
								<path
									fillRule="evenodd"
									clipRule="evenodd"
									d="M15.3815 1.34373C14.0463 1.3229 12.6563 1.75519 11.5878 2.96873C10.5117 1.75727 9.10748 1.34373 7.8073 1.34373C4.61782 1.34373 1.42456 4.25832 1.42456 8.37498C1.42456 12.3562 3.73653 15.6771 6.03431 17.9416C7.05918 18.9511 8.17076 19.8484 9.35334 20.6208C9.83748 20.9333 10.2725 21.1802 10.6261 21.3521C10.8029 21.4375 10.9665 21.5083 11.1131 21.5594C11.2417 21.6041 11.4157 21.6562 11.5897 21.6562C11.7627 21.6562 11.9376 21.6041 12.0662 21.5594C12.2119 21.5083 12.3764 21.4375 12.5532 21.3521C12.9069 21.1802 13.3418 20.9333 13.825 20.6208C15.0079 19.8485 16.1198 18.9512 17.145 17.9416C19.4428 15.6771 21.7548 12.3562 21.7548 8.37498C21.7548 4.2479 18.5549 1.39582 15.3815 1.34373V1.34373Z"
									stroke={current === false ? "#004B95" : "red "}
									strokeWidth={1.5}
								/>
							</svg>
							<ShareButton title={data.title} image={process.env.REACT_APP_SERVER + "/media/images/blog/" + data.image} />
						</div>
					</div>
					<p className="font-poppins max-sm:text-[15px] sm:text-[15px] max-sm:pl-[5px] text-kijeka-blue pr-3 text-[9px] py-[11px] tracking-[6%] font-medium">{parse(data.description.replace(/<h1/g, "<p").replace(/h1>/g, "p>"))}</p>
					<div className="w-full h-5 absolute top-[168px] bg-gradient-to-t from-white to-transparent hidden md:block"></div>
				</div>
			</div>
			<div className="group-hover:-translate-x-[10px] translate-x-[100px] absolute right-0 top-[160px] flex items-center gap-2 ease-linear duration-300">
				<i className="fa-solid fa-eye text-[#696969] text-[15px]"></i>
				<p className="text-[#696969] text-[13px]">{data.views}</p>
			</div>
			<div className="flex items-center rounded-bl-lg bg-[rgba(0,0,0,0.4)] backdrop-blur-sm max-sm:w-[300px] py-1 w-[180px] h-[65px] max-sm:group-hover:translate-x-[0px] max-sm:group-hover:translate-y-[10px] group-hover:translate-y-[-110px] translate-y-[55px] max-sm:translate-y-[25px] max-sm:translate-x-[-395px] ease-linear duration-300 max-sm:duration-300  ">
				<div className="bg-white w-[20%] h-[90%] rounded-full ml-2 p-1 flex items-center justify-center">
					<img className="w-full h-full object-cover" src="/static/images/icon.png" alt="/" />
				</div>
				<div className="pl-[8px]">
					<p className="font-poppins font-medium text-[13px] leading-[24px] tracking-[5%] max-sm:text-[22px] text-white">{data.author}</p>
					<p className="font-poppins font-medium text-[8px] tracking-[5%] text-white max-sm:text-[12px]">{data.date}</p>
				</div>
			</div>
		</div>
	);
};

export default BlogCategoriesCard;
