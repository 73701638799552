import React from "react";
import PublisherIcon from "./PublisherIcon";
import parse from "html-react-parser";

const BlogItemsDisplay = ({ blogData, setReadEnabled, readEnabled }) => {
	return (
		<div className="flex flex-col max-sm:flex">
			{/* <p className='text-[#004B95] font-poppins font-bold text-[28px] leading-[42px]'>Latest News</p> */}
			<h1 className="text-kijeka-blue font-poppins font-semibold text-[32px] max-sm:text-[30px] leading-[48px] tracking-[4%] pt-[32px] " style={{ letterSpacing: "4%" }}>
				{blogData.title}
			</h1>
			<p id="blogDescription" className={"font-poppins font-normal text-[18px] text-kijeka-blue text-justify lg:text-left leading-[27px] tracking-[5%] pt-[33px] overflow-hidden relative transition-all duration-300 " + (readEnabled ? " h-fit " : " max-h-[500px] ")} style={{ letterSpacing: "5%" }}>
				{!readEnabled ? <div className="w-full h-16 absolute bottom-0 bg-gradient-to-t from-white to-transparent"></div> : <></>}
				{parse(blogData.description.replace(/<h1/g, "<p").replace(/h1>/g, "p>"))}
			</p>
			<div>
				{/* publisher icon */}
				<PublisherIcon setReadEnabled={setReadEnabled} readEnabled={readEnabled} views={blogData.views} author={blogData.author} date={blogData.date} />
			</div>
			<div className="flex justify-center items-center max-sm:mt-5 max-sm:pl-3 mt-[73px] gap-3 mr-[48px]">
				<img className="w-[36px] h-[14px]" src="/static/images/GreenDots.png" alt="/" />
				<p className="text-[#37A945] text-[20px]  leading-[24.2px] font-boldt">Thank You</p>
				<img className="transform rotate-180" src="/static/images/GreenDots.png" alt="/" />
			</div>
		</div>
	);
};

export default BlogItemsDisplay;
