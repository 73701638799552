import React, { useState, useEffect } from "react";
import parse from "html-react-parser";
import Video from "../Popup/Video";
import TableData from "./TableData";
import Stars from "./Stars";
import BlogsList from "../Blogs/BlogsList";
import { useParams } from "react-router-dom";
import axios from "axios";
import AddToCart from "./AddToCart";
import ShareButton from "../AllProducts/ShareButton";
import ShareProduct from "../ShareProduct/ShareProduct";
import ReactStars from "react-stars";
import { Helmet } from "react-helmet";
import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";

const Details = ({ productCat }) => {
  const params = useParams();
  const product = params["link"];

  // const images = [
  //   "https://images.pexels.com/photos/313782/pexels-photo-313782.jpeg?cs=srgb&dl=pexels-quintin-gellar-313782.jpg&fm=jpg",
  //   "https://e0.pxfuel.com/wallpapers/558/975/desktop-wallpaper-macbook-aesthetic-aesthetic-clouds-mac.jpg",
  //   "https://images.pexels.com/photos/313782/pexels-photo-313782.jpeg?cs=srgb&dl=pexels-quintin-gellar-313782.jpg&fm=jpg",
  //   "https://e0.pxfuel.com/wallpapers/558/975/desktop-wallpaper-macbook-aesthetic-aesthetic-clouds-mac.jpg",
  // ];
  var img = [];
  const [images, setImages] = useState([]);
  const [selectedImage, setSelectedImage] = useState("");
  const [showModal, setShowModal] = useState(false);
  const [category, setCategory] = useState([]);
  const [productData, setProductData] = useState([]);
  const [relatedProducts, setRelatedProducts] = useState([]);

  useEffect(() => {
    if (productData.length === 0) {
      axios
        .get(
          process.env.REACT_APP_SERVER +
            "/api/getProductDetail/?productName=" +
            product
        )
        .then((data) => {
          if (data["data"]["data"]["productName"] !== "") {
            setProductData(data["data"]["data"]);
            console.log(data["data"]["data"]);
            img = [
              data["data"]["data"]["pro_images"]["main_img"],
              data["data"]["data"]["pro_images"]["image1_url"],
              data["data"]["data"]["pro_images"]["image2_url"],
              data["data"]["data"]["pro_images"]["image3_url"],
              data["data"]["data"]["pro_images"]["image4_url"],
              data["data"]["data"]["pro_images"]["image5_url"],
              data["data"]["data"]["pro_images"]["image6_url"],
              data["data"]["data"]["pro_images"]["image7_url"],
            ];
            setImages(img);

            console.log(img);
          } else {
            window.location.href = "/page-not-found";
            // console.log("backend object:"+data["data"]["data"])
          }
        })
        .catch((error) => console.log(error));
    } else {
      if (selectedImage === "") {
        setSelectedImage(process.env.REACT_APP_SERVER + productData.image);
      }
      // related products
      if (relatedProducts.length === 0) {
        // post in axios
        axios
          .get(
            process.env.REACT_APP_SERVER +
              "/api/getCategoryProducts/?category=" +
              productData.categoryLink
          )
          .then((data) => {
            let dataArray = data["data"]["data"];
            console.log("dataaraay:" + dataArray);
            let finalArray = [];
            for (let i = 0; i < dataArray.length; i++) {
              if (dataArray[i][0] !== productData.productName) {
                finalArray.push(dataArray[i]);
              }
            }
            setRelatedProducts(finalArray);
          })
          .catch((error) => console.log(error));
      }
    }
  }, [productData]);
  useEffect(() => {
    if (category.length === 0) {
      axios
        .get(process.env.REACT_APP_SERVER + "/api/categorySideBar/")
        .then((data) => setCategory(data["data"]["data"]))
        .catch((error) => console.log(error));
    }
  }, [category]);

  const ratingChanged = (newRating) => {
    axios
      .get(
        process.env.REACT_APP_SERVER +
          "/api/starProduct/?title=" +
          productData.productName +
          "&stars=" +
          newRating
      )
      .then((data) => {});
  };

  const printingDetails = () => {
    const elements = document.querySelectorAll(":not(.printer)");
    let display = [];
    for (let i = 0; i < elements.length; i++) {
      if (elements[i].style.visibility !== "hidden") {
        elements[i].style.visibility = "hidden";
        display.push(elements[i]);
      }
    }
    window.print();
    for (let i = 0; i < display.length; i++) {
      display[i].style.visibility = "visible";
    }
  };

  return (
    <div class="overflow-x-hidden">
      <Helmet>
        <meta charSet="utf-8" />
        <title>
          {productData.productName + " | Kijeka Engineers Private Limited"}
        </title>

        <meta
          name="title"
          content={
            productData.metaTitle + " | Kijeka Engineers Private Limited"
          }
        ></meta>
        <meta
          name="description"
          content={productData.metaDescription?.slice(0, 170)}
        ></meta>
        <meta
          name="keywords"
          content="product details, service details, specifications, features, offerings, comprehensive information, in-depth insights, material handling details, equipment details, service details"
        ></meta>
        <meta
          http-equiv="Content-Type"
          content="text/html; charset=utf-8"
        ></meta>
        <meta property="og:type" content="website"></meta>
        <meta property="og:url" content={window.location.href}></meta>
        <meta
          property="og:title"
          content={
            productData.metaTitle + " | Kijeka Engineers Private Limited"
          }
        ></meta>
        <meta
          property="og:description"
          content={productData.metaDescription?.slice(0, 170)}
        ></meta>
        <meta
          property="og:image"
          content={process.env.REACT_APP_SERVER + productData.image}
        ></meta>
        <meta property="twitter:card" content="summary_large_image"></meta>
        <meta property="twitter:url" content={window.location.href}></meta>
        <meta
          property="twitter:title"
          content={
            productData.metaTitle + " | Kijeka Engineers Private Limited"
          }
        ></meta>
        <meta
          property="twitter:description"
          content={productData.metaDescription?.slice(0, 170)}
        ></meta>
        <meta
          property="twitter:image"
          content={process.env.REACT_APP_SERVER + productData.image}
        ></meta>
        <link rel="canonical" href={window.location.href} />
      </Helmet>
      <ShareProduct />

      <div className="relative sticky top-0 flex mt-14 z-50">
        {showModal && (
          <div className="absolute top-0 w-screen h-screen flex justify-center item-center">
            <span
              className="bg-[#000] opacity-[0.6] w-full h-full"
              onClick={(e) => setShowModal(false)}
            ></span>
            <div className="absolute bg-[#fff] h-[80%] mt-[10vh] top-0 flex justify-center item-center">
              <img className="" src={selectedImage} alt="" />
            </div>
          </div>
        )}
      </div>
      <div className="h-auto mx-[5%] mt-10">
        <div className="box h-[5rem] w-[40rem] relative flex justify-start items-start group ml-[0.8rem]">
          <div className="h-12 w-1.5 bg-[#004B95] absolute mr-[1rem] origin-left"></div>
          <p className="text-[24px] absolute font-bold text-[#004B95] mt-[0.5rem] ml-[1rem]">
            {productData.category}
          </p>
        </div>
        <div className="flex md:flex-row flex-col">
          <div className="md:h-[929px] h-fit rounded-[10px] relative ">
            <BlogsList listdData={category} />
          </div>
          <div
            className="bg-[#FFFFFF] mb-10 w-full sm:w-[70%] relative left-[1.7%] h-auto"
            id="contentToPrint"
          >
            <div className="w-full flex flex-row items-center">
              <h1 className=" sm:text-2xl text-lg font-poppins font-bold text-[#004B95] leading-[31.44px] tracking-[5.5%] my-[15px] w-[90.80%] printer">
                {productData.productName} . {productData.modelNo}
              </h1>
              <button className=" sm:my-[21px] my-[25px] absolute right-[5%]">
                <ShareButton
                  title={productData.productName}
                  link={""}
                  image={process.env.REACT_APP_SERVER + productData.image}
                />
              </button>
            </div>
            <div className="w-full xl:flex xl:flex-row">
              <div className="xl:mb-[0%] mb-[2%] printer">
                <Stars
                  stars={productData.stars}
                  rating={productData.starCount}
                />
              </div>
              <div className="flex flex-row xl:absolute xl:left-[40%] xl:mb-[0%] mb-[2%] cursor-pointer">
                <svg
                  width="20"
                  height="20"
                  viewBox="0 0 20 20"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M5 0C4.44772 0 4 0.447715 4 1V5H2C0.89543 5 0 5.89543 0 7V15C0 16.1046 0.895432 17 2 17H4V19C4 19.5523 4.44772 20 5 20H15C15.5523 20 16 19.5523 16 19V17H18C19.1046 17 20 16.1046 20 15V7C20 5.89543 19.1046 5 18 5H16V1C16 0.447715 15.5523 0 15 0H5ZM15 12H5C4.44772 12 4 12.4477 4 13V15H2V7H5H15H18V15H16V13C16 12.4477 15.5523 12 15 12ZM14 5H6V2H14V5ZM3 8V10H6V8H3ZM14 14V18H6V14H14Z"
                    fill="#004B96"
                  />
                </svg>
                <div
                  className="ml-[7px] text-kijeka-blue underline font-poppins font-semibold sm:text-[18px] text-sm leading-[32.4px] tracking-[5%] -my-[6px]"
                  onClick={printingDetails}
                >
                  Print Page
                </div>
              </div>
              {productData.productPdf !== "" &&
              productData.productPdf !== null &&
              productData.productPdf !== undefined ? (
                <a
                  href={process.env.REACT_APP_SERVER + productData.productPdf}
                  download={productData.productName}
                  target="_blank"
                  rel="noreferrer"
                >
                  <div className="flex flex-row xl:absolute xl:left-[58%] xl:mb-[0%] mb-[2%] cursor-pointer">
                    <svg
                      width="16"
                      height="20"
                      viewBox="0 0 16 20"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M14 19.9999H2C0.89543 19.9999 0 19.1045 0 17.9999V1.99989C0 0.895325 0.89543 -0.000105089 2 -0.000105089H9C9.00896 -0.00126705 9.01804 -0.00126705 9.027 -0.000105089H9.033C9.04244 0.00285297 9.05216 0.0048629 9.062 0.00589491C9.15019 0.0115481 9.23726 0.0286931 9.321 0.0568949H9.336H9.351H9.363C9.38145 0.0698207 9.39884 0.0841998 9.415 0.0998949C9.52394 0.148318 9.62321 0.216079 9.708 0.299895L15.708 6.2999C15.7918 6.38468 15.8596 6.48395 15.908 6.5929C15.917 6.61489 15.924 6.6359 15.931 6.6589L15.941 6.68689C15.9689 6.77029 15.9854 6.85708 15.99 6.9449C15.9909 6.95486 15.9932 6.96463 15.997 6.9739V6.9799C15.9986 6.98645 15.9996 6.99315 16 6.9999V17.9999C16 18.5303 15.7893 19.039 15.4142 19.4141C15.0391 19.7892 14.5304 19.9999 14 19.9999ZM10.424 11.9999V16.9999H11.364V14.9599H12.824V14.1219H11.364V12.8409H13V11.9999H10.424ZM6.724 11.9999V16.9999H7.93C8.43593 17.0248 8.92583 16.8188 9.262 16.4399C9.6069 15.9998 9.77967 15.4491 9.748 14.8909V14.0809C9.77117 13.5285 9.59356 12.9864 9.248 12.5549C8.92263 12.1845 8.44761 11.9806 7.955 11.9999H6.724ZM3 11.9999V16.9999H3.94V15.2409H4.566C4.98402 15.264 5.39232 15.1093 5.69 14.8149C5.97408 14.4973 6.12141 14.0805 6.1 13.6549C6.11856 13.2191 5.97135 12.7925 5.688 12.4609C5.40772 12.1501 5.00309 11.981 4.585 11.9999H3ZM9 1.99989V6.9999H14L9 1.99989ZM7.946 16.1619H7.664V12.8409H8.006C8.24885 12.8266 8.48236 12.9366 8.626 13.1329C8.77261 13.4362 8.83544 13.7732 8.808 14.1089V14.9779C8.82996 15.2976 8.7606 15.6171 8.608 15.8989C8.4441 16.0902 8.19651 16.1886 7.946 16.1619ZM4.585 14.3999H3.939V12.8409H4.594C4.75752 12.8427 4.90863 12.9284 4.994 13.0679C5.10222 13.2465 5.15447 13.4534 5.144 13.6619C5.15654 13.8564 5.1049 14.0496 4.997 14.2119C4.89754 14.3367 4.74447 14.4066 4.585 14.3999Z"
                        fill="#004B96"
                      />
                    </svg>
                    <div className="ml-[7px] text-kijeka-blue underline font-poppins font-semibold sm:text-[18px] text-sm leading-[32.4px] tracking-[5%] -my-[6px]">
                      Download PDF
                    </div>
                  </div>
                </a>
              ) : (
                <></>
              )}
              {/* <AddToCart/> */}
              <AddToCart
                image={process.env.REACT_APP_SERVER + productData.image}
                productName={productData.productName}
              />
            </div>
            <div
              className={
                images.length > 4
                  ? "sm:h-[300px] h-[200px] w-full mt-[15px] grid grid-rows-4 grid-flow-col sm:gap-x-[calc(100%-114px)] gap-x-[calc(100%-70px)] printer"
                  : "sm:h-[300px] h-[200px] w-full mt-[15px] flex flex-col justify-between sm:gap-x-[calc(100%-114px)] gap-x-[calc(100%-70px)] printer"
              }
            >
              <div className="sm:h-[300px] sm:w-[64.32%] sm:mt-[0%] mt-[20%] w-[50%] h-[160px] absolute sm:left-[18.09%] left-[25%] flex justify-center">
                {
                  <img
                    src={
                      selectedImage === ""
                        ? process.env.REACT_APP_SERVER + productData.image
                        : selectedImage
                    }
                    alt="."
                    onClick={(e) => setShowModal(true)}
                  />
                }
              </div>
              {images.map(
                (item, index) =>
                  // Check if item is not an empty string
                  item !== "" && (
                    <button
                      className="sm:w-[58px] sm:h-[58px] w-[36px] h-[36px] bg-[#FFFFFF] mt-[8px] rounded-[10px] border border-[#004B95]"
                      key={index}
                      onClick={(e) =>
                        setSelectedImage(process.env.REACT_APP_SERVER + item)
                      }
                    >
                      <img
                        src={process.env.REACT_APP_SERVER + item}
                        alt=""
                        className="object-contain w-full h-full rounded-[10px]"
                      />
                    </button>
                  )
              )}
            </div>
            <div className="h-auto w-full mt-[15px] overflow-x-hidden printer">
              {productData.length !== 0 ? parse(productData.description) : ""}
            </div>
          </div>
        </div>
      </div>
      <div className="box h-[5rem] w-[25rem] relative flex justify-start items-start ml-[12%] group mt-6">
        <div className="h-12 w-1.5 bg-[#004B95] absolute mr-[1rem] origin-left group-hover:w-[16rem] duration-300 group"></div>
        <p className="text-[24px] absolute font-bold text-[#004B95] mt-[0.5rem] ml-[1rem] group-hover:text-white group-hover:translate-x-5 duration-300">
          Rate Product
        </p>
      </div>
      <div className="w-full mb-2 flex justify-center">
        <ReactStars
          count={5}
          half={false}
          onChange={ratingChanged}
          size={50}
          color1={"#00000033"}
          color2={"#004B96"}
          className="mx-auto h-32 "
        />
      </div>
      {relatedProducts.length !== 0 ? (
        <div className="md:my-[50px] my-[20px]">
          <TableData data={relatedProducts} />
        </div>
      ) : (
        <></>
      )}
      {productData.ytLink !== null &&
      productData.ytLink !== "" &&
      productData.ytLink !== undefined ? (
        <Video videoLink={productData.ytLink} />
      ) : (
        <></>
      )}
    </div>
  );
};

export default Details;
