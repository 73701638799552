import React from "react";
import { Link } from "react-router-dom";
import parse from "html-react-parser";

// Swiper
import { Swiper, SwiperSlide } from "swiper/react";
// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
// import required modules
import { Autoplay, Pagination } from "swiper";

// Classes
import { displayHeading } from "../lib/Classes";

// Components
import DefaultProductShown from "./DefaultProductShown";
import LikeShare from "./LikeShare";
import Stars from "./Stars";

const DisplayedProduct = ({ productShown, data }) => {
	if (productShown === -1) {
		// Default information to be displayed before hovering any categories
		return <DefaultProductShown />;
	}

	return (
		<div className="min-w-[300px] w-[300px] max-h-[680px] hidden lg:block overflow-hidden border-[3px] border-[#E1E1E1] drop-shadow shadow-[0px_4px_20px_rgba(166,166,166,0.3)] rounded-[10px] bg-white " data-aos="fade-right" data-aos-offset="300" data-aos-easing="ease-in-sine">
			<Swiper
				spaceBetween={30}
				centeredSlides={true}
				autoplay={{
					delay: 2000,
					disableOnInteraction: false,
				}}
				pagination={{ clickable: true }}
				modules={[Autoplay, Pagination]}
				className="mySwiper CategoryProducts"
			>
				{data.products.map((product, index) => {
					return (
						// Products' slider to be displayed on hover over a category
						<SwiperSlide key={index} className="overflow-visible h-fit">
							<div className="flex justify-between items-center">
								<p className={`text-start ${displayHeading} ml-[15px] w-[14rem] discriptionTruncate-1`} title={product[0]}>
									{product[0]}
								</p>
								<div className=" right-[15px] relative">
									<LikeShare liked={product[3]} title={product[0]} link={product[0].replace(/ /g, "-")} image={process.env.REACT_APP_SERVER + product[1]} />
								</div>
							</div>
							<div className="ml-[18px]">
								<Stars rating={product[5]} stars={product[4]} />
							</div>

							<div className="flex justify-center items-center !w-[40%] h-[20%] m-auto ">
								{/* Multiple images of a product */}
								<Swiper
									spaceBetween={10}
									centeredSlides={true}
									autoplay={{
										delay: 2500,
										disableOnInteraction: true,
									}}
									modules={[Autoplay]}
									className="mySwiper"
								>
									<SwiperSlide>
										<img className="!w-[161.58px] !h-[170px] m-auto pb-[12px] object-contain" data-aos="fade-right" data-aos-easing="ease-in-sine" src={process.env.REACT_APP_SERVER + product[1]} alt={product[1]} title={product[1]} loading="lazy" />
									</SwiperSlide>
								</Swiper>
							</div>
							<div className="font-poppins font-normal text-[16px] leading-[22.96px] tracking-[2%] text-[#004B95] discriptionTruncate-15 h-[300px] mb-3 px-5">{parse(product[2])}</div>
							<button className="relative text-center mb-8 font-poppins font-semibold text-[#FFFFFF] bg-[#004B95] w-[88%] px-3 h-[50px] rounded-[5px] text-[20px] leading-[40.8px] tracking-[5%] left-[20px]">
								<Link to={"/product/" + product[0].replace(/ /g, "-")}>Read More</Link>
							</button>
						</SwiperSlide>
					);
				})}
			</Swiper>
		</div>
	);
};

export default DisplayedProduct;
