import React from "react";
import { Helmet } from "react-helmet";
const Privacy = () => {
	return (
		<div className="px-48 pt-24 pb-12 max-sm:px-4">
			<Helmet>
				<meta charSet="utf-8" />
				<title>Your Privacy Matters: Kijeka Privacy Policy - Safeguarding Your Information</title>

				<meta name="title" content="Your Privacy Matters: Kijeka Privacy Policy - Safeguarding Your Information"></meta>
				<meta name="description" content="Protecting your privacy is our utmost priority at Kijeka. Explore our Privacy page to learn how we safeguard your personal information and ensure a secure and confidential experience throughout your interaction with us."></meta>
				<meta name="keywords" content="privacy policy, data protection, personal information, user privacy, data security, consent, information handling, privacy practices"></meta>
				<meta http-equiv="Content-Type" content="text/html; charset=utf-8"></meta>
				<meta property="og:type" content="website"></meta>
				<meta property="og:url" content="https://kijeka.com/privacy-policy/"></meta>
				<meta property="og:title" content="Privacy | Kijeka Engineers Private Limited"></meta>
				<meta property="og:description" content="Kijeka, a top-notch handling equipment solutions provider, understands the significance of your privacy. Our privacy page outlines our commitment to safeguarding your personal information and how we collect, use, and disclose it. Trust us to handle your data with the utmost respect and care, so you can focus on your business with confidence."></meta>
				<meta property="og:image" content="/static/images/KijekaMeta.png"></meta>
				<meta property="twitter:card" content="summary_large_image"></meta>
				<meta property="twitter:url" content="https://kijeka.com/privacy-policy/"></meta>
				<meta property="twitter:title" content="Privacy | Kijeka Engineers Private Limited"></meta>
				<meta property="twitter:description" content="Kijeka, a top-notch handling equipment solutions provider, understands the significance of your privacy. Our privacy page outlines our commitment to safeguarding your personal information and how we collect, use, and disclose it. Trust us to handle your data with the utmost respect and care, so you can focus on your business with confidence."></meta>
				<meta property="twitter:image" content="/static/images/KijekaMeta.png"></meta>
				<link rel="canonical" href="https://kijeka.com/privacy-policy/" />
			</Helmet>
			<p className="text-kijeka-blue font-bold text-3xl max-sm:text-lg">Privacy Policy for KIJEKA Engineers Pvt. Ltd.</p>
			<br />
			<p className="max-sm:text-sm text-justify">KIJEKA Engineers Pvt. Ltd. respects your privacy and is committed to protecting the personal information that you provide to us. This Privacy Policy explains our policies and practices regarding the collection, use, and disclosure of your personal information.</p>
			<br />

			<p className="text-kijeka-blue font-semibold tracking-[1px] text-xl max-sm:text-lg max-sm:tracking-[0.7px]">Information Collected</p>
			<p className="max-sm:text-sm text-justify">We may collect personal information from you when you request or submit information to KIJEKA Engineers Pvt. Ltd. This information may include your name, email address, phone number, and any other information you may provide. We use this information to contact you in the future to convey information about our solutions or services that we feel may benefit you. We do not share, sell, or rent any of the information collected to any third parties.</p>

			<br />

			<p className="text-kijeka-blue font-semibold tracking-[1px] text-xl max-sm:text-lg max-sm:tracking-[0.7px]">Information Collected by Others</p>

			<p className="max-sm:text-sm text-justify">This Privacy Policy addresses only the policy of KIJEKA Engineers Pvt. Ltd. website and not the sites that users access via links from our site. We are not responsible for the information collection policies of other sites, nor for the practices employed by websites linked to or from our website, nor for the information or content contained therein.</p>

			<br />

			<p className="text-kijeka-blue font-semibold tracking-[1px] text-xl max-sm:text-lg max-sm:tracking-[0.7px]">Cookies</p>

			<p className="max-sm:text-sm text-justify">Our website may use cookies to enhance your user experience. Cookies are small files that are stored on your device when you access and use our website. We use cookies to improve our website, personalize your experience, and track user trends. You can choose to accept or decline cookies by modifying your browser settings.</p>

			<br />

			<p className="text-kijeka-blue font-semibold tracking-[1px] text-xl max-sm:text-lg max-sm:tracking-[0.7px]">Updating, Correcting, and Deleting Personal Information</p>

			<p className="max-sm:text-sm text-justify">
				If you would like to have your personal information removed from KIJEKA Engineers Pvt. Ltd., please send an email to our Data Protection Officer at &nbsp;
				<a data-fr-linked="true" href="mailto:info@kijeka.com" className="text-kijeka-blue">
					info@kijeka.com
				</a>
				&nbsp;with &ldquo;Remove personal information&rdquo; in the subject line. To update, change, or correct your personal information, please send an email to our Data Protection Officer with the appropriate changes.
			</p>

			<br />

			<p className="text-kijeka-blue font-semibold tracking-[1px] text-xl max-sm:text-lg max-sm:tracking-[0.7px]">Legally Compelled Disclosure of Information</p>

			<p className="max-sm:text-sm text-justify">KIJEKA Engineers Pvt. Ltd. may disclose information when legally compelled to do so; in other words, when we, in good faith, believe that the law requires it or for the protection of our legal rights.</p>

			<br />

			<p className="text-kijeka-blue font-semibold tracking-[1px] text-xl max-sm:text-lg max-sm:tracking-[0.7px]">Periodic Policy Changes</p>

			<p className="max-sm:text-sm text-justify">Please note that KIJEKA Engineers Pvt. Ltd. reviews its privacy practices from time-to-time (i.e. to track technology and/or legal changes), and that these practices are subject to change. To ensure continuing familiarity with the most current version of our privacy policy, please bookmark and periodically review this page.</p>

			<br />

			<p className="max-sm:text-sm text-justify">This policy statement is made in the name of KIJEKA Engineers Pvt. Ltd. and is effective as of the current date. This statement does not create an agreement between KIJEKA Engineers Pvt. Ltd. and users, and as such, does not create any legal rights for any party.</p>
		</div>
	);
};

export default Privacy;
