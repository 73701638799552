import React from "react";
import { ImQuotesLeft } from "react-icons/im";
import { Helmet } from "react-helmet";
const About = () => {
	return (
		<div className="px-0 sm:px-10 mt-28 max-w-[80%] mx-auto overflow-x-hidden">
			<Helmet>
				<meta charSet="utf-8" />
				<title>Empowering Material Handling: Kijeka Story</title>

				<meta name="title" content="Empowering Material Handling: Kijeka Story"></meta>
				<meta name="description" content="Discover the remarkable journey of Kijeka, founded by Mr. Rameshchandra in 1980. With our headquarters in Ahmedabad, Kijeka is a leading top-tier material handling company, boasting 40 years of invaluable experience. Join us as we redefine excellence in the industry!"></meta>
				<meta name="keywords" content="Kijeka journey, company history, founder, Rameshchandra, 1980, headquarters, Ahmedabad, material handling, top-tier company, 40 years of experience"></meta>
				<meta http-equiv="Content-Type" content="text/html; charset=utf-8"></meta>
				<meta property="og:type" content="website"></meta>
				<meta property="og:url" content="https://kijeka.com/about"></meta>
				<meta property="og:title" content="About : Kijeka Engineers Private Limited"></meta>
				<meta property="og:description" content="Kijeka is a globally renowned name in the field of handling equipment solutions, catering to a diverse range of industries across the world. With our commitment to delivering high-performing and innovative equipment solutions, we have established ourselves as a trusted partner for businesses of all sizes."></meta>
				<meta property="og:image" content="/static/images/KijekaMeta.png"></meta>
				<meta property="twitter:card" content="summary_large_image"></meta>
				<meta property="twitter:url" content="https://kijeka.com/about"></meta>
				<meta property="twitter:title" content="About : Kijeka Engineers Private Limited"></meta>
				<meta property="twitter:description" content="Kijeka is a globally renowned name in the field of handling equipment solutions, catering to a diverse range of industries across the world. With our commitment to delivering high-performing and innovative equipment solutions, we have established ourselves as a trusted partner for businesses of all sizes."></meta>
				<meta property="twitter:image" content="/static/images/KijekaMeta.png"></meta>
				<link rel="canonical" href="https://kijeka.com/about" />
			</Helmet>
			<div className="relative flex justify-start items-center group mb-4 px-10 max-sm:px-0 mt-5">
				<div className="h-14 w-1.5 max-md:h-12 bg-kijeka-blue mr-[1rem] origin-left group-hover:w-[15rem] duration-300 group"></div>
				{/*title our journey*/}
				<p className="text-2xl max-sm:text-[22px] absolute font-bold text-kijeka-blue ml-[1rem] group-hover:text-white group-hover:translate-x-5 duration-300">Our Journey</p>
			</div>

			<div className="flex justify-center gap-20 items-center py-5 w-full max-md:flex-col max-md:mx-auto max-md:gap-0 pl-0 sm:pl-20">
				<div className="flex flex-col items-start justify-start w-min max-md:items-center max-md:justify-center">
					<div>
						<p className="text-kijeka-blue font-normal text-[14px] mt-5 sm:mt-14 text-justify w-full sm:w-[80%]">
							<span className="text-[10px]">
								<ImQuotesLeft />
							</span>
							Founded by Mr Rameshchandra in 1980, Kijeka Engineers is headquartered in Ahmedabad, India. The company prides itself on providing top-quality products and services that meet the specific needs of its customers. Whether you're looking for a customized material handling solution or on-site support from experienced technicians, Kijeka Engineers has you covered.
						</p>
						<img className="pt-3 mt-10 max-w-md w-[80vw]" src="/static/images/b3.png" />
					</div>

					<div>
						<p className="text-kijeka-blue  font-normal my-10 text-[14px] text-justify w-full sm:w-[80%]">
							<span className="text-[10px]">
								<ImQuotesLeft />
							</span>
							Kijeka Engineers is a leading provider of top-tier materials handling solutions. With over 40 years of experience, the company has been at the forefront of innovation in the industry, delivering reliable and efficient solutions to customers around the world.
						</p>
						<img className="mb-10 pt-3 max-w-md w-[80vw]" src={"/static/images/b1.png"} />
					</div>
				</div>

				<div className="flex sm:flex-col flex-col-reverse sm:items-end sm:justify-end w-full sm:w-min max-md:items-center max-md:justify-center ">
					<div className="flex flex-col-reverse sm:block">
						<img className="max-w-md w-[80vw]" src={"/static/images/b4.png"} />
						<p className="text-kijeka-blue flex flex-col text-[14px] my-10 font-normal text-justify w-full sm:w-[80%]">
							<span className="mt-1 text-[10px] ">
								<ImQuotesLeft />
							</span>
							At Kijeka Engineers, customer satisfaction is the top priority. The company's products are built to the highest standards and are designed to last, delivering a performance that exceeds expectations. Kijeka Engineers is committed to staying ahead of the curve, keeping up with the latest industry developments and trends to ensure its customers can access the most innovative solutions.
						</p>
					</div>

					<div className="flex flex-col-reverse sm:block">
						<img className="max-w-md w-[80vw]" src={"/static/images/b2.png"} />
						<p className="text-kijeka-blue  text-[14px]  my-10 font-normal  text-justify w-full sm:w-[80%]">
							<span className="mt-1 text-[10px]">
								<ImQuotesLeft />
							</span>
							Whether you're looking for a partner for your material handling needs, Kijeka Engineers is the trusted choice. With a focus on quality, efficiency, and customer satisfaction, Kijeka Engineers is dedicated to delivering the best possible results for its customers.
						</p>
					</div>
				</div>
			</div>
		</div>
	);
};

export default About;
