import React, { useEffect, useState, useRef } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Link } from "react-router-dom";
import axios from "axios";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import Form from "./Form";

import { Autoplay, Pagination, Navigation } from "swiper";

const navlinks = [
  {
    name: "Aerial Work Platform",
    link: "https://www.kijeka.com/aerial-work-platform/",
  },
  { name: "Ladders", link: "https://www.kijeka.com/ladders/" },
  {
    name: "Chemical Pumps & Acces",
    link: "https://www.kijeka.com/chemical-pumps-acces/",
  },
  {
    name: "Cranes & Lifting Accessories",
    link: "https://www.kijeka.com/cranes-lifting-accessories/",
  },
  {
    name: "Dock Levellers & Dock Ramp",
    link: "https://www.kijeka.com/dock-levellers-dock-ramp/",
  },
  { name: "Drum Equipments", link: "https://www.kijeka.com/drum-equipments/" },
  {
    name: "Industrial Stirrer",
    link: "https://www.kijeka.com/industrial-stirrer/",
  },
];
const navlinks1 = [
  {
    name: "Fuel Pumps, Meters & Acces",
    link: "https://www.kijeka.com/fuel-pumps-meters-access/",
  },
  { name: "Goods Lift", link: "https://www.kijeka.com/goods-lifts" },
  {
    name: "Hydraulic Scissor Lift Platform",
    link: "https://www.kijeka.com/hydraulic-scissor-lift-platform/",
  },
  {
    name: "Insect Killer Machine",
    link: "https://www.kijeka.com/insect-killer-machine/",
  },
  { name: "Hand Trucks", link: "https://www.kijeka.com/hand-trucks/" },
  {
    name: "Order Picker Trucks",
    link: "https://www.kijeka.com/order-picker-trucks/",
  },
  {
    name: "Oil Pumps, Meters & Acces",
    link: "https://www.kijeka.com/oil-pumps-meters-acces/",
  },
];
const navlinks2 = [
  { name: "Pallet Trucks", link: "https://www.kijeka.com/pallet-trucks/" },
  {
    name: "Polyurethane Wheels & Rollers",
    link: "https://www.kijeka.com/polyurethane-wheels-rollers/",
  },
  { name: "Stackers", link: "https://www.kijeka.com/stackers/" },
  {
    name: "Industrial Springs",
    link: "https://www.kijeka.com/industrial-springs-/",
  },
  { name: "Metals Pallets", link: "https://www.kijeka.com/metals-pallets-/" },
  {
    name: "Gas Cylinder Cart",
    link: "https://www.kijeka.com/gas-cylider-cart/",
  },
];

const Hero = () => {
  return (
    // Hero container
    <div className="h-full w-full flex items-center justify-center  relative">
      <div className="  w-full h-[130vh] md:mb-0 mb-[60vh] ">
        <img
          src="/static/images/hero-1.png"
          className=" w-full h-full"
          alt="adsf"
        />
      </div>

      <div className=" absolute w-full h-full pt-32">
        <div className=" bg-[#f1f1f10e] h-full ">
          <div className="   gap-5   flex md:flex-row flex-col justify-center items-start w-full">
            <div className=" w-full lg:block hidden md:px-0 px-5 md:w-[25%]">
              <Swiper
                spaceBetween={30}
                centeredSlides={true}
                autoplay={{
                  delay: 2500,
                  disableOnInteraction: false,
                }}
                pagination={{
                  clickable: true,
                }}
                navigation={true}
                modules={[Autoplay, Pagination, Navigation]}
                className="mySwiper"
              >
                <SwiperSlide>
                  <div className=" h-full space-y-6 py-14">
                    {navlinks1.map((e, i) => {
                      return (
                        <>
                          <div className="">
                            <a href={`${e.link}`} target="_blank">
                              <button className="    hover:border-kijeka-blue hover:text-kijeka-blue hover:bg-white transition-all duration-300 bg-[#edededc3] backdrop-filter backdrop-blur-md px-8 w-full rounded-full py-2 border-[2px] text-black font-semibold font-body border-[#cbcbcb]">
                                {e.name}
                              </button>
                            </a>
                          </div>
                        </>
                      );
                    })}{" "}
                  </div>
                </SwiperSlide>
                <SwiperSlide>
                  <div className="   h-full space-y-6 py-14">
                    {navlinks2.map((e, i) => {
                      return (
                        <>
                          <div className="">
                            <a href={`${e.link}`} target="_blank">
                              <button className="    hover:border-kijeka-blue hover:text-kijeka-blue hover:bg-white transition-all duration-300 bg-[#edededc3] backdrop-filter backdrop-blur-md px-8 w-full rounded-full py-2 border-[2px] text-black font-semibold font-body border-[#cbcbcb]">
                                {e.name}
                              </button>
                            </a>
                          </div>
                        </>
                      );
                    })}
                  </div>
                </SwiperSlide>
                <SwiperSlide>
                  <div className=" h-full space-y-6 py-14">
                    {navlinks.map((e, i) => {
                      return (
                        <>
                          <div className="">
                            <a href={`${e.link}`} target="_blank">
                              <button className="    hover:border-kijeka-blue hover:text-kijeka-blue hover:bg-white transition-all duration-300 bg-[#edededc3] backdrop-filter backdrop-blur-md px-8 w-full rounded-full py-2 border-[2px] text-black font-semibold font-body border-[#cbcbcb]">
                                {e.name}
                              </button>
                            </a>
                          </div>
                        </>
                      );
                    })}
                  </div>
                </SwiperSlide>
              </Swiper>
            </div>
            <div className=" w-full md:px-0 px-5 md:w-[52%] lg:w-[45%]">
              <Form />
            </div>
            <div className=" md:bg-transparent bg-white w-full md:px-0 px-5 md:w-[40%] lg:w-[25%]">
              <Swiper
                spaceBetween={30}
                centeredSlides={true}
                autoplay={{
                  delay: 2500,
                  disableOnInteraction: false,
                }}
                pagination={{
                  clickable: true,
                }}
                navigation={true}
                modules={[Autoplay, Pagination, Navigation]}
                className="mySwiper"
              >
                <SwiperSlide>
                  <div className=" h-full space-y-6 py-14">
                    {navlinks.map((e, i) => {
                      return (
                        <>
                          <div className="">
                            <a href={`${e.link}`} target="_blank">
                              <button className="    hover:border-kijeka-blue hover:text-kijeka-blue hover:bg-white transition-all duration-300 bg-[#edededc3] backdrop-filter backdrop-blur-md px-8 w-full rounded-full py-2 border-[2px] text-black font-semibold font-body border-[#cbcbcb]">
                                {e.name}
                              </button>
                            </a>
                          </div>
                        </>
                      );
                    })}
                  </div>
                </SwiperSlide>
                <SwiperSlide>
                  <div className=" h-full space-y-6 py-14">
                    {navlinks2.map((e, i) => {
                      return (
                        <>
                          <div className="">
                            <a href={`${e.link}`} target="_blank">
                              <button className="    hover:border-kijeka-blue hover:text-kijeka-blue hover:bg-white transition-all duration-300 bg-[#edededc3] backdrop-filter backdrop-blur-md px-8 w-full rounded-full py-2 border-[2px] text-black font-semibold font-body border-[#cbcbcb]">
                                {e.name}
                              </button>
                            </a>
                          </div>
                        </>
                      );
                    })}
                  </div>
                </SwiperSlide>
                <SwiperSlide>
                  <div className=" h-full space-y-6 py-14">
                    {navlinks1.map((e, i) => {
                      return (
                        <>
                          <div className="">
                            <a href={`${e.link}`} target="_blank">
                              <button className="    hover:border-kijeka-blue hover:text-kijeka-blue hover:bg-white transition-all duration-300 bg-[#edededc3] backdrop-filter backdrop-blur-md px-8 w-full rounded-full py-2 border-[2px] text-black font-semibold font-body border-[#cbcbcb]">
                                {e.name}
                              </button>
                            </a>
                          </div>
                        </>
                      );
                    })}{" "}
                  </div>
                </SwiperSlide>
              </Swiper>
            </div>
          </div>

          {/* <div className=" text-white grid-cols-4 px-24  grid gap-5 w-full">
                        {
                            navlinks.map((e, i) => {
                                return (
                                    <>
                                        <div className="">
                                            <button className="    hover:border-kijeka-blue hover:text-kijeka-blue hover:bg-white transition-all duration-300 bg-[#edededc3] backdrop-filter backdrop-blur-md px-8 w-full rounded-full py-2 border-[2px] text-black font-semibold font-body border-[#cbcbcb]">{e.name}</button>
                                        </div>
                                    </>
                                )
                            })
                        }

                    </div> */}
        </div>
      </div>
    </div>
  );
};

export default Hero;
