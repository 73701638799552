import axios from "axios";
import { useRef, useEffect } from "react";

function Chatbot() {
    const messageEndRef = useRef(null);

    const handleSubmit = (e) => {
        setTimeout(() => {
            document.getElementById("chatBotContainer").scrollIntoView({ behavior: "smooth", block: "end" });
        }, 500);
        if (document.getElementById("chatBotSearch").value !== "") {
            let chatBotContainer = document.getElementById("chatBotContainer");
            let type = "questions";
            if (document.getElementById("chatBotSearch").value.includes("(: ")) {
                type = "type";
            }
            document.getElementById("chatBotSearch").value = document.getElementById("chatBotSearch").value.replace("(: ", "");
            let html = `<div class="mx-[20px] my-[38px]"><div class="flex justify-between w-full"><p></p><p class="bg-[#004B95] font-[poppins] not-italic font-medium text-sm max-sm:text-xs leading-5 text-[#FFFFFF] tracking-[0.03em] rounded-[15px] rounded-br-none h-fit w-fit max-w-[80%] px-[20px] py-[10px] ">` + document.getElementById("chatBotSearch").value + `</p></div></div>`;
            chatBotContainer.innerHTML += html;
            if (type !== "type") {
                axios
                    .get(process.env.REACT_APP_SERVER + "/api/chatbot/?msg=" + document.getElementById("chatBotSearch").value + "&type=" + type)
                    .then((data) => {
                        let msg = data["data"]["msg"];
                        let html = "";
                        if (Array.isArray(msg)) {
                            html = `<div class="mx-[20px] my-[38px] "><p class="bg-[#A0D0FF] font-[poppins] not-italic font-medium text-sm leading-5 text-[#101010] tracking-[0.03em] rounded-[15px] rounded-bl-none h-fit w-fit max-w-[80%] px-[20px] py-[10px] ">` + msg[0] + `</p><span><div class="ml-[20px] mt-[15px]"><div class="grid grid-flow-row grid-cols-3 gap-2 my-[2px] mb-[10px]">`;
                            for (let i = 0; i < msg[1].length; i++) {
                                html += `<button onclick="javascript:document.getElementById('chatBotSearch').value='(: ` + msg[1][i] + `';document.getElementById('chatBotSearchButton').click();" class="bg-[#FFFFFF] border-[#004B95] font-[poppins] not-italic font-medium text-[10px] flex border-2  rounded-[5px] px-[15px] py-[5px]">` + msg[1][i] + `</button>`;
                            }
                            html += `</div></div></span></div>`;
                        } else {
                            html = `<div class="mx-[20px] my-[38px] "><p class="bg-[#A0D0FF] font-[poppins] not-italic font-medium text-sm leading-5 text-[#101010] tracking-[0.03em] rounded-[15px] rounded-bl-none h-fit w-fit max-w-[80%] px-[20px] py-[10px] ">` + msg + `</p></div>`;
                        }
                        chatBotContainer.innerHTML += html;
                    })
                    .catch((error) => console.log(error));
                document.getElementById("chatBotSearch").value = "";
            } else {
                axios
                    .get(process.env.REACT_APP_SERVER + "/api/chatbot/?msg=" + document.getElementById("chatBotSearch").value + "&type=" + type)
                    .then((data) => {
                        let msg = data["data"]["msg"];
                        let html = "";
                        if (Array.isArray(msg)) {
                            html = `<div class="mx-[20px] my-[38px] "><p class="bg-[#A0D0FF] font-[poppins] not-italic font-medium text-sm leading-5 text-[#101010] tracking-[0.03em] rounded-[15px] rounded-bl-none h-fit w-fit max-w-[80%] px-[20px] py-[10px] ">` + document.getElementById("chatBotSearch").value + `</p><span><div class="ml-[20px] mt-[15px]"><div class="grid grid-flow-row grid-cols-3 gap-2 my-[2px] mb-[10px]">`;
                            for (let i = 0; i < msg.length; i++) {
                                if (Array.isArray(msg[i])) {
                                    html += `<button onclick="javascript:window.location.href='/product/` + msg[i][1] + `';" class="bg-[#FFFFFF] border-[#004B95] font-[poppins] not-italic font-medium text-[10px] flex border-2  rounded-[5px] px-[5px] py-[5px]">` + msg[i][0] + `</button>`;
                                } else {
                                    html += `<button onclick="javascript:document.getElementById('chatBotSearch').value='(: ` + msg[i] + `';document.getElementById('chatBotSearchButton').click();" class="bg-[#FFFFFF] border-[#004B95] font-[poppins] not-italic font-medium text-[10px] flex border-2  rounded-[5px] px-[5px] py-[5px]">` + msg[i] + `</button>`;
                                }
                            }
                            html += `</div></div></span></div>`;
                        } else {
                            html = `<div class="mx-[20px] my-[38px] "><p class="bg-[#A0D0FF] font-[poppins] not-italic font-medium text-sm leading-5 text-[#101010] tracking-[0.03em] rounded-[15px] rounded-bl-none h-fit w-fit max-w-[80%] px-[20px] py-[10px] ">` + msg + `</p></div>`;
                        }
                        chatBotContainer.innerHTML += html;
                    })
                    .catch((error) => console.log(error));
                document.getElementById("chatBotSearch").value = "";
            }
        }
    };

    useEffect(() => {
        messageEndRef.current?.scrollIntoView();
    });
    return (
        <>
            <div
                className="h-fit w-72 rounded-lg fixed right-20 bottom-16 shadow-lg origin-bottom transition-all duration-300 z-50 scale-y-0 opacity-0"
                id="chatbotOverlay"
                onMouseLeave={() => {
                    if (!document.getElementById("chatbotOverlay").classList.contains("scale-y-0")) {
                        document.getElementById("chatbotOverlay").classList.add("scale-y-0");
                        document.getElementById("chatbotOverlay").classList.add("opacity-0");
                    }
                }}
            >
                {/*Chatbot header containing title and close button*/}
                <div className="h-16 w-full bg-[#004B95] rounded-t-[10px] text-center sticky top-0 z-20 ">
                    <div
                        onClick={() => {
                            if (!document.getElementById("chatbotOverlay").classList.contains("scale-y-0")) {
                                document.getElementById("chatbotOverlay").classList.add("scale-y-0");
                                document.getElementById("chatbotOverlay").classList.add("opacity-0");
                            }
                        }}
                        className="bg-[#FFFFFF] w-10 h-10 rounded-full absolute left-3 top-3 z-[999]"
                    >
                        <svg className="w-full h-full p-3 flex justify-center items-center" width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M0.616117 0.616117C1.10427 0.127961 1.89573 0.127961 2.38388 0.616117L9 7.23223L15.6161 0.616117C16.1043 0.127961 16.8957 0.127961 17.3839 0.616117C17.872 1.10427 17.872 1.89573 17.3839 2.38388L10.7678 9L17.3839 15.6161C17.872 16.1043 17.872 16.8957 17.3839 17.3839C16.8957 17.872 16.1043 17.872 15.6161 17.3839L9 10.7678L2.38388 17.3839C1.89573 17.872 1.10427 17.872 0.616117 17.3839C0.127961 16.8957 0.127961 16.1043 0.616117 15.6161L7.23223 9L0.616117 2.38388C0.127961 1.89573 0.127961 1.10427 0.616117 0.616117Z" fill="#004B95" />
                            {/* {showComponent &&<Chatbot  handleClose={handleClose}/>} */}
                        </svg>
                    </div>
                    <div className="font-[poppins] h-full not-italic font-semibold text-2xl max-sm:text-lg lg:leading-9 capitalize text-[#FFFFFF] relative top-[27%]">
                        <p>Chat Bot</p>
                    </div>
                </div>
                {/*Chatbot body displaying chats*/}
                <div className="h-72 w-full bg-[#FFFFFF] overflow-y-scroll no-scrollbar z-10" id="chatBotContainer">
                    <div className="mx-[20px] my-[38px] ">
                        <p className="bg-[#A0D0FF] font-[poppins] not-italic font-medium text-sm max-sm:text-xs leading-5 text-[#101010] tracking-[0.03em] rounded-[20px] rounded-bl-none h-auto w-fit px-[20px] py-[10px] ">Hi!! there ,How can i help you?</p>
                    </div>
                </div>
                {/*Chatbot input box and send button*/}
                <div className="h-16 w-full bg-[#FFFFFF] rounded-b-[10px] sticky bottom-0 border overflow-hidden">
                    <input type="text" placeholder="Write Here" id="chatBotSearch" className="w-full h-16  pl-5 pr-16 text-kijeka-blue  shadow-xl border-t max-sm:text-sm focus:outline-none ring-0 focus:ring-2 focus:ring-[#004B9555]" onKeyUp={(event) => (event.key === "Enter" ? handleSubmit(event) : null)} />
                    <button
                        onClick={(event) => {
                            handleSubmit(event);
                        }}
                        type="submit"
                        id="chatBotSearchButton"
                        className="bg-[#37A945] absolute right-0 mt-2 mr-2 rounded-full w-[45px] h-[45px]"
                    >
                        <svg className="absolute right-[15px] bottom-[15px]" width="17" height="17" viewBox="0 0 17 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path fillRule="evenodd" clipRule="evenodd" d="M8.1134 8.8866C8.1134 8.8866 -1.6867 6.85926 1.71889 4.89333C4.59277 3.23446 14.497 0.382345 15.8807 1.11928C16.6177 2.50297 13.7655 12.4072 12.1067 15.2811C10.1407 18.6867 8.1134 8.8866 8.1134 8.8866Z" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                        </svg>
                        <svg className="absolute right-[15.12px] bottom-[22.11px]" width="10" height="10" viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M1.11328 8.88598L8.88058 1.11865" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                        </svg>
                    </button>
                </div>
            </div>
        </>
    );
}

export default Chatbot;
