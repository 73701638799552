import React from "react";

const AddToCart = ({ image, productName }) => {
	const handleAddToCart = () => {
		let products = [];

		if (localStorage.getItem("products")) {
			products = JSON.parse(localStorage.getItem("products"));
		}
		let productFinder = products.find((product) => product.productName === productName);
		if (productFinder === undefined) {
			let swapner = document.getElementById("addTOInquiryButton");
			let reciver = document.getElementById("countForCart");
			let newDiv = document.createElement("div");
			newDiv.innerHTML = "1";
			newDiv.className = "absolute top-0 bg-[#004B96] text-white rounded-full w-[20px] h-[20px] flex justify-center items-center z-50";
			newDiv.id = "cartAdder-1";
			swapner.appendChild(newDiv);
			let reciverPosition = reciver.getBoundingClientRect();
			let newDivPosition = newDiv.getBoundingClientRect();
			let difference = {
				x: reciverPosition.x - newDivPosition.x,
				y: reciverPosition.y - newDivPosition.y,
			};
			newDiv.style.transform = `translate(${difference.x}px, ${difference.y}px)`;
			newDiv.style.transition = "transform 0.5s ease-in-out";
			setTimeout(() => {
				newDiv.remove();
				reciver.innerText = parseInt(reciver.innerText) + 1;
			}, 500);
			products.push({ productName: productName, image: image });
			localStorage.setItem("products", JSON.stringify(products));
			setTimeout(() => {
				window.location.reload();
			}, 500);
			// let cartOverlay = document.getElementById("cartOverlay");
			// cartOverlay.innerHTML +=
			// 	`<div id="cart-` +
			// 	productName +
			// 	`" class="flex items-center gap-8 px-2 py-2 border-b border-[rgba(166,166,166,0.3)] last-of-type:border-none">
			// 	<div class="flex items-center gap-2">
			// 		<div class="border border-kijeka-blue rounded-lg">
			// 			<img src="` +
			// 	image +
			// 	`" class="w-10 h-10 object-contain" />
			// 		</div>
			// 		<p class="truncate text-kijeka-blue text-sm font-medium  w-32">` +
			// 	productName +
			// 	`</p>
			// 	</div>
			// 	<p
			// 		onClick="javascript:location.reload();"
			// 		class="bg-gray-100 px-2 py-1 text-gray-400 text-xs font-semibold rounded-full hover:bg-red-500 hover:text-white cursor-pointer transition-all duration-300 my-2 md:my-0 w-fit"
			// 	>
			// 		Remove
			// 	</p>
			// </div>`;
		} else {
			alert("Product already in cart");
		}
	};

	return (
		<div className="relative flex flex-row xl:absolute xl:left-[80%] xl:mb-[0%] mb-[2%] cursor-pointer">
			<svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
				<path d="M10 2C5.58172 2 2 5.58172 2 10C2 14.4183 5.58172 18 10 18C14.4183 18 18 14.4183 18 10C18 5.58172 14.4183 2 10 2ZM0 10C0 4.47715 4.47715 0 10 0C15.5228 0 20 4.47715 20 10C20 15.5228 15.5228 20 10 20C4.47715 20 0 15.5228 0 10Z" fill="#004B96" />
				<path d="M10 8C10.5523 8 11 8.44771 11 9V15C11 15.5523 10.5523 16 10 16C9.44771 16 9 15.5523 9 15V9C9 8.44771 9.44771 8 10 8Z" fill="#004B96" />
				<path d="M11.5 5.5C11.5 6.32843 10.8284 7 10 7C9.17157 7 8.5 6.32843 8.5 5.5C8.5 4.67157 9.17157 4 10 4C10.8284 4 11.5 4.67157 11.5 5.5Z" fill="#004B96" />
			</svg>
			<p id="addTOInquiryButton" className="ml-[7px] text-kijeka-blue underline font-poppins font-semibold sm:text-[18px] text-sm leading-[32.4px] tracking-[5%] -my-[6px]" onClick={handleAddToCart}>
				Add to Inquiry
			</p>
		</div>
	);
};

export default AddToCart;
