import React from "react";

const PublisherIcon = ({ setReadEnabled, views, author, date, readEnabled }) => {
	return (
		<div className="flex md:flex-row flex-col justify-start items-center lg:pt-[26px] md:gap-[102px] gap-[30px]">
			<div className="flex lg:pl-[13px]">
				<div className="border-[#004B95] w-[20%] h-[40%] border-[2px] rounded-full">
					<img className="" src="/static/images/icon.png" alt="/" />
				</div>

				<div className="pl-[13px]">
					<p>{author}</p>
					<div className="flex xl:flex-row flex-col justify-between items-center gap-2">
						<p>{date}</p>
						<div className="flex flex-row gap-x-[10px]">
							<div>
								<i class="fa-solid fa-eye"></i>
							</div>
							<p>{views}</p>
						</div>
					</div>
				</div>
			</div>
			{!readEnabled ? (
				<button
					className="w-auto h-[50px] px-[10px] rounded-[5px] bg-[#004B95] text-[white] font-semibold sm:text-[20px] text-[16px] tracking-[5%] leading-[40.8px] font-poppins"
					onClick={() => {
						setReadEnabled(true);
					}}
				>
					Read More
				</button>
			) : (
				<></>
			)}
		</div>
	);
};

export default PublisherIcon;
