import React, { useState } from "react";
import axios from "axios";

// Swiper
import { Navigation, Scrollbar, A11y, Autoplay } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/scrollbar";
import "swiper/css/autoplay";

// Components
import Page from "../Page/Page";

const BlogSlider = () => {
	// Variable for blogs
	const [blogs, setBlogs] = useState([
		{
			author: "",
			blogLink: "",
			category: "",
			description: "",
			sources: "",
			title: "",
			views: 0,
		},
	]);

	// Blog data
	if (blogs.length === 1) {
		axios
			.get(process.env.REACT_APP_SERVER + "/api/blogdata/")
			.then((data) => {
				setBlogs(data["data"]["data"]);
			})
			.catch((error) => console.log(error));
	}

	return (
		<div className="relative mx-auto">
			{/* Heading */}
			<div className="box h-[3rem] w-[25rem] relative flex justify-start items-start ml-[2rem] group my-6">
				<div className="h-12 w-1.5 bg-[#004B95] absolute mr-[1rem] origin-left group-hover:w-[10rem] duration-300 group"></div>
				<p className="text-[24px] absolute font-bold text-[#004B95] mt-[0.5rem] ml-[1rem] group-hover:text-white group-hover:translate-x-5 duration-300">Blogs</p>
			</div>
			{/* Blog slider */}
			<div className="min-h-[400px]" style={{ backgroundImage: "url(/static/images/blogsliderbg.png)", backgroundSize: "cover" }}>
				<Swiper
					modules={[Navigation, Scrollbar, A11y, Autoplay]}
					spaceBetween={50}
					slidesPerView={1}
					navigation={{
						prevEl: ".swiper-button-prev",
						nextEl: ".swiper-button-next",
					}}
					autoplay={{
						delay: 2500,
						disableOnInteraction: false,
					}}
					className="no-scrollbar"
				>
					{blogs.slice(0, 6).map((item, index) => (
						<SwiperSlide className="!h-[400px] px-[10%] max-sm:px-[15%] flex items-center justify-center" key={index}>
							{/* Blog */}
							<Page link={"/blog/" + item["blogLink"]} Img={process.env.REACT_APP_SERVER + "/media/images/blog/" + item.image} title={item.title} description={item.description} index={index} />
						</SwiperSlide>
					))}
				</Swiper>
			</div>
			{/* Navigation buttons */}
			<div className="swiper-button-prev"></div>
			<div className="swiper-button-next"></div>
		</div>
	);
};

export default BlogSlider;
