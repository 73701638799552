import React, { useState } from "react";
import axios from "axios";

const OurClients = () => {
	// Variable for client logo
	const [clients, setClients] = useState([]);

	// Client logo data
	if (clients.length === 0) {
		axios
			.get(process.env.REACT_APP_SERVER + "/api/clientList/")
			.then((data) => {
				setClients(data["data"]["data"]);
			})
			.catch((error) => console.log(error));
	}

	return (
		<div className="my-10">
			{/* Heading */}
			<div className="box h-[5rem] w-[25rem] relative flex justify-start items-start ml-[2rem] group">
				<div className="h-12 w-1.5 bg-[#004B95] absolute mr-[1rem] origin-left group-hover:w-[15rem] duration-300 group"></div>
				<p className="text-[24px] absolute font-bold text-[#004B95] mt-[0.5rem] ml-[1rem] group-hover:text-white group-hover:translate-x-5 duration-300">Our clients</p>
			</div>

			{/* Client logos container */}
			<div className="grid lg:grid-cols-8 md:grid-cols-6 grid-cols-2 gap-y-2 ml-5 px-5 justify-center items-center">
				{clients.map((item, index) => (
					<a href={item[2]} className="w-full h-20 lg:w-32 lg:h-20 flex justify-center items-center group p-3 lg:p-0" title={item[0]} key={index}>
						{/* Client logo */}
						<img src={process.env.REACT_APP_SERVER + item[1]} alt={item[0]} title={item[0]} loading="lazy" className="w-full h-full object-contain scale-100 group-hover:scale-110 transition-all duration-300" />
					</a>
				))}
			</div>
		</div>
	);
};

export default OurClients;
