import React, { useState } from "react";

const ShareButton = (props) => {
	return (
		<div className="relative">
			<svg
				className="cursor-pointer"
				width={20}
				height={20}
				viewBox="0 0 23 25"
				fill="none"
				onClick={(event) => {
					event.preventDefault();
					document.getElementById("shareLinkInput").value = window.location+props.link;
					document.getElementById("shareTitle").innerHTML = props.title;
					document.getElementById("shareImage").src = props.image;
					document.getElementById("shareLinkedin").href = "https://www.linkedin.com/shareArticle?mini=true&url=" + encodeURI(window.location+props.link) + "&title=" + encodeURI(props.title) + "&summary=Kijeka Engineers PVT LTD.&source=LinkedIn"
					document.getElementById("shareTwitter").href = "https://twitter.com/intent/tweet?text=" + encodeURI(props.title) + "&url=" + encodeURI(window.location+props.link) + "&hashtags=Kijeka,Engineers,PVT,LTD"
					document.getElementById("shareFacebook").href = "https://www.facebook.com/sharer/sharer.php?u=" + encodeURI(window.location+props.link) + "&quote=" + encodeURI(props.title)
					document.getElementById("shareWhatsapp").href = "https://api.whatsapp.com/send/?phone=919879545352&text="+encodeURI(props.title)+"%0A"+"Kijeka,Engineers,PVT,LTD"+"%0A"+encodeURI(window.location+props.link)+"&type=phone_number&app_absent=0"
					document.getElementById("shareInstagram").href = "https://www.instagram.com/"
					document.getElementById("shareOverlay").classList.remove("scale-0");
					document.getElementById("shareOverlay").classList.remove("opacity-0");
					document.getElementsByTagName("BODY")[0].style.overflow = "hidden";
					
				}}
			>
				<path d="M15.3435 18.4271L7.66273 14.2025M7.65182 10.7712L15.3399 6.54257M21.937 20.1397C21.937 22.2538 20.379 23.9677 18.4572 23.9677C16.5354 23.9677 14.9774 22.2538 14.9774 20.1397C14.9774 18.0256 16.5354 16.3118 18.4572 16.3118C20.379 16.3118 21.937 18.0256 21.937 20.1397ZM21.937 4.82795C21.937 6.94206 20.379 8.65589 18.4572 8.65589C16.5354 8.65589 14.9774 6.94206 14.9774 4.82795C14.9774 2.71383 16.5354 1 18.4572 1C20.379 1 21.937 2.71383 21.937 4.82795ZM8.0179 12.4838C8.0179 14.598 6.45995 16.3118 4.53812 16.3118C2.6163 16.3118 1.05835 14.598 1.05835 12.4838C1.05835 10.3697 2.6163 8.65589 4.53812 8.65589C6.45995 8.65589 8.0179 10.3697 8.0179 12.4838Z" stroke="#004B95" strokeWidth={1.5} />
			</svg>
		</div>
	);
};

export default ShareButton;
