import React from "react";
import { Helmet } from "react-helmet";
const TermsCon = () => {
	return (
		<div className="px-48 pt-24 pb-12 max-sm:px-4">
			<Helmet>
				<meta charSet="utf-8" />
				<title>Guidelines and Rights: Kijeka Privacy Terms and Conditions - Protecting Your Data</title>

				<meta name="title" content="Guidelines and Rights: Kijeka Privacy Terms and Conditions - Protecting Your Data"></meta>
				<meta name="description" content="Familiarize yourself with the terms and conditions that govern your usage of Kijeka's services. Our Terms and Conditions page outlines the guidelines, rights, and responsibilities that ensure a fair and transparent relationship between you and Kijeka."></meta>
				<meta name="keywords" content="terms and conditions, terms of service, usage guidelines, rights and responsibilities, user agreement, legal terms, fair usage policy, governing terms"></meta>
				<meta http-equiv="Content-Type" content="text/html; charset=utf-8"></meta>
				<meta property="og:type" content="website"></meta>
				<meta property="og:url" content="https://www.kijeka.com/terms-and-condition/"></meta>
				<meta property="og:title" content="Terms & Condition | Kijeka Engineers Private Limited"></meta>
				<meta property="og:description" content="Stay informed and compliant with Kijeka's terms and conditions for our high-performing handling equipment solutions. As a leading provider for industries worldwide, we prioritize transparency and accountability in all aspects of our business. Review our terms to ensure a smooth and productive partnership with Kijeka."></meta>
				<meta property="og:image" content="/static/images/KijekaMeta.png"></meta>
				<meta property="twitter:card" content="summary_large_image"></meta>
				<meta property="twitter:url" content="https://www.kijeka.com/terms-and-condition/"></meta>
				<meta property="twitter:title" content="Terms & Condition | Kijeka Engineers Private Limited"></meta>
				<meta property="twitter:description" content="Stay informed and compliant with Kijeka's terms and conditions for our high-performing handling equipment solutions. As a leading provider for industries worldwide, we prioritize transparency and accountability in all aspects of our business. Review our terms to ensure a smooth and productive partnership with Kijeka."></meta>
				<meta property="twitter:image" content="/static/images/KijekaMeta.png"></meta>
				<link rel="canonical" href="https://www.kijeka.com/terms-and-condition/" />
			</Helmet>
			<h1 className="text-kijeka-blue font-bold text-3xl max-sm:text-lg">Terms And Conditions</h1>
			<br />
			<p className="max-sm:text-sm text-justify">
				Thank you for visiting the website of Kijeka Engineers Pvt. Ltd. By accessing our website, you agree to comply with the following terms and conditions, which apply to the use of the Kijeka website, &nbsp;
				<a href="http://www.kijeka.com" className="text-kijeka-blue">
					www.kijeka.com
				</a>
				<span> .</span>
			</p>
			<br />
			<div>
				<span className="text-kijeka-blue font-semibold tracking-[1px] text-xl max-sm:text-lg max-sm:tracking-[0.7px]">Introduction:</span>
				<br />
				<p className="max-sm:text-sm text-justify">Kijeka Engineers Pvt. Ltd. is a private limited company incorporated in India. These terms and conditions apply to the use of the Kijeka website, www.kijeka.com (the &ldquo;Website&rdquo;).</p>
			</div>
			<br />
			<div>
				<span className="text-kijeka-blue font-semibold tracking-[1px] text-xl max-sm:text-lg max-sm:tracking-[0.7px]">Acceptance of Terms and Conditions:</span>
				<br />
				<p className="max-sm:text-sm text-justify">By accessing or using the Website, you agree to be bound by these terms and conditions. If you do not agree with any part of these terms and conditions, you must not access or use the Website.</p>
			</div>
			<br />
			<div>
				<span className="text-kijeka-blue font-semibold tracking-[1px] text-xl max-sm:text-lg max-sm:tracking-[0.7px]">Intellectual Property Rights:</span>
				<br />
				<p className="max-sm:text-sm text-justify">The Website and its contents, including but not limited to text, graphics, images, logos, trademarks, service marks, trade names, software and other materials are owned or licensed by Kijeka and are protected by applicable intellectual property laws. You may not reproduce, modify, distribute, transmit, display, perform, publish, license, create derivative works from, transfer or sell any part of the Website or its contents.</p>
			</div>
			<br />
			<div>
				<span className="text-kijeka-blue font-semibold tracking-[1px] text-xl max-sm:text-lg max-sm:tracking-[0.7px]">Use of the Website:</span>
				<br />
				<p className="max-sm:text-sm text-justify">You agree to use the Website only for lawful purposes and in accordance with these terms and conditions. You are prohibited from:</p>
			</div>
			<ul className="px-12 list-outside text-md">
				<li className="list-disc">
					<p className="max-sm:text-sm text-justify">Attempting to interfere with the proper functioning of the Website or its security features; Accessing any non-public areas of the Website.</p>
				</li>
				<li className="list-disc">
					<p className="max-sm:text-sm text-justify">Impersonating any person or entity, or falsely representing your affiliation with any person or entity; Using the Website to transmit any advertising, promotional materials, or other forms of solicitation.</p>
				</li>
				<li className="list-disc">
					<p className="max-sm:text-sm text-justify">Using the Website to violate any applicable laws, rules or regulations.</p>
				</li>
			</ul>
			<br />
			<div>
				<span className="text-kijeka-blue font-semibold tracking-[1px] text-xl max-sm:text-lg max-sm:tracking-[0.7px]">User Content:</span>
				<br />
				<ul className="px-12 list-outside text-md">
					<li className="list-disc">
						<p className="max-sm:text-sm text-justify">You are solely responsible for any content that you upload, post, or otherwise make available on the Website. You represent and warrant that you own or have the necessary licences, rights, consents, and permissions to use and authorise Kijeka to use all trademarks, copyrighted materials, trade secrets, or other proprietary rights in and to any and all user content.</p>
					</li>
					<li className="list-disc">
						<p className="max-sm:text-sm text-justify">All company names, logos, trademarks or registered trademarks mentioned in website are of their respective owners. Use of them does not imply any affiliation with or endorsement by them.</p>
					</li>
				</ul>
			</div>
			<br />
			<div>
				<span className="text-kijeka-blue font-semibold tracking-[1px] text-xl max-sm:text-lg max-sm:tracking-[0.7px]">Privacy:</span>
				<br />
				<p className="max-sm:text-sm text-justify"> Kijeka&#39;s privacy policy governs the collection, use and disclosure of your personal information. Please review our Privacy Policy to learn more about how we collect and use your personal information.</p>
			</div>
			<br />
			<div>
				<span className="text-kijeka-blue font-semibold tracking-[1px] text-xl max-sm:text-lg max-sm:tracking-[0.7px]">Limitation of Liability:</span>
				<br />
				<p className="max-sm:text-sm text-justify">Kijeka is not liable for any direct, indirect, incidental, special, consequential or exemplary damages resulting from your use of the Website or any content on the Website. Kijeka&#39;s liability shall not exceed the amount paid by you to Kijeka, if any, for access to the Website.</p>
			</div>
			<br />
			<div>
				<span className="text-kijeka-blue font-semibold tracking-[1px] text-xl max-sm:text-lg max-sm:tracking-[0.7px]">Indemnification:</span>
				<br />
				<p className="max-sm:text-sm text-justify"> You agree to indemnify, defend, and hold Kijeka, its affiliates, officers, directors, employees, agents, licensors, and suppliers harmless from and against all claims, liabilities, damages, losses, costs, and expenses (including reasonable attorneys&#39; fees) arising from your use of the Website or any violation of these terms and conditions.</p>
			</div>
			<br />
			<div>
				<span className="text-kijeka-blue font-semibold tracking-[1px] text-xl max-sm:text-lg max-sm:tracking-[0.7px]">Termination:</span>
				<br />
				<p className="max-sm:text-sm text-justify">Kijeka may terminate or suspend your access to the Website at any time without notice and without liability to you.</p>
			</div>
			<br />
			<div>
				<span className="text-kijeka-blue font-semibold tracking-[1px] text-xl max-sm:text-lg max-sm:tracking-[0.7px]">Governing Law and Jurisdiction:</span>
				<br />
				<p className="max-sm:text-sm text-justify"> These terms and conditions shall be governed by and construed in accordance with the laws of India. Any disputes arising out of or relating to these terms and conditions shall be resolved exclusively in the courts located in Ahmedabad, India.</p>
			</div>
			<br />
			<div>
				<span className="text-kijeka-blue font-semibold tracking-[1px] text-xl max-sm:text-lg max-sm:tracking-[0.7px]">Changes to Terms and Conditions:</span>
				<br />
				<p className="max-sm:text-sm text-justify"> Kijeka reserves the right to modify these terms and conditions at any time without prior notice. Your continued use of the Website following any such modifications constitutes your acceptance of the revised terms and conditions.</p>
			</div>
			<br />
			<div>
				<span className="text-kijeka-blue font-semibold tracking-[1px] text-xl max-sm:text-lg max-sm:tracking-[0.7px]">Contact Us:</span>
				<br />
				<p className="max-sm:text-sm text-justify">
					If you have any questions about these terms and conditions, please contact us at &nbsp; &nbsp; &nbsp;
					<a href="mailto:info@kijeka.com" className="text-kijeka-blue">
						info@kijeka.com
					</a>
					<span> .</span>
				</p>
			</div>
		</div>
	);
};

export default TermsCon;
