import React from "react";

const Stars = ({ stars, rating }) => {
	const ratingStars = Array.from({ length: 5 }, (elem, index) => {
		return <span key={index}>{stars >= index + 1 ? <i className="fa-solid fa-star w-[15px]  lg:w-[19.37px]  lg:h-[19.37px] text-[#004B95] pr-[4.04px] text-[12px]"></i> : <i className="fa-regular fa-star w-[15px] lg:w-[19.37px]  lg:h-[19.37px] text-[#004B95] pr-[4.04px]   text-[12px]"></i>}</span>;
	});
    
	return (
		<div className="flex items-center mt-2">
			<div className="flex items-center">
				{ratingStars}
				<p className="text-[12px] text-[#004B95] flex items-center">( {rating} )</p>
			</div>
		</div>
	);
};

export default Stars;
