import React from "react";
import ProductCard from "../AllProducts/ProductCard";

const MainProducts = (props) => {
  return (
    <>
      <div className="flex p-[10px] pt-0 max-w-full mb-10">
        <div className="grid grid-flow-row grid-cols-1 gap-3 sm:grid-cols-2 md:grid-cols-2 lg:grid-cols-4  w-full h-full">
          {props.product.map((data, index) => {
            if (data[0] !== "") {
              return (
                <ProductCard
                  key={index}
                  index={index}
                  link={data[4]}
                  liked={data[3]}
                  title={data[0]}
                  description={data[1]}
                  img={process.env.REACT_APP_SERVER + data[2]}
                  rating={data[7]}
                  stars={data[6]}
                />
              );
            } else {
              return <></>;
            }
          })}
        </div>
      </div>
    </>
  );
};

export default MainProducts;
