import React, { useState, useEffect } from "react";
import axios from "axios";
const Inquiry = () => {
	const [cartData, setCartData] = useState([]);
	useEffect(() => {
		if (cartData.length === 0) {
			setCartData(JSON.parse(localStorage.getItem("products")));
		}
	}, [cartData]);
	return (
		<div className="mt-24">
			<div className="relative flex justify-start items-center group mb-4 px-32 max-md:px-10 max-md:text-md">
				<div className="h-12 w-1.5 bg-[#004B95] mr-[1rem] origin-left group-hover:w-[15rem] duration-300 group max-md:h-10"></div>
				<p className="text-[24px] absolute font-bold text-[#004B95] ml-[1rem] group-hover:text-white group-hover:translate-x-5 duration-300">Inquiry</p>
			</div>
			<div className="flex flex-col-reverse pb-10 lg:flex-row-reverse p-12 items-center gap-32 justify-center max-sm:p-0">
				{/* <!--right side--> */}
				<div className="text-[#004B95] w-min max-md:w-full px-12 pb-10">
					<div className="flex flex-col gap-y-5">
						<div className="flex items-center gap-x-[5.5rem] max-md:flex-col max-md:items-start">
							<label for="name" className=" font-bold max-md:text-[18px] text-[22px]">
								Name:
							</label>
							<input type="text" id="name" name="name" placeholder="Eg.Rahul" required className=" border-[2px] text-[14px] focus:outline-[#004B95] text-gray-500 placeholder-[#A6A6A64D] py-1 px-3 rounded-lg border-[#A6A6A64D]" />
						</div>

						<div className="flex items-center gap-x-24 max-md:flex-col max-md:items-start">
							<label for="email" className="font-bold max-md:text-[18px] text-[22px]">
								Email:
							</label>
							<input type="email" id="email" name="email" placeholder="Eg.Rahul123@gmail.com" className=" border-[2px] text-[14px] focus:outline-[#004B95] text-gray-500 placeholder-[#A6A6A64D] py-1 px-3 rounded-lg border-[#A6A6A64D]" />
						</div>

						<div className="flex items-center gap-x-12 max-md:flex-col max-md:items-start">
							<label for="mobileno" className="font-bold max-md:text-[18px] text-[22px]">
								Mobile No:
							</label>
							<input type="text" id="phoneNo" name="phoneNo" placeholder="Eg. +91 7777989922" className=" border-[2px]  text-[14px] focus:outline-[#004B95] text-gray-500 placeholder-[#A6A6A64D] py-1 px-3 rounded-lg border-[#A6A6A64D]" />
						</div>

						<div className="flex items-center gap-x-16 max-md:flex-col max-md:items-start">
							<label for="country" className="font-bold max-md:text-[18px] text-[22px]">
								Country:
							</label>
							<input type="text" id="country" name="country" placeholder="Eg. India" className=" border-[2px] f text-[14px] focus:outline-[#004B95] text-gray-500 placeholder-[#A6A6A64D] py-1 px-3 rounded-lg border-[#A6A6A64D]" />
						</div>

						<div className="flex items-center gap-x-10 max-md:flex-col max-md:items-start">
							<label for="company" className="font-bold max-md:text-[18px] text-[22px]">
								Company:
							</label>
							<input type="text" id="companyName" name="companyName" placeholder="Eg. Amazon" className=" border-[2px] f text-[14px] focus:outline-[#004B95] text-gray-500 placeholder-[#A6A6A64D] py-1 px-3 rounded-lg border-[#A6A6A64D]" />
						</div>

						<div className="flex items-center gap-x-16 max-md:flex-col max-md:items-start">
							<label for="address" className="font-bold max-md:text-[18px] text-[22px]">
								Address:
							</label>
							<input type="text" placeholder="Eg. Cecilia Chapman 711-2880 Nulla St." id="address" name="address" className=" border-[2px]  text-[14px] focus:outline-[#004B95] text-gray-500 placeholder-[#A6A6A64D] py-1 px-3 rounded-lg border-[#A6A6A64D]" />
						</div>

						<div className="flex items-center gap-x-2 ">
							<input type="checkbox" id="isSuscribed" name="isSuscribed" checked />
							<label for="checkbox" className=" text-[#004B95] font-normal text-[16px]">
								Subscribe for Latests News
							</label>
						</div>
						<div className="g-recaptcha" data-sitekey="6LcP1-wkAAAAAJ7rGFlQwmL4DvUvmfIj6FljoNrX"></div>
						<div className="flex gap-x-20 max-md:gap-x-5">
							<button className="text-[#004B95] h-[70%] w-[40%] max-md:w-[45%] max-md:text-lg max-md:h-[80%] p-2 font-poppins text-[20px] border-[1px] border-[#004B95] rounded-lg font-semibold hover:text-white hover:bg-red-600 hover:duration-700 duration-700 hover:cursor-pointer hover:border-0">Cancel</button>

							<button
								className="text-white h-[70%] w-[40%] max-md:w-[45%] max-md:text-lg max-md:h-[80%] p-2 bg-[#004B95] font-poppins text-[20px] border-2 border-[#004B95] rounded-lg font-semibold hover:text-white hover:bg-[#004B95] hover:duration-700 duration-700 hover:cursor-pointer "
								onClick={() => {
									let name = document.getElementById("name").value;
									let email = document.getElementById("email").value;
									let phoneNo = document.getElementById("phoneNo").value;
									let country = document.getElementById("country").value;
									let companyName = document.getElementById("companyName").value;
									let query = "";
									for (let i = 0; i < cartData.length; i++) {
										query += cartData[i]["productName"] + ",";
									}
									let address = document.getElementById("address").value;
									let isSuscribed = document.getElementById("isSuscribed").value;
									if (name && email && phoneNo && country && companyName && query && address) {
										let gifContainer = document.getElementById("GIF");
										gifContainer.classList.remove("opacity-0");
										gifContainer.classList.remove("scale-0");
										gifContainer.classList.add("opacity-100");
										gifContainer.classList.add("scale-100");
										setTimeout(() => {
											gifContainer.classList.remove("opacity-100");
											gifContainer.classList.remove("scale-100");
											gifContainer.classList.add("opacity-0");
											gifContainer.classList.add("scale-0");
											window.location.href = "/";
										}, 2000);
										let formData = new FormData();
										formData.append("name", name);
										formData.append("email", email);
										formData.append("phoneNo", phoneNo);
										formData.append("country", country);
										formData.append("companyName", companyName);
										formData.append("query", query);
										formData.append("address", address);
										formData.append("isSuscribed", isSuscribed);
										axios
											.post(process.env.REACT_APP_SERVER + "/api/inquiryform/", formData)

											.then((res) => {
												document.getElementById("name").disabled = true;
												document.getElementById("email").disabled = true;
												document.getElementById("phoneNo").disabled = true;
												document.getElementById("country").disabled = true;
												document.getElementById("companyName").disabled = true;
												document.getElementById("query").disabled = true;
												document.getElementById("address").disabled = true;
												document.getElementById("isSuscribed").disabled = true;
												document.getElementById("name").disabled = "";
												document.getElementById("email").disabled = "";
												document.getElementById("phoneNo").disabled = "";
												document.getElementById("country").disabled = "";
												document.getElementById("companyName").disabled = "";
												document.getElementById("query").disabled = "";
												document.getElementById("address").disabled = "";
												document.getElementById("isSuscribed").disabled = "";
											});
									} else {
										alert("Please fill all the fields");
									}
								}}
							>
								Send
							</button>
						</div>
					</div>
				</div>

				{/* <!--left side--> */}
				<div className="w-max text-[#004B95] max-md:w-[80%]">
					<div className="border border-grey-300 rounded-lg shadow-[0_2px_20px_rgba(166,166,166,0.25)] bg-white min-w-[500px] w-full lg:w-full h-full lg:h-full py-3">
						<div className="flex items-center justify-between px-5 mt-5">
							<p className="font-bold text-[22px] text-[#004B95]">Added Products</p>
						</div>
						<div className="px-2 overflow-y-scroll h-[450px] no-scrollbar">
							{cartData.map((item, index) => {
								return (
									<div key={index} id="product_details" className="px-2 flex my-2 items-center justify-between rounded-lg shadow-[0_2px_20px_rgba(166,166,166,0.25)] bg-white w-full md:w-[90%] h-[72px] md:mx-auto md:px-4">
										<div className="border border-kijeka-blue rounded-lg">
											<img src={item.image} className="w-20 h-10 object-contain" alt="" />
										</div>
										<div className="px-6 w-[350px]">
											<p className="text-[12px] text-[#004B95] font-semibold">{item.productName}</p>
										</div>
										<div className="bg-[#D9D9D9] py-1 px-4 rounded-full max-md:bg-red-600 hover:bg-red-600 hover:duration-700 duration-700 hover:cursor-pointer">
											<p
												onClick={() => {
													let temp = cartData;
													temp.splice(index, 1);
													setCartData(temp);
													document.getElementById("cart-" + item.productName).remove();
													document.getElementById("product_details").remove();
													localStorage.setItem("products", JSON.stringify(temp));
													setTimeout(() => {
														window.location.reload();
													}, 500);
												}}
												className="text-[#FFFFFF] text-[10px] font-semibold"
											>
												Remove
											</p>
										</div>
									</div>
								);
							})}
						</div>
					</div>
				</div>
				<div className="backdrop-blur-sm bg-white opacity-0 scale-0 absolute w-full h-full flex items-center transition-all duration-300" id="GIF">
					<div className="w-96 h-96 mx-auto object-contain bg-blend-screen overflow-visible" style={{ backgroundImage: "url(/static/images/bgillustration1_1.gif)", backgroundSize: "cover", backgroundRepeat: "no-repeat" }}></div>
				</div>
			</div>
		</div>
	);
};

export default Inquiry;
