import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import AOS from "aos";

// Components
import ShareButton from "../AllProducts/ShareButton";
import Stars from "../AllProducts/Stars";

const CategoryCard = ({ title, description, img, rating, stars, setProductShown, index, link }) => {
	useEffect(() => {
		AOS.init();
	}, []);

	return (
		<>
			{/* Category container */}
			<div data-aos="fade-down" data-aos-duration="1000" className="w-auto relative overflow-hidden py-3 lg:px-5 px-2 h-[320px] rounded-lg border-[2px] hover:border-[#004B95] bg-white shadow-[0px_4px_20px_rgba(166,166,166,0.3)] mb-8 last-of-type:mb-0" onMouseEnter={() => setProductShown(index)}>
				{/* Category title */}
				<div className="flex justify-between items-center">
					<Link to={"/" + link}>
						<p className={`text-start font-poppins font-bold text-[14px] leading-[131%] tracking-[0.055em] text-[#004B95]`}>{title}</p>
					</Link>
					<ShareButton title={title} link={link} image={img} />
				</div>

				{/* Category image */}
				<Link to={"/" + link}>
					<img className="w-[80%] mx-[10%] h-[55%] object-contain relative" title={img} loading="lazy" src={img} alt={img} />
				</Link>

				{/* Category rating */}
				<Stars rating={rating} stars={stars} />

				{/* Link to the products of that category */}
				<div className="text-center absolute bottom-3 w-[86%]">
					<Link to={"/" + link}>
						<button className="text-center text-[#FFFFFF] lg:text-sm text-xs bg-[#004B95] w-full h-[40px] rounded-[5px] font-poppins font-medium leading-[25.83px] tracking-[6%]">See More Products</button>
					</Link>
				</div>
			</div>
		</>
	);
};

export default CategoryCard;
