import React from "react";
import { Link } from "react-router-dom";
import parse from "html-react-parser";

const Page = (props) => {
	return (
		<div className="flex flex-row items-center justify-between h-full gap-x-20">
			{/* Blog image */}
			<div className="hidden lg:block w-[600px] h-[80%]" data-aos="fade-down-right" data-aos-duration="1500">
				<img src={props.Img} alt={props.Img} title={props.Img} loading="lazy" className="object-contain w-full h-full" />
			</div>

			<div className="text-white ">
				{/* Blog title */}
				<p className="text-3xl max-sm:text-[20px] font-semibold" data-aos="zoom-out-down" data-aos-duration="1500">
					{props.title}
				</p>

				{/* Blog description */}
				<p className="mt-3 h-30 discriptionTruncate-5 text-[16px] text-white text-justify lg:text-left max-sm:text-[14px] font-[300] space-x-28" data-aos="zoom-out-right" data-aos-duration="1500">
					{parse(props.description.replace(/<h1/g, "<p").replace(/h1>/g, "p>"))}
				</p>

				{/* Link to that perticular blog */}
				<Link to={props.link}>
					<div
						className="flex items-center gap-2 mt-6 hover:gap-4 hover:bg-white border group border-white w-fit py-2 px-4 rounded-lg transition-all duration-300 cursor-pointer animate__animated animate__fadeInUp"
						style={{ animationDelay: "0.5s" }}
						onMouseEnter={() => {
							document.getElementById("readMoreArrowBlog" + props.index).style.stroke = "black";
						}}
						onMouseLeave={() => {
							document.getElementById("readMoreArrowBlog" + props.index).style.stroke = "white";
						}}
					>
						<p className="text-white group-hover:text-black font-semibold tracking-[1px] transition-all duration-300">Read More</p>
						<svg width="25" height="19" viewBox="0 0 32 19" fill="none" xmlns="http://www.w3.org/2000/svg">
							<path d="M20.0024 2L30 9.5L20.0024 17M2 9.5H29.72" style={{ stroke: "white" }} id={"readMoreArrowBlog" + props.index} strokeWidth="2.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
						</svg>
					</div>
				</Link>
			</div>
		</div>
	);
};

export default Page;
