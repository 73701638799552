import React from "react";
import InquiryForm from "./Form";

const Modal = ({ setShowPopup }) => {
  return (
    <div className="fixed inset-0 z-50 flex items-center justify-center overflow-hidden outline-none focus:outline-none font-display">
      {/* Background overlay */}
      <div className="fixed inset-0 bg-black/30 backdrop-blur-[2px] -z-50"></div>

      {/* Modal content */}
      <div className="relative lg:w-[40%] w-[80%] mx-auto my-6 shadow-lg z-50">
        <div className="bg-white/90 backdrop-blur-md rounded-lg shadow-lg overflow-auto">
          <div className="flex flex-col p-5">
            <div className="flex items-center justify-between">
              <h1 className="text-xl font-semibold text-kijeka-blue font-display">
                Inquiry Form
              </h1>
              <button
                className="text-gray-500 hover:text-gray-800 focus:outline-none"
                onClick={() => setShowPopup(false)}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="h-6 w-6"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2}
                    d="M6 18L18 6M6 6l12 12"
                  />
                </svg>
              </button>
            </div>
            <div className="mt-3">
              <InquiryForm />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Modal;
