import React from "react";
import { Helmet } from "react-helmet";
//Components
import DetailedCareer from "../Components/DetailedCareer/DetailedCareer";

const Careers = () => {
	return (
		<div>
			<Helmet>
				<meta charSet="utf-8" />
				<title>Join Our Team: Explore Exciting Careers at Kijeka</title>

				<meta name="title" content="Join Our Team: Explore Exciting Careers at Kijeka"></meta>
				<meta name="description" content="Explore exciting career opportunities at Kijeka and join our dynamic team. Discover rewarding roles and a supportive work environment to advance your professional journey. Start your path to success with Kijeka today!"></meta>
				<meta name="keywords" content="job opportunities, career openings, employment, work opportunities, professional growth, job vacancies, join our team, career development"></meta>
				<meta http-equiv="Content-Type" content="text/html; charset=utf-8"></meta>
				<meta property="og:type" content="website"></meta>
				<meta property="og:url" content="https://kijeka.com/careers"></meta>
				<meta property="og:title" content="Careers : Kijeka Engineers Private Limited"></meta>
				<meta property="og:description" content="Our careers page at Kijeka highlights the exciting opportunities to join a leading provider of high-performing handling equipment solutions that support industries worldwide."></meta>
				<meta property="og:image" content="/static/images/KijekaMeta.png"></meta>
				<meta property="twitter:card" content="summary_large_image"></meta>
				<meta property="twitter:url" content="https://kijeka.com/careers"></meta>
				<meta property="twitter:title" content="Careers : Kijeka Engineers Private Limited"></meta>
				<meta property="twitter:description" content="Our careers page at Kijeka highlights the exciting opportunities to join a leading provider of high-performing handling equipment solutions that support industries worldwide."></meta>
				<meta property="twitter:image" content="/static/images/KijekaMeta.png"></meta>
				<link rel="canonical" href="https://kijeka.com/careers" />
			</Helmet>
			<DetailedCareer />
		</div>
	);
};

export default Careers;
