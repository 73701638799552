import React, { useEffect, useState } from "react";
import MainProducts from "./MainProductsNew";
import BlogsList from "../Blogs/BlogsList";
import ShareProduct from "../ShareProduct/ShareProduct";
import { useParams } from "react-router-dom";
import axios from "axios";
import SwiperCore, { Autoplay } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/autoplay";
import "swiper/swiper-bundle.min.css";
import "swiper/swiper.min.css";
import Quotation from "../WhatPeopleSay/Quotation.jsx";
import "aos/dist/aos.css";
import { Helmet } from "react-helmet";
import Details from "../ProductDetails/Details";

const quotesImages = [
  "/static/images/QBG1.png",
  "/static/images/QBG2.png",
  "/static/images/QBG3.png",
  "/static/images/QBG4.png",
  "/static/images/QBG5.png",
  "/static/images/QBG6.png",
  "/static/images/QBG7.png",
  "/static/images/QBG8.png",
  "/static/images/QBG9.png",
  "/static/images/QBG10.png",
  "/static/images/QBG11.png",
  "/static/images/QBG12.png",
  "/static/images/QBG13.png",
  "/static/images/QBG14.png",
  "/static/images/QBG15.png",
];

SwiperCore.use([Autoplay]);

const BlogNew = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const params = useParams();
  let temp = "Hot Products";
  let temp2 = "";

  if (params["link"] !== undefined) {
    temp = params["link"];
  }
  if (params["subLink"] !== undefined) {
    temp2 = params["subLink"];
  }

  const selectedCat = temp;
  const subLink = temp2;

  const [category, setCategory] = useState([]);
  const [products, setProducts] = useState([]);
  const [qoutes, setQoutes] = useState([]);
  const [metaTitle, setMetaTitle] = useState("");
  const [metaDescription, setMetaDescription] = useState("");

  useEffect(() => {
    if (qoutes.length === 0) {
      axios
        .get(
          process.env.REACT_APP_SERVER +
            "/api/quoteList/?category=" +
            selectedCat
        )
        .then((data) => setQoutes(data["data"]["data"]))
        .catch((error) => console.log(error));
    }
    if (category.length === 0) {
      axios
        .get(process.env.REACT_APP_SERVER + "/api/categorySideBar/")
        .then((data) => setCategory(data["data"]["data"]))
        .catch((error) => console.log(error));
    }
    if (products.length === 0) {
      // post in axios
      axios
        .get(
          process.env.REACT_APP_SERVER +
            "/api/getCategoryProducts/?category=" +
            selectedCat +
            "&subCategory=" +
            subLink
        )
        .then((data) => {
          if (data["data"]["data"][0][0] !== "") {
            setProducts(data["data"]["data"]);
            setMetaTitle(data["data"]["metaTitle"]);
            var el = document.createElement("html");
            el.innerHTML = data["data"]["metaDescription"];
            setMetaDescription(el.innerText.slice(0, 170));
          } else {
            window.location.href = "/page-not-found";
          }
        })
        .catch((error) => console.log(error));
    }
  }, [category, products, qoutes, selectedCat]);

  return (
    <div className="mt-16">
      <Helmet>
        <meta charSet="utf-8" />
        <title>
          {selectedCat === undefined
            ? "Hot Products : Kijeka Engineers Private Limited"
            : metaTitle + " : Kijeka Engineers Private Limited"}
        </title>

        <meta
          name="title"
          content={
            selectedCat === undefined
              ? "Hot Products : Kijeka Engineers Private Limited"
              : metaTitle + " : Kijeka Engineers Private Limited"
          }
        ></meta>
        <meta name="description" content={metaDescription}></meta>
        <meta
          name="keywords"
          content="hot products, trending products, popular products, innovative solutions, high-performance equipment, material handling, efficiency, productivity, cutting-edge technology, top-tier products"
        ></meta>
        <meta
          http-equiv="Content-Type"
          content="text/html; charset=utf-8"
        ></meta>
        <meta property="og:type" content="website"></meta>
        <meta
          property="og:url"
          content="https://kijeka.com/our-products"
        ></meta>
        <meta
          property="og:title"
          content={metaTitle + ": Kijeka Engineers Private Limited"}
        ></meta>
        <meta property="og:description" content={metaDescription}></meta>
        <meta
          property="og:image"
          content="/static/images/KijekaMeta.png"
        ></meta>
        <meta property="twitter:card" content="summary_large_image"></meta>
        <meta
          property="twitter:url"
          content="https://kijeka.com/our-products"
        ></meta>
        <meta
          property="twitter:title"
          content={metaTitle + ": Kijeka Engineers Private Limited"}
        ></meta>
        <meta property="twitter:description" content={metaDescription}></meta>
        <meta
          property="twitter:image"
          content="/static/images/KijekaMeta.png"
        ></meta>
        <link rel="canonical" href="https://kijeka.com/our-products" />
      </Helmet>
      <ShareProduct />
      <div className="w-full relative">
        <div className="text-white">
          <Swiper
            grabCursor={true}
            centeredSlides={true}
            slidesPerView={1}
            autoplay={{
              delay: 3000,
              disableOnInteraction: false,
            }}
            modules={[Autoplay]}
            loop={true}
            className="mySwiper h-fit w-full flex justify-between items-center"
          >
            {qoutes.map((item, index) => (
              <SwiperSlide
                className="w-full lg:p-12 p-4 lg:min-h-[200px] min-h-[150px] items-center justify-center"
                key={index}
                style={{
                  backgroundImage:
                    "linear-gradient(180deg, rgba(0, 21, 75, 0.5) 0%, rgba(0, 21, 75, 0) 237.41%), url(" +
                    quotesImages[index % 15] +
                    ")",
                  backgroundSize: "cover",
                  backgroundRepeat: "no-repeat",
                  display: "flex",
                }}
              >
                <div className="flex items-center justify-center">
                  <p className="lg:text-xl md:text-base text-xs text-justify lg:mt-2 md:text-center lg:ml-5 relative">
                    <Quotation className="w-10 h-10 -translate-y-5" />
                    &nbsp;{item}
                  </p>
                </div>
              </SwiperSlide>
            ))}
          </Swiper>
        </div>
      </div>
      <div className="">
        <div className="box h-[5rem] w-fit pl-10 relative flex justify-start items-start ml-[2rem] max-sm:ml-0 group mt-5">
          <div className="h-12 w-1.5 bg-[#004B95] absolute mr-[1rem] origin-left group-hover:w-full duration-300 group"></div>
          <h1 className="text-[24px] font-bold text-[#004B95] mt-[0.5rem] ml-[1rem] group-hover:text-white group-hover:translate-x-5 duration-300 capitalize">
            {selectedCat !== ""
              ? selectedCat.replace(/-/g, " ")
              : "Hot Products"}
          </h1>
        </div>
      </div>

      <div className="sm:flex sm:flex-row sm:gap-5 sm:mb-5 sm:mx-5 flex flex-col">
        <div className="sm:w-[30%] w-full sm:ml-[1%] mx-auto px-6">
          <BlogsList listdData={category} />
        </div>
        <div className="sm:w-[80%] mt-5 md:mt-0 px-4">
          <div className="">
            <div className="">
              {category.map((item, index) => {
                {
                  console.log(item);
                  console.log("selected category:" + subLink);
                }
                if (item[1] == selectedCat) {
                  return (
                    <div key={index} className="px-2">
                      {item[2].length !== 0 ? (
                        <div
                          id="subCategory"
                          class="border-[1px] rounded-lg lg:w-[100%] border-kijeka-blue mb-5"
                        >
                          <div class="overflow-hidden text-kijeka-blue">
                            <ul className="text-sm text-kijeka-blue flex items-center mnd:justify-center gap-x-5 flex-wrap py-2 md:px-10 px-5">
                              {item[2].map((subItem, subIndex) => {
                                return (
                                  <>
                                    {subLink == subItem[1] ? (
                                      <li
                                        key={subIndex}
                                        className="border-b-2 border-kijeka-blue bg-kijeka-blue text-white p-2 rounded-md"
                                      >
                                        <a href={`/${item[1]}/${subItem[1]}`}>
                                          {subItem[0]}
                                        </a>
                                      </li>
                                    ) : (
                                      <li key={subIndex} className="p-2">
                                        <a href={`/${item[1]}/${subItem[1]}`}>
                                          {subItem[0]}
                                        </a>
                                      </li>
                                    )}
                                  </>
                                );
                              })}
                            </ul>
                          </div>
                        </div>
                      ) : null}
                    </div>
                  );
                }
              })}
            </div>
          </div>
          <MainProducts product={products} />
        </div>
      </div>
    </div>
  );
};

export default BlogNew;
