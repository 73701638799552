import React from "react";
import ProductCard from "../AllProducts/ProductCard";
import { Autoplay } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/scrollbar";
import "swiper/css/autoplay";
import { Scrollbar } from "swiper";

function TableData({ data }) {
	return (
		<div className="md:h-fit md:w-[80.24%]  left-[9.87%] rounded-[10px] pb-5 sticky top-0">
			<div className="box h-[5rem] w-[25rem] relative flex justify-start items-start ml-[2rem] group mt-6">
				<div className="h-12 w-1.5 bg-[#004B95] absolute mr-[1rem] origin-left group-hover:w-[16rem] duration-300 group"></div>
				<h2 className="text-[24px] absolute font-bold text-[#004B95] mt-[0.5rem] ml-[1rem] group-hover:text-white group-hover:translate-x-5 duration-300">Related Products</h2>
			</div>
			<Swiper
				grabCursor={true}
				slidesPerView={window.innerWidth >= 640 && window.innerWidth <= 768 ? 2 : window.innerWidth >= 768 ? 4 : 1}
				spaceBetween={20}
				autoplay={{
					delay: 3000,
					disableOnInteraction: false,
				}}
				modules={[Autoplay, Scrollbar]}
				scrollbar={{
					hide: true,
				}}
				className="mySwiper"
				style={{ padding: "20px" }}
			>
				{data.slice(0, 6).map((product, index) => (
					<SwiperSlide key={index}>
						<ProductCard index={index} liked={product[3]} link={product[4]} title={product[0]} description={product[1]} img={process.env.REACT_APP_SERVER + product[2]} rating={product[7]} stars={product[6]} />
					</SwiperSlide>
				))}
			</Swiper>
			{/* <div className="h-[536px] w-full bg-[#FFFFFF] overflow-y-scroll">
				<table className="w-full border-separate border-spacing-y-[20px]">
					<thead className="bg-[#004B95] h-[50px] sticky top-0">
						<tr className="">
							<th className="w-[15%] justify-center not-italic font-semibold md:text-base text-base leading-8 text-white font-poppins tracking-wider">Model</th>
							<th className="w-[15%] justify-center not-italic font-semibold md:text-base text-base leading-8 text-white font-poppins tracking-wider">Image</th>
							<th className="w-[25%] justify-center not-italic font-semibold md:text-base text-base leading-8 text-white font-poppins tracking-wider">Item Name</th>

							<th className="w-[15%] justify-center not-italic font-semibold md:text-base text-base leading-8 text-white font-poppins tracking-wider">Select</th>
						</tr>
					</thead>
					<tbody className="bg-white h-[486px] overflow-y-auto">
						{data.map((product, index) => (
							<tr key={index} className="h-[120px] shadow-[0px_0px_15px_rgba(166,166,166,0.5)]">
								<td className="w-[15%]">
									<div className="text-center not-italic font-semibold md:text-lg text-base  leading-7 text-[#004B95] font-poppins">{product[5]}</div>
								</td>
								<td className="w-[15%]">
									<Link to={process.env.REACT_APP_SERVER + "/product/" + product[4]}>
										<div className="w-[100%] md:h-[100px] h-[80px] flex justify-center">
											<img src={process.env.REACT_APP_SERVER + product[2]} alt={product[2]} />
										</div>
									</Link>
								</td>
								<td className="w-[25%]">
									<Link to={process.env.REACT_APP_SERVER + "/product/" + product[4]}>
										<div className=" text-left not-italic font-semibold md:text-lg text-base  leading-7 text-[#004B95] font-poppins">{product[0]}</div>
									</Link>
								</td>

								<td className="w-[15%]">
									<div className="flex justify-center">
										<input type="checkbox" />
									</div>
								</td>
							</tr>
						))}
					</tbody>
				</table>
			</div> */}
		</div>
	);
}
export default TableData;
