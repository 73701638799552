import React, { useState } from "react";
import axios from "axios";

// Swiper
import SwiperCore, { Autoplay, EffectCoverflow } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/scrollbar";
import "swiper/css/autoplay";
import "swiper/css/effect-coverflow";
import "swiper/swiper-bundle.min.css";
import "swiper/swiper.min.css";
// Styles
import "../Product_Video/productvideo.css";
// Image
import Background from "../assets/images/Rectangle20.jpg";

SwiperCore.use([EffectCoverflow, Autoplay]);

const ProductVideos = () => {
    // Variable for youtube videos
    const [videos, setVideos] = useState([]);

    // Youtube video data
    if (videos.length === 0) {
        axios
            .get(process.env.REACT_APP_SERVER + "/api/youtubeVideoList/")
            .then((data) => {
                setVideos(data["data"]["data"]);
            })
            .catch((error) => console.log(error));
    }

    return (
        <>
            {/* Heading */}
            <div className="box h-[5rem] w-[25rem] relative flex justify-start items-start ml-[2rem] group mt-6">
                <div className="h-12 w-1.5 bg-[#004B95] absolute mr-[1rem] origin-left group-hover:w-[18rem] duration-300 group"></div>
                <p className="text-[24px] absolute font-bold text-[#004B95] mt-[0.5rem] ml-[1rem] group-hover:text-white group-hover:translate-x-5 duration-300">Product Videos</p>
            </div>

            {/* Youtube video slider */}
            <div style={{ backgroundImage: "url(" + Background + ")", backgroundSize: "auto", width: "100%", display: "flex", alignItems: "center", justifyContent: "center" }}>
                <Swiper
                    effect="coverflow"
                    grabCursor={true}
                    centeredSlides={true}
                    slidesPerView={window.innerWidth >= 640 && window.innerWidth <= 768 ? 2 : window.innerWidth >= 768 ? 3 : 1}
                    coverflowEffect={{
                        rotate: 50,
                        stretch: 0,
                        depth: 100,
                        modifier: 1,
                        slideShadows: false,
                    }}
                    autoplay={{
                        delay: 5000,
                        disableOnInteraction: false,
                    }}
                    initialSlide={2}
                    loop={true}
                    modules={[EffectCoverflow]}
                    className="mySwiper h-fit w-full"
                >
                    {videos.map((item, index) => (
                        <SwiperSlide key={index}>
                            <div className=" w-[100%] text-center h-48 sm:h-64 sm:64 my-10 px-3">
                                {/* Youtube video */}
                                <iframe className="w-full h-full object-cover mx-auto mt-3 rounded-xl overflow-hidden " src={item[1]} loading="lazy" title={item[0]} frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
                                <h2 className="font-bold text-white discriptionTruncate-1 h-5">{item[0]}</h2>
                            </div>
                        </SwiperSlide>
                    ))}
                </Swiper>
            </div>
        </>
    );
};

export default ProductVideos;
