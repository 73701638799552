import React, { useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay } from "swiper";
import "swiper/css";
import "swiper/css/scrollbar";
import "aos/dist/aos.css";
import axios from "axios";

// Components
import CategoryCard from "../CategoryCard/CategoryCard";
import DisplayedProduct from "./DisplayedProduct";

const MainProducts = () => {
	// Variables for product and category data
	const [productShown, setProductShown] = useState(-1);
	const [category, setCategory] = useState([]);

	//homeCategoryList
	if (category.length === 0) {
		axios
			.get(process.env.REACT_APP_SERVER + "/api/homeCategoryList/")
			.then((data) => {
				setCategory(data["data"]["data"]);
			})
			.catch((error) => console.log(error));
	}
	return (
		<>
			{/* Heading */}
			<div className="box h-[5rem] w-[25rem] relative flex justify-start items-start ml-[2rem] group mt-6">
				<div className="h-12 w-1.5 bg-[#004B95] absolute mr-[1rem] origin-left group-hover:w-[16rem] duration-300 group"></div>
				<p className="text-[24px] absolute font-bold text-[#004B95] mt-[0.5rem] ml-[1rem] group-hover:text-white group-hover:translate-x-5 duration-300">Our Products</p>
			</div>
			<div className="flex p-[10px] gap-[15px] max-w-full" id="ourProducts">
				{/* Products to be displayed when hover over category */}
				<DisplayedProduct productShown={productShown} data={category[productShown]} />
				<Swiper
					className="rounded-lg pb-0"
					slidesPerView={window.innerWidth > 640 ? 4 : 2}
					spaceBetween={20}
					autoplay={{
						delay: 2000,
						disableOnInteraction: false,
					}}
					loop={true}
					modules={[Autoplay]}
				>
					{category.map((item, index) => {
						if (index % 2 === 0) {
							let img = "";
							try {
								img = process.env.REACT_APP_SERVER + item.products[0][1];
							} catch {
								img = "";
							}
							let img1 = "";
							try {
								img1 = process.env.REACT_APP_SERVER + category[index + 1].products[0][1];
							} catch {
								img1 = "";
							}
							let component1 = <div></div>;
							try {
								{
									/* Category card 1 */
								}
								component1 = <CategoryCard index={index} link={item.categoryLink} title={item.categoryName} description={item.discription} img={img} rating={category[index].products[0][5]} stars={category[index].products[0][4]} setProductShown={setProductShown} />;
							} catch {}
							let component2 = <div></div>;
							try {
								{
									/* Category card 2 */
								}
								component2 = <CategoryCard index={index} link={category[index].categoryLink} title={category[index].categoryName} description={category[index].discription} img={img1} rating={category[index].products[0][5]} stars={category[index].products[0][4]} setProductShown={setProductShown} />;
							} catch {}
							return (
								// Slider for categories
								<SwiperSlide className="h-32" key={index}>
									{component1}
									{component2}
								</SwiperSlide>
							);
						} else {
							return <></>;
						}
					})}
				</Swiper>
			</div>
		</>
	);
};

export default MainProducts;
