import axios from 'axios';
import React, { useEffect, useState, Ref, useRef } from 'react'

function Form() {

    const name = useRef('');
    const email = useRef('');
    const mobile = useRef('');
    const country = useRef('');
    const company = useRef('');
    const message = useRef('');



    function submitData() {
        let formData = new FormData();

        formData.append('name', name.current.value);
        formData.append('email', email.current.value);
        formData.append('mobile', mobile.current.value);
        formData.append('country', country.current.value);
        formData.append('company', company.current.value);
        formData.append('message', message.current.value);



        if (name.current.value == '') {
            alert('Enter Name')
        }
        else if (email.current.value == '') {
            alert('Enter email')
        }
        else if (mobile.current.value == '') {
            alert('Enter mobile')
        }
        else if (company.current.value == '') {
            alert('Enter company')
        }
        else if (message.current.value == '') {
            alert('Enter message')
        }
        else {

            axios({
                url: 'https://www.kijeka.com/api/adForm/',
                method: "POST",
                data: formData
            }).then((e) => {

                name.current.value = '';
                email.current.value = '';
                mobile.current.value = '';
                country.current.value = '';
                company.current.value = '';
                message.current.value = '';

                alert("Your Inquiry is Succesfully submited")

            }).catch((E) => {
                console.log(E);
            })
        }




    }


    return (

        <div className=' space-y-3 w-full px-6  relative bg-[#ffffffd3]  backdrop-filter backdrop-blur-3xl rounded-lg p-3'>


            <div className=' font-semibold  font-body  py-2 text-kijeka-blue text-xl'>Inquiry Form</div>

            <div className=' w-full space-y-1 '>
                <p className=' w-full  font-bold '>Name:</p>
                <input type="text" id="name" ref={name} className=' bg-transparent focus-visible:outline-none w-full border-b-[1px] p-[5px] border-[#4a4a4a3b] rounded-md' />
            </div>
            <div className=' w-full space-y-1 '>
                <p className='font-bold  w-full'>Email:</p>
                <input type="Email" ref={email} className=' bg-transparent w-full border-b-[1px]  focus-visible:outline-none p-[5px] border-[#4a4a4a3b]  rounded-md' />
            </div>
            <div className=' w-full space-y-1 '>
                <p className='font-bold  w-full'>Mobile:</p>
                <input type="number" ref={mobile} className=' bg-transparent w-full border-b-[1px]  focus-visible:outline-none p-[5px] border-[#4a4a4a3b]  rounded-md' />
            </div>
            <div className=' w-full space-y-1 '>
                <p className='font-bold  w-full'>Country:</p>
                <input type="text" ref={country} className=' bg-transparent w-full border-b-[1px]  focus-visible:outline-none p-[5px] border-[#4a4a4a3b]  rounded-md' />
            </div>
            <div className=' w-full space-y-1 '>
                <p className='font-bold  w-full'>Company:</p>
                <input type="text" ref={company} className=' bg-transparent w-full border-b-[1px]  focus-visible:outline-none p-[5px] border-[#4a4a4a3b]  rounded-md' />
            </div>
            <div className=' w-full space-y-1 '>
                <p className='font-bold  w-full'>Message:</p>
                <input type="text" ref={message} className=' bg-transparent w-full border-b-[1px]  focus-visible:outline-none p-[5px] border-[#4a4a4a3b]  rounded-md' />
            </div>

            <div className=' pb-2'>
                <button onClick={submitData} className=' font-bold  text-white bg-kijeka-blue px-5 text-xl rounded-lg py-2'>Submit</button>
            </div>
        </div>

    )
}

export default Form
