import React from "react";
// import "./styles.css";
import YoutubeEmbed from "./YoutubeEmbed";

const Video = ({ videoLink }) => {
	const [isScrolled, setIsScrolled] = React.useState();

	window.addEventListener("scroll", () => {
		if (window.location.pathname.toString() === "/") {
			if (window.scrollY > 30) {
				setIsScrolled(true);
			}
			if (window.scrollY < 30) {
				setIsScrolled(false);
			}
		} else {
			setIsScrolled(true);
		}
	});

	return (
			<div className={isScrolled ? "z-50 hidden md:block" : "hidden"}>
				<div className="right bottom-5 align-baseline right-20 fixed flex flex-col z-50">
					<div className="">
						<div className="">
							<YoutubeEmbed embedId={videoLink} />
						</div>
						{/* Close Button */}
						<div
							id="closeButton"
							className="-left-3 absolute cursor-pointer -top-1 w-[2rem] h-[2rem] flex items-center justify-center rounded-full shadow-xl bg-white"
							onClick={() => {
								document.getElementById("youtubePop").classList.add("scale-y-0");
								document.getElementById("youtubePop").classList.add("opacity-0");
								document.getElementById("youtubePop").classList.add("scale-y-0");
								document.getElementById("closeButton").classList.add("opacity-0");
							}}
						>
							<div className="rounded-full p-1 cursor-pointer transition-all duration-300 shadow-xl">
								<svg width="15" height="15" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
									<path d="M9.40195 7.49122L16.0844 0.808716L17.9933 2.71762L11.3108 9.40012L17.9933 16.0826L16.0844 17.9915L9.40195 11.309L2.71945 17.9915L0.810547 16.0826L7.49305 9.40012L0.810547 2.71762L2.71945 0.808716L9.40195 7.49122Z" fill="#004B95" />
								</svg>
							</div>
						</div>
						{/* End */}
					</div>
				</div>
			</div>
	);
};

export default Video;
