import React from 'react'
import Hero from './subComponents/Hero.jsx'
import Form from './subComponents/Form.jsx'
function AdPage() {
    return (
        <div>
            <Hero />
        </div>
    )
}

export default AdPage
