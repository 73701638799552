import * as React from "react";
const Mail = (props) => (
  <svg
    width={20}
    height={21}
    viewBox="0 0 20 21"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      opacity={0.4}
      d="M18.3337 14.1128C18.3337 16.4472 16.467 18.3383 14.142 18.3466H14.1337H5.87533C3.55866 18.3466 1.66699 16.464 1.66699 14.1295V14.1211C1.66699 14.1211 1.67199 10.4177 1.67866 8.55517C1.67949 8.20542 2.07949 8.00962 2.35199 8.22717C4.33199 9.80442 7.87283 12.6803 7.91699 12.7179C8.50866 13.194 9.25866 13.4626 10.0253 13.4626C10.792 13.4626 11.542 13.194 12.1337 12.7087C12.1778 12.6794 15.6395 9.88976 17.6495 8.28658C17.9228 8.06819 18.3245 8.26399 18.3253 8.61291C18.3337 10.4613 18.3337 14.1128 18.3337 14.1128Z"
      fill="#004B95"
    />
    <path
      d="M17.8967 5.52234C17.1751 4.15679 15.7551 3.28491 14.1917 3.28491H5.87507C4.31173 3.28491 2.89173 4.15679 2.17007 5.52234C2.0084 5.82775 2.08507 6.20846 2.35423 6.42434L8.54173 11.3937C8.97507 11.7451 9.50007 11.92 10.0251 11.92C10.0284 11.92 10.0309 11.92 10.0334 11.92C10.0359 11.92 10.0392 11.92 10.0417 11.92C10.5667 11.92 11.0917 11.7451 11.5251 11.3937L17.7126 6.42434C17.9817 6.20846 18.0584 5.82775 17.8967 5.52234Z"
      fill="#004B95"
    />
  </svg>
);
export default Mail;
