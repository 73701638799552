import React from "react";
import { Outlet } from "react-router-dom";
import Navbar from "../Ad/Components/Navbar/Navbar";
import Footer from "../Ad/Components/Footer/Footer";

const Ad = () => {
  return (
    <div>
      <Navbar />
      <Outlet />
      <Footer />
    </div>
  );
};

export default Ad;
