import React from "react";

const Cookies = () => {
	// check for a varible in localstorage
	if (localStorage.getItem("kijekaCookie") === null || localStorage.getItem("kijekaCookie") === "true") {
		localStorage.setItem("kijekaCookie", "true");
		return (
			// Cookie container
			<div className="fixed bottom-0 w-full lg:px-8 px-4 py-2 bg-white z-50 origin-bottom transition-all duration-300 shadow-[0px_2px_20px_rgba(166,166,166,0.25)]" id="cookiesOverlay">
				<div className="flex lg:flex-row flex-col items-center justify-between">
					<p className="text-kijeka-blue font-medium text-justify">This website uses cookies to improve your experience. We'll assume you're ok with this, but you can opt-out if you wish.</p>
					<div className='flex items-center my-2 lg:my-0 gap-5'>
						<button
							className="text-kijeka-blue border border-kijeka-blue font-medium px-3 py-1 rounded-lg"
							onClick={() => {
								document.getElementById("cookiesOverlay").classList.add("scale-y-0");
								document.getElementById("cookiesOverlay").classList.add("opacity-0");
								localStorage.setItem("kijekaCookie", "false");
							}}
						>
							Cancel
						</button>
						<button
							className="bg-kijeka-blue text-white font-medium px-6 py-1 rounded-lg"
							onClick={() => {
								document.getElementById("cookiesOverlay").classList.add("scale-y-0");
								document.getElementById("cookiesOverlay").classList.add("opacity-0");
								localStorage.setItem("kijekaCookie", "false");
							}}
						>
							OK
						</button>
					</div>
				</div>
			</div>
		);
	} else {
		return <></>;
	}
};

export default Cookies;
