import React, {useEffect, useRef, useState} from "react";
import "animate.css";
import {Link} from "react-router-dom";
import axios from "axios";
import {Swiper, SwiperSlide} from "swiper/react";
import SwiperCore, {Autoplay, EffectFade, Navigation, Pagination} from "swiper";
// Import Swiper styles
import "swiper/css";
import "swiper/css/effect-fade";
import "swiper/css/navigation";
import "swiper/css/pagination";

SwiperCore.use([EffectFade, Autoplay, Navigation, Pagination]);

const Hero = () => {
    // Variables for text typing animation
    const [text, setText] = useState("");
    const fullText = "Kijeka Engineers";
    const [index, setIndex] = useState(0);

    const slideRef = useRef();
    const [images, setImages] = React.useState([]);

    if (images.length === 0) {
        axios.get(process.env.REACT_APP_SERVER + "/api/imageSlider/").then((res) => {
            let dataImage = res.data.data;
            setImages(dataImage);
        });
    }

    // Text typing animation logic
    useEffect(() => {
        if (index < fullText.length) {
            setTimeout(() => {
                setText(text + fullText[index]);
                setIndex(index + 1);
            }, 100);
        }
    }, [text, index]);
    return (
        // Hero container
        <div className="h-screen w-full flex items-center justify-center z-40 relative" ref={slideRef}>
            <Swiper
                spaceBetween={30}
                effect={"fade"}
                autoplay={{
                    delay: 2500,
                    disableOnInteraction: false,
                }}
                loop={true}
                modules={[EffectFade, Autoplay, Navigation, Pagination]}
                className="w-full h-full z-0"
            >
                {images.map((image, index) => {
                    return (
                        <SwiperSlide key={index} className="w-full h-full">
                            <img src={process.env.REACT_APP_SERVER + image} alt={image} title={image} loading="lazy"
                                 className="w-full h-full object-cover "/>
                        </SwiperSlide>
                    );
                })}
            </Swiper>

            <div className="w-full h-full absolute top-0 flex flex-col justify-center px-7 z-10">
                <div className="w-full flex flex-col md:flex-row items-center justify-between">
                    <div className="md:w-[40%] w-full animate__animated animate__fadeInLeft">
                        {/* Left image */}
                        <div className="lg:w-[500px] lg:h-[500px]">
                            <img src="/static/images/tool-1 (1).png"
                                 className="w-full h-full min-w-full min-h-full object-cover hidden md:block"
                                 alt="tooltool" title="tool" loading="lazy"/>
                        </div>
                    </div>
                    <div className="md:w-[60%] w-full">
                        {/* Kijeka engineers */}
                        <h1 className="text-white text-3xl font-semibold">{text}</h1>
                        <p className="text-white text-base my-4 mb-8 animate__animated animate__fadeInUp md:text-left text-justify">KIJEKA
                            ENGINEERS is a leading Indian material handling equipment manufacturer and supplier of high
                            quality material handling products and industrial machinery equipment since 1980.</p>
                        <div>
                            <Link to="/about"
                                  className="flex items-center gap-2 hover:gap-4 hover:bg-white border group border-white w-fit py-2 px-4 rounded-lg transition-all duration-300 cursor-pointer animate__animated animate__fadeInUp"
                                  style={{animationDelay: "0.5s"}}
                                  onMouseEnter={() => {
                                      document.getElementById("exploreArrow").style.stroke = "black";
                                  }}
                                  onMouseLeave={() => {
                                      document.getElementById("exploreArrow").style.stroke = "white";
                                  }}
                            >
                                <p className="text-white group-hover:text-black font-semibold tracking-[1px] transition-all duration-300">Explore</p>
                                <svg width="25" height="19" viewBox="0 0 32 19" fill="none"
                                     xmlns="http://www.w3.org/2000/svg">
                                    <path d="M20.0024 2L30 9.5L20.0024 17M2 9.5H29.72" style={{stroke: "white"}}
                                          id="exploreArrow" strokeWidth="2.5" strokeMiterlimit="10"
                                          strokeLinecap="round" strokeLinejoin="round"/>
                                </svg>
                            </Link>
                        </div>
                    </div>
                </div>
                {/* Scroll down button */}
                <div
                    className="w-full flex flex-col items-center justify-center mt-10 md:mt-0"
                    onClick={() => {
                        document.getElementById("ourProducts").scrollIntoView({
                            behavior: "smooth",
                            block: "center",
                            inline: "nearest"
                        });
                    }}
                >
                    <p className="mb-4 text-xl text-white font-medium tracking-[2px] cursor-pointer">Scroll Down</p>
                    <svg width="57" height="22" viewBox="0 0 57 22" fill="none" xmlns="http://www.w3.org/2000/svg"
                         className="down-arrow">
                        <path d="M3 3L26.4273 18.7713C27.5325 19.5153 28.9767 19.5218 30.0885 18.7877L54 3"
                              stroke="white" strokeOpacity="0.7" strokeWidth="4.5" strokeLinecap="round"/>
                    </svg>
                </div>
            </div>
        </div>
    );
};

export default Hero;
