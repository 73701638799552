import axios from "axios";
import React, { useState } from "react";

const ContactUsForm = () => {
	const [inputData, setInputData] = useState({
		heading1: "",
		heading2: "",
		lableOfName: "",
		textFiledName: "",
		lableOfQuery: "",
		textFiledOfQuery: "",
		lableOfEmail: "",
		textFiledOfEmail: "",
		lableOfAddress: "",
		textFiledOfAddress: "",
		lableOfCountry: "",
		textFiledOfCountry: "",
		lableOfSubscription: "",
		lableOfCompany: "",
		textFiledOfCompany: "",
		textOfSubmit: "",
	});
	if (inputData.heading1 === "" || inputData.heading2 === "") {
		axios.get(process.env.REACT_APP_SERVER + "/api/reachUsForm/").then((res) => {
			let data = res.data.data;
			setInputData(data);
		});
	}
	return (
		<div className="mt-10 mb-5">
			<ContactTitle />
			<div className="flex flex-col lg:flex-row md:px-12 px-2 pb-5 items-center justify-between relative lg:w-[90%]">
				{/* <!--left side--> */}
				<div className="text-kijeka-blue lg:w-[50%] w-full">
					<div className="mb-10">
						{/* <div className="lets text-[26px] max-sm:text-[22px] max-sm:leading-[30px] leading-[38px] font-bold">{inputData.heading1}</div> */}
						<div className="lets text-[26px] max-sm:text-[22px] max-sm:leading-[30px] leading-[38px] font-bold">{inputData.heading2}</div>
						<div className="lets text-[26px] max-sm:text-[22px] max-sm:leading-[30px] leading-[38px] font-bold">About Products!</div>
					</div>

					<div className="grid grid-cols-2 gap-y-5 md:w-max" style={{ gridTemplateColumns: "min-content auto" }}>
						<label for="name" className="md:w-[160px] font-bold text-[22px] md:pr-20 max-lg:text-[25px] max-md:text-[18px]">
							{inputData.lableOfName}
						</label>
						<input type="text" id="name" name="name" placeholder={inputData.textFiledName} className=" mt-1  border-b-[2px] font-medium text-[16px] focus:outline-none placeholder-[#85A4C3] border-[#85A4C3] max-sm:w-full max-lg:w-[100%] " />

						<label for="email" className=" detail font-bold text-[22px] max-lg:pr-20 max-lg:text-[25px]  max-md:text-[18px]">
							{inputData.lableOfEmail}
						</label>
						<input type="email" id="email" name="email" placeholder={inputData.textFiledOfEmail} className=" w-72 mt-1 border-b-[2px] font-medium text-[16px] focus:outline-none placeholder-[#85A4C3] border-[#85A4C3] max-sm:w-full  max-lg:w-[100%]" />

						<label for="mobileno" className="w-max detail font-bold text-[22px] max-lg:pr-20 max-lg:text-[25px] max-md:text-[18px]">
							Mobile No.
						</label>
						<input type="text" id="phoneNo" name="phoneNo" placeholder="Eg. +91 7777989922" className=" w-72 mt-1 border-b-[2px] font-medium text-[16px] focus:outline-none placeholder-[#85A4C3] border-[#85A4C3] max-sm:w-full  max-lg:w-[100%]" />
						<label for="country" className="w-max detail1 text-[#004B95] font-bold text-[22px] max-lg:pr-20  max-lg:text-[25px] max-md:text-[18px]">
							{inputData.lableOfCountry}
						</label>
						<input type="text" id="country" name="country" placeholder={inputData.textFiledOfCompany} className="w-72 mt-1 border-b-[2px] font-medium text-[16px] focus:outline-none placeholder-[#85A4C3] border-[#85A4C3] max-sm:w-full  max-lg:w-[100%]" />
					</div>
				</div>

				{/* <!--right side--> */}
				<div className="lg:w-[50%] text-kijeka-blue w-full lg:mt-0 mt-5">
					<div className="grid grid-cols-2 gap-y-5 md:w-max" style={{ gridTemplateColumns: "min-content auto" }}>
						<label for="company" className="w-[160px] detail1 text-[#004B95] max-lg:pr-20 font-bold text-[22px]  max-md:text-[18px]">
							{inputData.lableOfCompany}
						</label>
						<input type="text" id="companyName" name="companyName" placeholder={inputData.textFiledOfCompany} className="w-72 mt-1 border-b-[2px] font-medium text-[16px] focus:outline-none placeholder-[#85A4C3] border-[#85A4C3]   max-lg:w-[100%]" />
						<label for="query" className="w-max detail1 text-[#004B95] max-lg:pr-20 font-bold text-[22px]  max-md:text-[18px]">
							{inputData.lableOfQuery}
						</label>
						<input type="text" id="query" name="query" placeholder={inputData.textFiledOfQuery} className="w-72 mt-1 border-b-[2px] font-medium text-[16px] focus:outline-none placeholder-[#85A4C3] border-[#85A4C3] max-sm:w-full  max-lg:w-[100%]" />
						<label for="address" className="w-max detail1 text-[#004B95] max-lg:pr-20 font-bold text-[22px]   max-md:text-[18px]">
							{inputData.lableOfAddress}
						</label>
						<input type="text" id="address" name="address" placeholder={inputData.textFiledOfAddress} className="w-72 mt-1 border-b-[2px] font-medium text-[16px] focus:outline-none placeholder-[#85A4C3] border-[#85A4C3]  max-lg:w-[100%] max-sm:w-full" />
						<div className="g-recaptcha" data-sitekey="6LcP1-wkAAAAAJ7rGFlQwmL4DvUvmfIj6FljoNrX"></div>
					</div>
					<div className="flex items-center gap-x-2 mb-5">
						<input type="checkbox" checked="checked" id="isSubscribed" name="isSubscribed" />
						<label for="checkbox" className="text-[#004B95] font-normal text-[16px]">
							{inputData.lableOfSubscription}
						</label>
					</div>
					<div className="flex items-center justify-center md:justify-start gap-x-2">
						<button
							onClick={() => {
								let name = document.getElementById("name").value;
								let email = document.getElementById("email").value;
								let phoneNo = document.getElementById("phoneNo").value;
								let country = document.getElementById("country").value;
								let companyName = document.getElementById("companyName").value;
								let query = document.getElementById("query").value;
								let address = document.getElementById("address").value;
								let isSuscribed = document.getElementById("isSubscribed").value;
								if (name !== "" && email !== "" && phoneNo !== "" && country !== "" && companyName !== "" && query !== "" && address !== "") {
									let gifContainer = document.getElementById("GIF");
									gifContainer.classList.remove("opacity-0");
									gifContainer.classList.remove("scale-0");
									gifContainer.classList.add("opacity-100");
									gifContainer.classList.add("scale-100");
									setTimeout(() => {
										gifContainer.classList.remove("opacity-100");
										gifContainer.classList.remove("scale-100");
										gifContainer.classList.add("opacity-0");
										gifContainer.classList.add("scale-0");
										window.scrollTo(0, 0);
									}, 2000);
									let formData = new FormData();
									formData.append("name", name);
									formData.append("email", email);
									formData.append("phoneNo", phoneNo);
									formData.append("country", country);
									formData.append("companyName", companyName);
									formData.append("query", query);
									formData.append("address", address);
									formData.append("isSuscribed", isSuscribed);
									axios
										.post(process.env.REACT_APP_SERVER + "/api/contactus/", formData)

										.then((res) => {
											document.getElementById("name").disabled = true;
											document.getElementById("email").disabled = true;
											document.getElementById("phoneNo").disabled = true;
											document.getElementById("country").disabled = true;
											document.getElementById("companyName").disabled = true;
											document.getElementById("query").disabled = true;
											document.getElementById("address").disabled = true;
											document.getElementById("isSuscribed").disabled = true;
											document.getElementById("name").disabled = "";
											document.getElementById("email").disabled = "";
											document.getElementById("phoneNo").disabled = "";
											document.getElementById("country").disabled = "";
											document.getElementById("companyName").disabled = "";
											document.getElementById("query").disabled = "";
											document.getElementById("address").disabled = "";
											document.getElementById("isSuscribed").disabled = "";
										});
								} else {
									alert("Please fill all the fields");
								}
							}}
							className="text-[#004B95] h-[50px] tracking-[1px] max-sm:w-[130px] w-[168px] font-poppins text-[24px] border-2 border-[#004B95] rounded-lg font-medium hover:text-white hover:bg-[#004B95] hover:duration-700 duration-700 hover:cursor-pointer"
						>
							{inputData.textOfSubmit}
						</button>
						<a target="_blank" href="http://discovery.ariba.com/profile/AN01039790621" className="rounded-lg overflow-hidden border-2 border-[#004B95] h-[50px]  max-sm:w-[130px] w-[168px] hover:text-white hover:bg-[#004B95] duration-700 hover:cursor-pointer flex gap-2 items-center px-2 ">
							<p className="text-xs max-w-[100px] text-left font-medium ">Find us on Ariba Network</p>
							<svg id="outputsvg" xmlns="http://www.w3.org/2000/svg" className="h-8 w-8 flex justify-center items-center" width={40} height={40} viewBox="0 0 9000 9000">
								<g
									id="l01g9WtJe1thmfprMPy9f5f"
									fill="rgb(239,170,0)"
									style={{
										transform: "none",
									}}
								>
									<g>
										<path
											id="pA0XXWP6o"
											d="M 837 6684 c -6 -7 80 -179 163 -324 34 -60 136 -249 248 -460 25 -47 56 -102 69 -122 12 -21 23 -40 23 -42 0 -3 36 -70 80 -150 44 -79 80 -147 80 -150 0 -3 23 -43 50 -90 28 -47 50 -87 50 -89 0 -3 17 -36 38 -74 44 -77 103 -187 170 -313 24 -47 95 -177 157 -290 62 -113 129 -236 150 -275 37 -69 71 -133 125 -230 15 -27 49 -90 75 -140 27 -49 69 -128 95 -175 42 -76 112 -205 233 -430 22 -41 77 -142 122 -225 45 -82 101 -186 124 -230 23 -44 46 -87 51 -95 9 -14 167 -306 353 -650 29 -55 47 -87 126 -233 49 -90 90 -133 158 -167 65 -33 167 -33 238 -1 88 39 103 59 235 306 41 75 174 321 252 465 137 251 227 418 271 500 27 52 53 100 57 105 4 6 29 51 55 100 65 123 116 218 197 364 37 68 68 125 68 126 0 2 37 70 81 152 71 128 146 268 314 578 21 39 88 162 149 275 62 113 125 230 140 260 16 30 43 80 61 110 18 30 57 102 87 160 69 129 107 200 169 312 27 48 49 90 49 93 0 3 26 52 58 108 53 92 135 242 267 492 26 50 51 95 55 100 7 10 26 45 129 236 28 53 51 101 51 108 0 9 -73 12 -322 9 -306 -3 -325 -4 -369 -24 -78 -36 -117 -87 -234 -304 -60 -113 -131 -243 -156 -290 -26 -47 -64 -116 -84 -155 -21 -38 -64 -117 -95 -175 -32 -58 -76 -139 -98 -180 -21 -41 -64 -120 -94 -175 -31 -55 -73 -134 -95 -175 -41 -77 -81 -150 -125 -230 -14 -25 -46 -84 -71 -132 -26 -47 -55 -101 -65 -120 -10 -18 -40 -73 -67 -123 -26 -49 -71 -133 -100 -185 -29 -52 -70 -129 -92 -170 -74 -137 -105 -195 -128 -235 -13 -22 -43 -78 -67 -125 -25 -47 -83 -155 -130 -240 -96 -174 -130 -237 -233 -430 -149 -281 -227 -420 -235 -420 -12 0 -25 20 -78 120 -27 52 -76 145 -109 205 -33 61 -106 196 -163 300 -56 105 -113 208 -125 230 -12 22 -38 69 -58 105 -19 36 -49 92 -67 125 -18 33 -45 83 -60 110 -15 28 -56 104 -92 170 -36 66 -86 158 -111 205 -25 47 -82 153 -127 235 -45 83 -102 188 -127 235 -25 47 -73 135 -106 195 -33 61 -75 139 -95 175 -19 36 -70 129 -113 208 -44 78 -79 144 -79 145 0 2 -40 77 -90 167 -50 90 -90 165 -90 167 0 2 -39 74 -86 161 -48 86 -103 186 -122 222 -167 313 -182 333 -267 378 -50 27 -50 27 -370 30 -176 2 -324 0 -328 -4 z "
										/>
										<path id="pgqPDCXj0" d="M 2530 6680 c 0 -7 20 -49 44 -94 45 -84 216 -400 268 -496 17 -30 60 -109 96 -175 111 -205 128 -237 196 -359 36 -66 66 -121 66 -123 0 -3 56 -107 223 -413 16 -30 54 -98 83 -151 30 -54 54 -99 54 -101 0 -12 128 -232 136 -235 9 -3 293 510 355 638 22 47 22 47 -13 110 -20 35 -56 102 -80 149 -78 145 -124 232 -175 325 -28 50 -99 180 -158 290 -59 110 -137 254 -173 320 -35 66 -72 134 -81 150 -32 61 -82 113 -133 141 -53 29 -53 29 -380 32 -249 3 -328 1 -328 -8 z " />
										<path
											id="pai9nKspw"
											d="M 7610 6676 c -98 -26 -147 -72 -215 -201 -27 -49 -67 -124 -90 -165 -23 -41 -63 -113 -88 -160 -25 -47 -71 -132 -102 -190 -32 -58 -78 -143 -102 -190 -25 -47 -58 -107 -73 -135 -15 -27 -85 -156 -155 -285 -70 -129 -141 -260 -157 -290 -17 -30 -46 -84 -66 -120 -19 -36 -46 -85 -60 -110 -47 -86 -90 -166 -232 -430 -78 -146 -146 -269 -150 -275 -9 -13 -75 -135 -132 -246 -25 -46 -69 -129 -100 -184 -30 -55 -73 -134 -95 -175 -47 -89 -144 -267 -258 -475 -45 -82 -104 -190 -130 -240 -26 -49 -74 -137 -105 -195 -32 -58 -77 -141 -100 -185 -24 -44 -78 -143 -120 -220 -42 -77 -95 -174 -117 -215 -22 -41 -64 -119 -93 -173 -35 -65 -49 -102 -43 -108 7 -7 128 -9 329 -7 308 3 320 4 361 25 57 31 106 83 146 157 19 33 43 77 54 96 11 19 50 93 88 165 37 72 71 135 75 140 7 9 93 167 165 300 17 33 51 96 75 140 23 44 53 98 65 120 12 22 46 85 75 140 29 55 70 129 90 165 20 36 46 83 57 105 11 22 44 83 73 135 29 52 68 124 87 160 19 36 48 88 63 115 15 28 44 82 65 120 96 182 262 489 305 565 26 47 64 117 85 155 43 80 83 154 165 305 32 58 81 150 110 205 29 55 56 105 60 110 4 6 34 60 65 120 32 61 80 151 108 200 28 50 62 113 77 140 15 28 98 181 185 340 87 160 185 342 218 405 33 63 63 120 67 125 25 34 119 223 113 228 -12 13 -595 6 -643 -7 z "
										/>
									</g>
								</g>
							</svg>
						</a>
					</div>
				</div>

				<div className="backdrop-blur-sm bg-white opacity-0 scale-0 absolute w-full h-full flex items-center transition-all duration-300" id="GIF">
					<div className="w-96 h-96 mx-auto object-contain bg-blend-screen overflow-visible" style={{ backgroundImage: "url(/static/images/bgillustration1_1.gif)", backgroundSize: "cover", backgroundRepeat: "no-repeat" }}></div>
				</div>
			</div>
		</div>
	);
};

export default ContactUsForm;

const ContactTitle = () => {
	return (
		<div className="box h-[5rem] w-[10rem] sm:w-[25rem] relative flex justify-start items-start ml-[2rem] group">
			<div className="h-12  w-1.5 bg-[#004B95] absolute mr-[1rem] origin-left group-hover:w-[15rem] duration-300 group"></div>
			<p className="text-[24px] max-sm:text-[22px] absolute font-bold text-[#004B95] mt-[0.5rem] ml-[1rem] group-hover:text-white group-hover:translate-x-5 duration-300">Contact Us</p>
		</div>
	);
};
