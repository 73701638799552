import React, { useState } from "react";
import axios from "axios";

const LikeShare = (props) => {
	const [current, setCurrent] = useState(props.liked);
	const handleOnClick = () => {
		setCurrent((prev) => !prev);
		axios
			.get(process.env.REACT_APP_SERVER + "/api/likeProduct/?title=" + props.title)
			.then((data) => {
				console.log(data);
			})
			.catch((error) => {
				console.log(error);
			});
	};

	return (
		<div className="pt-[22px] relative ">
			<svg
				className="cursor-pointer"
				width={20}
				height={20}
				viewBox="0 0 23 25"
				fill="none"
				onClick={() => {
					document.getElementById("shareLinkInput").value = process.env.REACT_APP_SERVER + props.link;
					document.getElementById("shareTitle").innerHTML = props.title;
					document.getElementById("shareImage").src = props.image;
					document.getElementById("shareLinkedin").href = "https://www.linkedin.com/shareArticle?mini=true&url=" + encodeURI(window.location + props.link) + "&title=" + encodeURI(props.title) + "&summary=Kijeka Engineers PVT LTD.&source=LinkedIn";
					document.getElementById("shareTwitter").href = "https://twitter.com/intent/tweet?text=" + encodeURI(props.title) + "&url=" + encodeURI(window.location + props.link) + "&hashtags=Kijeka,Engineers,PVT,LTD";
					document.getElementById("shareFacebook").href = "https://www.facebook.com/sharer/sharer.php?u=" + encodeURI(window.location + props.link) + "&quote=" + encodeURI(props.title);
					document.getElementById("shareWhatsapp").href = "https://api.whatsapp.com/send/?phone=919879545352&text=" + encodeURI(props.title) + "%0AKijeka,Engineers,PVT,LTD%0A" + encodeURI(window.location + props.link) + "&type=phone_number&app_absent=0";
					document.getElementById("shareInstagram").href = "https://www.instagram.com/";
					document.getElementById("shareOverlay").classList.remove("scale-0");
					document.getElementById("shareOverlay").classList.remove("opacity-0");
					document.getElementsByTagName("BODY")[0].style.overflow = "hidden";
				}}
			>
				<path d="M15.3435 18.4271L7.66273 14.2025M7.65182 10.7712L15.3399 6.54257M21.937 20.1397C21.937 22.2538 20.379 23.9677 18.4572 23.9677C16.5354 23.9677 14.9774 22.2538 14.9774 20.1397C14.9774 18.0256 16.5354 16.3118 18.4572 16.3118C20.379 16.3118 21.937 18.0256 21.937 20.1397ZM21.937 4.82795C21.937 6.94206 20.379 8.65589 18.4572 8.65589C16.5354 8.65589 14.9774 6.94206 14.9774 4.82795C14.9774 2.71383 16.5354 1 18.4572 1C20.379 1 21.937 2.71383 21.937 4.82795ZM8.0179 12.4838C8.0179 14.598 6.45995 16.3118 4.53812 16.3118C2.6163 16.3118 1.05835 14.598 1.05835 12.4838C1.05835 10.3697 2.6163 8.65589 4.53812 8.65589C6.45995 8.65589 8.0179 10.3697 8.0179 12.4838Z" stroke="#004B95" strokeWidth={1.5} />
			</svg>
			<svg className="mt-[17px] cursor-pointer" onClick={handleOnClick} width={20} height={20} viewBox="0 0 25 25" fill={current === false ? "none" : "red "}>
				<path
					fillRule="evenodd"
					clipRule="evenodd"
					d="M15.3815 1.34373C14.0463 1.3229 12.6563 1.75519 11.5878 2.96873C10.5117 1.75727 9.10748 1.34373 7.8073 1.34373C4.61782 1.34373 1.42456 4.25832 1.42456 8.37498C1.42456 12.3562 3.73653 15.6771 6.03431 17.9416C7.05918 18.9511 8.17076 19.8484 9.35334 20.6208C9.83748 20.9333 10.2725 21.1802 10.6261 21.3521C10.8029 21.4375 10.9665 21.5083 11.1131 21.5594C11.2417 21.6041 11.4157 21.6562 11.5897 21.6562C11.7627 21.6562 11.9376 21.6041 12.0662 21.5594C12.2119 21.5083 12.3764 21.4375 12.5532 21.3521C12.9069 21.1802 13.3418 20.9333 13.825 20.6208C15.0079 19.8485 16.1198 18.9512 17.145 17.9416C19.4428 15.6771 21.7548 12.3562 21.7548 8.37498C21.7548 4.2479 18.5549 1.39582 15.3815 1.34373V1.34373Z"
					stroke={current === false ? "#004B95" : "red "}
					strokeWidth={1.5}
				/>
			</svg>
		</div>
	);
};

export default LikeShare;
