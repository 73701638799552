import React, { useState, useEffect } from "react";
import ByNewsDropdown from "./ByNewsDropdown";
import Card from "./Card";
import Jobcategory from "./Jobcategory";
import { Scheduledropdown } from "./Scheduledropdown";
import axios from "axios";

const DetailedCareer = () => {
	const [careerData, setCareerData] = useState([]);
	useEffect(() => {
		axios
			.get(process.env.REACT_APP_SERVER + "/api/careerList/")
			.then((res) => {
				setCareerData(res.data.data);
			})
			.catch((err) => {
				console.log(err);
			});
	}, []);

	return (
		<>
			<div className="box h-[5rem] w-[25rem] relative flex justify-start items-start ml-[2rem] group mt-24">
				<div className="h-14 w-1.5 bg-[#004B95] absolute mr-[1rem] origin-left group-hover:w-[18rem] duration-300 group"></div>
				<p className="text-[28px] absolute font-bold text-[#004B95] mt-[0.5rem] ml-[1rem] group-hover:text-white group-hover:translate-x-5 duration-300">Let's join We</p>
			</div>

			{/*===============================search =========================== */}

			<form>
				<label for="default-search" className="mb-2 text-sm font-medium  sr-only">
					Search
				</label>
				<div className="relative ml-5 my-3 sm:ml-6 sm:my-4 justify-between">
					<input type="search" id="default-search" className="w-[13rem] max-sm:w-[20rem] max-lg:w-[25rem] p-2 pl-5 text-[#004B95] border border-[#004B95] rounded-lg bg-[#ffff] focus:ring-blue-500 focus:border-blue-500 focus:outline-none  placeholder-[#004B95] placeholder sm:w-[15rem] md:w-[20rem] lg:w-[25rem] xl-w-[22rem]" placeholder="Search job by category , Title" required />

					<div className="absolute inset-x-[10rem] top-1/2 right-2 -translate-y-1/2 flex items-center ml-5 pointer-events-none sm:absolute max-sm:ml-28 max-lg:ml-20 sm:inset-x-[10rem] md:absolute md:inset-x-[12rem] lg:absolute lg:inset-x-[15rem]">
						<i className="fa fa-search text-[#004B95] text-[1rem]" aria-hidden="true"></i>
					</div>
				</div>
			</form>

			{/*============================title================================================ */}

			<div className="inline-flex items-center justify-center w-full">
				<hr className="w-[95%] h-0.5 my-8 bg-[#004895] border-0 rounded " />
				<div className="absolute lg:px-4 lg:-translate-x-1/2 lg:bg-white lg:left-1/2 ">
					<p className="text-[1rem] max-lg:bg-white font-bold max-sm:mt-12  text-[#004895] not-italic lg:leading-10  lg:p-[0.2rem] lg:mr-[2rem] max-sm:text-[15px] cursor-pointer  sm:text-[1.1rem] md:text-[1.2rem]  lg:[1.3rem] xl:text-[1.4rem]">Showing {careerData.length} Opportunities</p>
				</div>
			</div>

			{/*====================================Application Card======================================== */}

			{careerData.map((item, index) => {
				return (
					<div className="my-5" key={index}>
						<div className="lg:h-[30rem] max-sm:h-[37rem] mx-[2rem] p-6 bg-white border border-[#A6A6A680] rounded-lg shadow  sm:h-[18rem] xl:h-[15rem] ">
							<div className="flex space-x-10 sm:flex sm:space-x-40 md:flex md:space-x-60 lg:flex lg:space-x-80 xl:flex xl:justify-between">
								<a href={"/career-details/" + item.requisitionNumber}>
									<p className="text-[#004895] text-[0.8rem]  font-bold xl:text-[1rem] xl:text-bold">{item.jobTitle}</p>
								</a>
								<a href={"/career-details/" + item.requisitionNumber}>
									<p className="mb-2 text-[0.8rem] text-[#004895]  font-bold tracking-tight">Apply Now</p>
								</a>
								{/* <svg width="26" height="26" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg">
									<path d="M17.4208 19.393L8.59173 14.9785M8.57919 11.393L17.4166 6.97431M25 21.1826C25 23.3918 23.2091 25.1826 21 25.1826C18.7909 25.1826 17 23.3918 17 21.1826C17 18.9735 18.7909 17.1826 21 17.1826C23.2091 17.1826 25 18.9735 25 21.1826ZM25 5.18262C25 7.39176 23.2091 9.18262 21 9.18262C18.7909 9.18262 17 7.39176 17 5.18262C17 2.97348 18.7909 1.18262 21 1.18262C23.2091 1.18262 25 2.97348 25 5.18262ZM9 13.1826C9 15.3918 7.20914 17.1826 5 17.1826C2.79086 17.1826 1 15.3918 1 13.1826C1 10.9735 2.79086 9.18262 5 9.18262C7.20914 9.18262 9 10.9735 9 13.1826Z" stroke="#004B95" strokeWidth="1.5" />
								</svg> */}
							</div>

							<div className="flex space-x-3 my-3">
								<p className="text-[#101010] text-lg max-sm:text-sm max-lg:text-md font-semibold">
									Schedule : <span className=" text-lg max-sm:text-sm font-normal">{item.schedule}</span>{" "}
								</p>
								<p className="text-[#101010] text-lg max-sm:text-sm font-semibold">
									Job Category :<span className=" text-lg max-sm:text-sm font-normal">{item.jobCategory}</span>{" "}
								</p>
								<p className="text-[#101010] text-lg max-sm:text-sm font-semibold">
									Requisition Number : <span className=" text-lg max-sm:text-sm font-normal">{item.requisitionNumber}</span>{" "}
								</p>
							</div>

							<p className="mb-3 font-normal text-gray-700 ">{item.discription}</p>

							<div className="flex space-x-10 justify-between">
								<span className="text-[0.8rem] text-[#808080]">{item.date}</span>
								<p className="text-[0.8rem] text-[#004B95] ">{item.applied} already Applied</p>
							</div>
						</div>
					</div>
				);
			})}
		</>
	);
};

export default DetailedCareer;
