import React, { useState } from "react";
import axios from 'axios';

const InquiryForm = () => {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [mobile, setMobile] = useState("");
  const [country, setCountry] = useState("");
  const [company, setCompany] = useState("");
  const [message, setMessage] = useState("");

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!name) {
      alert('Name is required');
      return;
    }
    // const formData = { name, email, mobile, country, company, message };
    const formData = new FormData();
    formData.append("name",name)
    formData.append("email",email)
    formData.append("mobile",mobile)
    formData.append("country",country)
    formData.append("company",company)
    formData.append("message",message)
  
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_SERVER}/api/adForm/`,
        formData,
        {
          headers: {
            'Content-Type': 'multipart/form-data'
          }
        }
      );
      console.log('Form data sent successfully');
      // Reset the form fields after successful submission
      setName('');
      setEmail('');
      setMobile('');
      setCountry('');
      setCompany('');
      setMessage('');
    } catch (error) {
      console.error('There was a problem with the form submission:', error);
    }
  };

  return (
    <div className="w-full overflow-x-hidden flex justify-center">
      <form onSubmit={handleSubmit} className="w-full font-display">
        <div className="mb-4">
          <label
            className="block text-[#101010] text-sm font-bold"
            htmlFor="name"
          >
            Name:
          </label>
          <input
            id="name"
            type="text"
            name="name"
            value={name}
            onChange={(e) => setName(e.target.value)}
            className="appearance-none border-b rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline bg-transparent border-black/10 focus:border-black/30"
          />
        </div>
        <div className="mb-4">
          <label
            className="block text-[#101010] text-sm font-bold"
            htmlFor="email"
          >
            Email:
          </label>
          <input
            id="email"
            type="email"
            name="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            className="appearance-none border-b rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline bg-transparent border-black/10 focus:border-black/30"
          />
        </div>
        <div className="mb-4">
          <label
            className="block text-[#101010] text-sm font-bold"
            htmlFor="mobile"
          >
            Mobile:
          </label>
          <input
            id="mobile"
            type="tel"
            name="mobile"
            value={mobile}
            onChange={(e) => setMobile(e.target.value)}
            className="appearance-none border-b rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline bg-transparent border-black/10 focus:border-black/30"
          />
        </div>
        <div className="mb-4">
          <label
            className="block text-[#101010] text-sm font-bold"
            htmlFor="country"
          >
            Country:
          </label>
          <input
            id="country"
            type="text"
            name="country"
            value={country}
            onChange={(e) => setCountry(e.target.value)}
            className="appearance-none border-b rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline bg-transparent border-black/10 focus:border-black/30"
          />
        </div>
        <div className="mb-4">
          <label
            className="block text-[#101010] text-sm font-bold"
            htmlFor="company"
          >
            Company:
          </label>
          <input
            id="company"
            type="text"
            name="company"
            value={company}
            onChange={(e) => setCompany(e.target.value)}
            className="appearance-none border-b rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline bg-transparent border-black/10 focus:border-black/30"
          />
        </div>
        <div className="mb-4">
          <label
            className="block text-[#101010] text-sm font-bold"
            htmlFor="message"
          >
            Message:
          </label>
          <textarea
            id="message"
            name="message"
            value={message}
            onChange={(e) => setMessage(e.target.value)}
            className="resize-none appearance-none border-b rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline bg-transparent border-black/10 focus:border-black/30"
          />
        </div>
        <button className="bg-kijeka-blue text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline">
          Submit
        </button>
      </form>
    </div>
  );
};

export default InquiryForm;
