import React, { useEffect, useState } from "react";

//Components
import Hero from "../Components/Hero/Hero";
import OurClients from "../Components/OurClients/OurClients";
import ProductVideos from "../Components/Product_Video/ProductVideos";
import WhatPeopeSay from "../Components/WhatPeopleSay/WhatPeopeSay";
import MainProducts from "../Components/AllProducts/MainProducts";
import BlogSlider from "../Components/BlogSlider/BlogSlider";
import ContactUsForm from "../Pages/Contact/ContactUsForm";
import ShareProduct from "../Components/ShareProduct/ShareProduct";
import { Helmet } from "react-helmet";
import Modal from "./Modal";

const Homepage = () => {
  // Variables for loading
  const [loader, setLoader] = useState("block");
  const [opacity, setOpacity] = useState(1);
  // Loading animation
  useEffect(() => {
    setTimeout(() => {
      setOpacity(0);
      setTimeout(() => {
        setLoader("hidden");
      }, 300);
    }, 2000);
    // document.getElementsByTagName("BODY")[0].style.overflow = "hidden";
  }, []);

  const [showPopup, setShowPopup] = useState(false);

  useEffect(() => {
    const timer = setTimeout(() => {
      setShowPopup(true);
    }, 15000);

    return () => clearTimeout(timer);
  }, []);

  return (
    // Homepage container
    <div className="w-full overflow-x-hidden">
      <Helmet>
        <meta charSet="utf-8" />
        <title>Elevate with Kijeka: Material Handling Experts</title>
        <meta
          name="title"
          content="Elevate with Kijeka: Material Handling Experts"
        ></meta>
        <meta
          name="description"
          content="Welcome to Kijeka, India's premier material handling equipment manufacturer and supplier since 1980. Explore our extensive range of high-quality products."
        ></meta>
        <meta
          name="keywords"
          content="material handling equipment, equipment manufacturer, equipment supplier, high quality products, unique products, blogs, product videos, clients, testimonials, Indian material company"
        ></meta>
        <meta
          http-equiv="Content-Type"
          content="text/html; charset=utf-8"
        ></meta>
        <meta property="og:type" content="website"></meta>
        <meta property="og:url" content="https://www.kijeka.com/"></meta>
        <meta
          property="og:title"
          content="Kijeka Engineers Private Limited"
        ></meta>
        <meta
          property="og:description"
          content="Welcome to Kijeka, India's premier material handling equipment manufacturer and supplier since 1980. Explore our extensive range of high-quality products."
        ></meta>
        <meta
          property="og:image"
          content="/static/images/KijekaMeta.png"
        ></meta>
        <meta property="twitter:card" content="summary_large_image"></meta>
        <meta property="twitter:url" content="https://www.kijeka.com/"></meta>
        <meta
          property="twitter:title"
          content="Kijeka Engineers Private Limited"
        ></meta>
        <meta
          property="twitter:description"
          content="Welcome to Kijeka, India's premier material handling equipment manufacturer and supplier since 1980. Explore our extensive range of high-quality products."
        ></meta>
        <meta
          property="twitter:image"
          content="/static/images/KijekaMeta.png"
        ></meta>
        <link rel="canonical" href="https://www.kijeka.com/" />
        {/*Google Ads*/}
        {/*<script async src="https://www.googletagmanager.com/gtag/js?id=AW-11320408145"></script>*/}
      </Helmet>
      {/* Loading container */}
      <div
        className={
          loader +
          " fixed top-0 left-0 z-50 flex flex-col justify-center items-center h-screen w-full bg-white transition-all duration-300 opacity-" +
          opacity
        }
      >
        <div className="animate__animated animate__fadeInUp animate__delay-1s">
          <img
            src="/static/images/logoOnly.png"
            alt="logo"
            title="logo"
            loading="lazy"
            className="w-[10rem] h-[10rem] animate-pulse"
          />
        </div>
      </div>
      {showPopup && <Modal setShowPopup={setShowPopup} />}
      <Hero />
      <ShareProduct />
      <MainProducts />
      <BlogSlider />
      <ProductVideos />
      <OurClients />
      <WhatPeopeSay />
      <ContactUsForm />
    </div>
  );
};

export default Homepage;
