import React from "react";

const Quotation = () => {
	return (
		<>
			<span className="hidden sm:block">
				<svg width={26} height={23} viewBox="0 0 26 23" fill="none" xmlns="http://www.w3.org/2000/svg">
					<path d="M7.80002 1C3.75557 5.91111 -2.77331 16.7733 3.46669 20.9333" stroke="white" />
					<circle cx={6.06667} cy={16.5999} r={6.06667} fill="white" />
					<path d="M21.6667 1C17.6223 5.91111 11.0934 16.7733 17.3334 20.9333" stroke="white" />
					<circle cx={19.9334} cy={16.5999} r={6.06667} fill="white" />
				</svg>
			</span>
		</>
	);
};

export default Quotation;
