import "animate.css";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import React, { useEffect, useState } from "react";

//Components
import Footer from "./Components/Footer/Footer";
import JobAppForm from "./Components/JobApplicationForm/JobAppForm";
import JoinDetail from "./Components/JoinDetail/JoinDetail";
import Navbar from "./Components/Navbar/Navbar";
import Careers from "./Pages/Careers";
import ContactUs from "./Pages/ContactUs";
import Homepage from "./Pages/Homepage";
import KijekaButton from "./Components/KijekaButton/KijekaButton";
import Blog from "./Components/Blogs/Blog";
import BlogNew from "./Components/BlogsNew/BlogNew";
import Details from "./Components/ProductDetails/Details";
import About from "./Components/Aboutus/About";
import Cookies from "./Components/Cookies/Cookies";
import Privacy from "./Components/Privacy/Privacy";
import TermsCon from "./Components/TearmsCondition/TermsCon";
import Inquiry from "./Components/Inquiry/Inquiry";
import Page404 from "./Pages/404/Page404";
import InquiryForm from "./Pages/Form";
import Home from "./Outlet/home";
import Ad from "./Outlet/Ad";
import AdPage from "./Ad/Components/HomePage/Adpage";

function App() {
  //check if there exist a key products in local storage
  if (!localStorage.getItem("products")) {
    localStorage.setItem("products", JSON.stringify([]));
  }

  return (
    <Router>
      {/* <Navbar /> */}
      <Routes>
        <Route path="/" element={<Home />}>
          <Route path="/" element={<Homepage />} />
          <Route path="/careers" element={<Careers />} />
          <Route path="/about" element={<About />} />
          <Route path="/blog/:link" element={<Blog />} />
          <Route path="/blog" element={<Blog />} />
          <Route path="/contact" element={<ContactUs />} />
          <Route path="/career-details/:link" element={<JoinDetail />} />
          <Route path="/job-apply/:link" element={<JobAppForm />} />
          <Route path="/our-products" element={<BlogNew />} />
          <Route path="/:link" element={<BlogNew />} />
          <Route path="/:link/:subLink" element={<BlogNew />} />
          <Route path="/product/:link" element={<Details />} />
          {/* <Route path="/product-compare" element={<ProductCompare />} /> */}
          <Route path="/privacy-policy" element={<Privacy />} />
          <Route path="/terms-and-condition" element={<TermsCon />} />
          <Route path="/add-to-inquiry" element={<Inquiry />} />
          <Route path="/page-not-found" element={<Page404 />} />
          <Route path="/inquiry-form" element={<InquiryForm />} />
        </Route>
        <Route path="/ad" element={<Ad />}>
          <Route path="/ad" element={<AdPage />} />
        </Route>
      </Routes>
      {/* <Cookies />
      <KijekaButton />
      <Footer /> */}
    </Router>
  );
}

export default App;
