import React from "react";
import PropTypes from "prop-types";

const YoutubeEmbed = ({ embedId }) => (
	<div id="youtubePop" className="video-responsive w-full h-full object-cover mx-auto mt-3 rounded-xl overflow-hidden origin-bottom-right z-[100] transition-all duration-300">
		<iframe
			width="400"
			allow="autoplay"
			loop={true}
			height="228"
			src={`https://www.youtube.com/embed/${embedId}?autoplay=1&mute=1`}
			// allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
			allowFullScreen
			title="Embedded youtube"
		/>
	</div>
);

YoutubeEmbed.propTypes = {
	embedId: PropTypes.string.isRequired,
};

export default YoutubeEmbed;
