import React, { useState } from "react";
import axios from "axios";

// Swiper
import { Navigation, Scrollbar, A11y } from "swiper";
import SwiperCore, { Autoplay, EffectCoverflow, Pagination } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/scrollbar";
import "swiper/swiper-bundle.min.css";
import "swiper/swiper.min.css";

// Components
import Background2 from "../assets/images/background2.jpg";
import RevieStars from "./RevieStars";
import Quotation from "./Quotation";

SwiperCore.use([EffectCoverflow, Autoplay, Pagination]);
export const WhatPeopeSay = () => {
	// Variable for reviews
	const [review, setReview] = useState([]);

	// Review data
	if (review.length === 0) {
		axios
			.get(process.env.REACT_APP_SERVER + "/api/reviewFetcher/")
			.then((data) => {
				setReview(data["data"]["data"]);
			})
			.catch((error) => console.log(error));
	}

	return (
		<>
			{/* Heading */}
			<div className="box h-[5rem] w-[25rem] relative flex justify-start items-start ml-[2rem] group">
				<div className="h-12 w-1.5 bg-[#004B95] absolute mr-[1rem] origin-left group-hover:w-[20rem] duration-300 group"></div>
				<p className="text-[24px] absolute font-bold text-[#004B95] ml-[1rem] mt-[0.5rem] group-hover:text-white group-hover:translate-x-5 duration-300">What People Say</p>
			</div>

			{/* Review slider */}
			<div style={{ backgroundImage: "url(" + Background2 + ")", backgroundSize: "auto", width: "100%" }}>
				<Swiper
					modules={[Navigation, Pagination, Scrollbar, A11y, Autoplay]}
					spaceBetween={0}
					slidesPerView={window.innerWidth > 640 ? 2 : 1}
					navigation={window.innerWidth > 640 ? true : false}
					autoplay={{
						delay: 2500,
						disableOnInteraction: false,
					}}
				>
					{review.map((item, index) => (
						<SwiperSlide key={index}>
							{/* Client review */}
							<div className="mt-5 mb-5 lg:px-14 px-2">
								<div className="max-w-md mx-auto min-h-[200px] max-h-[200px] py-4 w-full sm:w-[14rem] bg-[#D9D9D91A] border-2 border-[#787575] backdrop-blur-sm rounded-xl shadow-md overflow-hidden md:max-w-2xl lg:w-[100%] xl:w-[100%] md:w-[100%] 2xl:w-[100%]">
									<div className="flex flex-col sm:flex-row items-center justify-between">
										<div className="md:shrink-0 hidden sm:block">
											<img className="w-[4rem] ml-11 object-cover xl:w-[8rem] md:w-[6rem] rounded-lg" src={item.image} onerror="this.onerror=null; this.src='/static/images/logoOnly.png'" alt="Modern building architecture" title="Modern building architecture" loading="lazy" />
										</div>
										<div className="lg:p-5 p-4">
											<Quotation />
											<div className="lg:ml-[3rem]">
												<p className="lg:mr-[2rem] text-1 text-white discriptionTruncate xl:text-[0.9rem] md:text-[0.6rem]">{item.review}</p>
												<RevieStars stars={item.rating} />
												<div className="flex space-x-0.5 pt-[0.5rem] xl:pt-[1rem] sm:pt-[0.5rem]  ">
													<p className="text-white mr-[2rem] text-[0.8rem] xl:text-[0.8rem] sm:text-[0.7rem]">{item.name}</p>
													<p className="text-white mr-[2rem] text-[0.8rem] xl:text-[0.8rem] sm:text-[0.7rem]">{item.time}</p>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
						</SwiperSlide>
					))}
				</Swiper>
			</div>
		</>
	);
};

export default WhatPeopeSay;
