import React from "react";
import { Helmet } from "react-helmet";
const Page404 = () => {
	return (
		<div className="w-full h-screen flex flex-col items-center justify-center">
			<Helmet>
				<meta charSet="utf-8" />
				<title>404 Page Not Found | Kijeka Engineers Private Limited</title>

				<meta name="title" content="404 Page Not Found | Kijeka Engineers Private Limited"></meta>
				<meta name="description" content="Oops! Looks like the page you were looking for couldn't be found. Kijeka is a leading provider of top-quality handling equipment solutions for industries worldwide. We apologize for any inconvenience caused by this error and encourage you to explore our website to discover innovative solutions that can help you achieve your business goals."></meta>
				<meta
					name="keywords"
					content="Kijeka,Kijeka Engineers Private Limited,below hook//hoist mounted drum lifter,chain drum lifter,counterbalance fully powered drum lifter tilter,drainer 4 wheel drum truck,drum dollies,drum lever bar/drum positioning tool,drum palletizer that pour drum,drum storage rack,drum tilter trolleyes,drum trolley(multipurpose),drum trolleyes 3 wheelers,drum trolleyes 3 wheelers(pw3 new),drum trolleyes 3 wheelers pw3,drum trolleyes 4 wheelers  pw4,drum trollery 3 wheel (open top drum),drum wrench,forklift/crane mounded drum rotator,fully powered drum lifter tilter,hydraulic drum palletizer advance,hydraulic drum truck,low-profile drum caddies,manual drum lifter tilter,manual drum upender,overhead drum lifter,portable drum storage racks,portable drum storage racks(for 2 drums),safety drum faucets/ Tap,semi powered drum lifter tilter (with manual rotating),semi powered drum lifter tilter (with power rotating),stand for drum storage rack,v-shaped based hydraulic drumstacker,Forklift Drum Grab"
				></meta>
				<meta http-equiv="Content-Type" content="text/html; charset=utf-8"></meta>
				<meta property="og:type" content="website"></meta>
				<meta property="og:url" content="https://www.kijeka.com/page-not-found/"></meta>
				<meta property="og:title" content="404 Page Not Found | Kijeka Engineers Private Limited"></meta>
				<meta property="og:description" content="Oops! Looks like the page you were looking for couldn't be found. Kijeka is a leading provider of top-quality handling equipment solutions for industries worldwide. We apologize for any inconvenience caused by this error and encourage you to explore our website to discover innovative solutions that can help you achieve your business goals."></meta>
				<meta property="og:image" content="/static/images/KijekaMeta.png"></meta>
				<meta property="twitter:card" content="summary_large_image"></meta>
				<meta property="twitter:url" content="https://www.kijeka.com/page-not-found/"></meta>
				<meta property="twitter:title" content="404 Page Not Found | Kijeka Engineers Private Limited"></meta>
				<meta property="twitter:description" content="Oops! Looks like the page you were looking for couldn't be found. Kijeka is a leading provider of top-quality handling equipment solutions for industries worldwide. We apologize for any inconvenience caused by this error and encourage you to explore our website to discover innovative solutions that can help you achieve your business goals."></meta>
				<meta property="twitter:image" content="/static/images/KijekaMeta.png"></meta>
				<link rel="canonical" href="https://www.kijeka.com/page-not-found/" />
			</Helmet>
			<div className="text-center mt-5">
				<h1 className="text-kijeka-blue font-bold lg:text-9xl text-3xl tracking-widest">404</h1>
				<p className="text-kijeka-blue font-bold lg:text-5xl text-3xl">Page Not Found</p>
			</div>
		</div>
	);
};

export default Page404;
