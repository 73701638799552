import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import axios from "axios";
const JobAppForm = () => {
	document.title = "Kijeka | Job Apply";
	const params = useParams();
	const careerLink = params["link"];
	const [careerData, setCareerData] = useState({
		jobTitle: "",
		schedule: "",
		jobCategory: "",
		requisitionNumber: "",
		detailDiscription: "",
	});

	const resetForm = () => {
        document.getElementById("name").value = "";
        document.getElementById("email").value = "";
        document.getElementById("mobileNo").value = "";
        document.getElementById("country").value = "";
        document.getElementById("address").value = "";
        document.getElementById("terms&condition").checked = false;
        document.getElementById("resume").value = "";
    };

	useEffect(() => {
		axios
			.get(process.env.REACT_APP_SERVER + "/api/careerList/")
			.then((res) => {
				let data = res.data.data;
				let flag = false;
				for (let i = 0; i < data.length; i++) {
					if (data[i]["requisitionNumber"] === careerLink) {
						setCareerData(data[i]);
						flag = true;
					}
				}
				if (flag === false) {
					window.location.href = "/career";
				}
			})
			.catch((err) => {
				console.log(err);
			});
	}, []);
	return (
		<>
			<div className="box h-[5rem] w-[25rem] relative flex justify-start items-start ml-[2rem] group mt-24 max-sm:w-[15rem]">
				<div className="h-14  w-1.5 bg-[#004B95] absolute mr-[1rem] origin-left group-hover:w-[18rem] duration-300 group"></div>
				<p className="text-[28px] max-sm:text-[25px] absolute font-bold text-[#004B95] mt-[0.5rem] ml-[1rem] group-hover:text-white group-hover:translate-x-5 duration-300 ">Join Us!</p>
			</div>
			<div className="my-5 ml-10">
				<h1 className="text-[#004895] text-xl font-bold">{careerData["jobTitle"]}</h1>
			</div>
			<div className="flex ml-10 gap-5">
				<p className="text-[#004895] text-lg font-bold">
					Schedule : <span className=" text-lg max-sm:text-[15px] md:text-[1.1rem] font-normal">{careerData["schedule"]}</span>
				</p>
				<p className="text-[#004895] text-lg font-bold">
					Job Category : <span className=" text-lg md:text-[1.1rem] font-normal">{careerData["jobCategory"]}</span>
				</p>

				<p className="text-[#004895] text-lg font-bold">
					Requisition Number : <span className=" text-lg md:text-[1.1rem] font-normal">{careerData["requisitionNumber"]}</span>
				</p>
			</div>
			<div>
				<div className="my-3 ml-10 grid grid-cols-2" style={{ gridTemplateColumns: "min-content auto" }}>
					<input type="hidden" name="requisitionNumber" id="requisitionNumber" value={careerData["requisitionNumber"]} />
					<label className="w-28 py-1 max-sm:pr-11 mt-1 text-lg text-kijeka-blue font-bold">Name :</label>
					<input type="text" name="name" id="name" className="p-2 w-[16.5rem] h-[1.5rem]  py-2 mt-2  border border-[#004895]  rounded-md focus:outline-none focus:ring-1 focus:ring-blue-600   md:w-[26.5rem] lg:w-[16.5rem] xl:w-[16.5rem]  max-sm:w-[14rem] " required />
					<label className="w-28 py-1 mt-1 max-sm:pr-12 text-lg text-[#004895] font-bold">Email :</label>
					<input type="email" name="email" id="email" className=" p-2 w-[16.5rem] h-[1.5rem] py-2 mt-2 border border-[#004895] rounded-md  focus:outline-none focus:ring-1  focus:ring-blue-600 md:w-[26.5rem] lg:w-[16.5rem] xl:w-[16.5rem] max-sm:w-[14rem]" required />
					<label className="w-28 md:mr-2 py-1 mt-1 max-sm:pr-3 text-lg text-[#004895] font-bold">Mobile No :</label>
					<input type="text" name="number" id="mobileNo" className=" p-2 w-[16.5rem] h-[1.5rem] py-2 mt-2 border border-[#004895]  rounded-md focus:outline-none  focus:ring-1 focus:ring-blue-600 md:w-[26.5rem] lg:w-[16.5rem] xl:w-[16.5rem] max-sm:w-[14rem]" required />
					<label className="w-28 py-1 mt-1 text-lg max-sm:pr-7 text-[#004895] font-bold">Country :</label>
					<input type="text" name="country" id="country" className=" p-2 w-[16.5rem] h-[1.5rem] py-2  mt-2 border border-[#004895] rounded-md focus:outline-none focus:ring-1 focus:ring-blue-600 md:w-[26.5rem] lg:w-[16.5rem] xl:w-[16.5rem] max-sm:w-[14rem]" required />
					<label className="w-28 py-1 mt-1 text-lg text-[#004895] max-sm:pr-7 font-bold">Address :</label>
					<input type="text" name="address" id="address" className="p-2 w-[16.5rem] h-[1.5rem] py-2 mt-2 border border-[#004895] rounded-md  focus:outline-none  focus:ring-1 focus:ring-blue-600 md:w-[26.5rem] lg:w-[16.5rem] xl:w-[16.5rem] max-sm:w-[14rem]" required />
					<label className="w-28 py-1 max-sm:mt-5 mt-1 md:mt-3 text-lg text-[#004895] font-bold" id="resumeContainer">
						Upload Resume{" "}
					</label>
					<input
						type="file"
						id="resume"
						name="resume"
						className="block  w-[16.5rem] max-sm:mt-3 text-sm text-[D9D9D9] file:mr-4 file:py-2 file:px-4 file:rounded-full file:border-0 file:text-sm md:mt-3 file:font-semibold file:bg-[#D9D9D9] file:text-[#000]  hover:file:bg-violet-100"
						required
						accept=".pdf"
						onInputCapture={() => {
							let resumeInput = document.getElementById("resume");
							resumeInput.addEventListener("change", () => {
								if (resumeInput.files.length > 0) {
									const fileName = document.getElementById("resume").files[0].name;
								}
							});
						}}
					/>
				</div>
				<div className="ml-10 my-2">
					<input type="checkbox" id="terms&condition" name="chek" value="chek" className={`form-check-input mr-3`} required />
					<label className="w-28 py-1 lg:mt-1 max-sm:pr-5 text-[0.7rem] max-sm:text-[15px] text-[#004895] md:text-[15px]">Agree All term and conditions</label>
				</div>
				<div className="ml-10 my-2">
					<input
						type="submit"
						id="apply"
						value="Apply Now"
						className='w-[30%] sm:w-[20%] md:w-[20%]  lg:w-[10%] xl:w-[10%] h-[2rem] py-1  px-5 mr-2 mb-2 text-sm font-medium text-[#004895] focus:outline-none bg-white rounded-lg border border-[#004895] hover:bg-gray-100 hover:text-blue-700 focus:z-10 focus:ring-4 focus:ring-gray-200    "'
						onClick={() => {
							let name = document.getElementById("name").value;
							let email = document.getElementById("email").value;
							let mobileNo = document.getElementById("mobileNo").value;
							let country = document.getElementById("country").value;
							let address = document.getElementById("address").value;
							let termscondition = document.getElementById( "terms&condition").value;
							let resume = document.getElementById("resume").files[0];
							if (name === "" || email === "" || mobileNo === "" || country === "" || address === "" || termscondition.checked === false || document.getElementById("resume").files.length === 0) {
								alert("Fill all the details.");
							} else {
								let formData = new FormData();
								formData.append("requisitionNumber", document.getElementById("requisitionNumber").value);
								formData.append("name", document.getElementById("name").value);
								formData.append("email", document.getElementById("email").value);
								formData.append("number", document.getElementById("mobileNo").value);
								formData.append("country", document.getElementById("country").value);
								formData.append("address", document.getElementById("address").value);
								formData.append("resume", document.getElementById("resume").files[0]);
								console.log(name, email, mobileNo, country, address, resume);

								axios({
									url: process.env.REACT_APP_SERVER + "/api/jobEntry/",
									method: "POST",
									headers: {
										"Content-Type": "multipart/form-data",
									},
									data: formData,
								})
									.then((res) => {
										console.log(res);
										if (res.status === 200) {
											alert("Your application has been submitted successfully.");
											window.location.reload();
										}
									})
									.catch((err) => {
										console.log(err);
									});
							}
						}}
					/>
					<input type="submit" id="cancel" value="Cancel" onClick={()=> resetForm()} className='w-[30%] sm:w-[20%] md:w-[20%] lg:w-[10%] xl:w-[10%] h-[2rem] py-1  px-5 mr-2 mb-2 text-sm font-medium text-[#004895] focus:outline-none bg-white rounded-lg border border-[#004895] hover:bg-gray-100 hover:text-blue-700 focus:z-10 focus:ring-4 focus:ring-gray-200    "' />
				</div>
			</div>
		</>
	);
};

export default JobAppForm;
