import React from "react";
const BlogsList = ({ listdData, selectedCat }) => {
  return (
    <div className="w-full md:min-w-[230px] lg:min-w-[300px] max-h-[calc(100vh-7rem)] h-fit transition-all duration-300 border-[#004B95] border-[1px] rounded-[10px] bg-white overflow-hidden sticky top-24">
      <div className="box h-[5rem] w-fit pl-6 relative flex justify-start items-start group mt-5">
        <div className="h-12 w-1.5 bg-[#004B95] absolute mr-[1rem] origin-left group-hover:w-full duration-300 group"></div>
        <p className="text-[22px] font-bold text-[#004B95] mt-[0.5rem] ml-[0.7rem] group-hover:text-white group-hover:translate-x-5 duration-300 capitalize truncate pr-5">
          Categories
        </p>
      </div>

      <div className=" flex justify-between px-[20px] items-center relative border-[1px] border-[#004B95] rounded-[5px] h-[38px] mx-[10%] w-[80%]">
        <input
          type="text"
          name="catSearch"
          id="catSearch"
          onKeyUp={() => {
            let categories = document.querySelectorAll(".category");
            let value = document.getElementById("catSearch").value;
            categories.forEach((item) => {
              if (
                item
                  .getAttribute("data-category")
                  .toLowerCase()
                  .includes(value.toLowerCase())
              ) {
                item.classList.remove("hidden");
              } else {
                item.classList.add("hidden");
              }
            });
          }}
          placeholder="Search here"
          className=" font-medium leading-[30px]  outline-none focus:outline-none  tracking-[5%] text-[#004B95] w-[80%]"
        />
        <i className="fa-solid fa-magnifying-glass text-[#004B95] relative"></i>
      </div>

      <div className="w-full max-h-[calc(100vh-7rem-190px)] h-fit transition-all duration-300 mb-5 px-[20px] mt-[10px] overflow-y-scroll overflow-x-hidden">
        <ul>
          {listdData.map((item, index) => {
            return (
              <a
                href={item[2].length === 0 ? `/${item[1]}` : `/${item[1]}`}
                key={index}
                data-category={item[0] + "," + item[2]}
                className="category group"
              >
                <div className=" border-b-[2px] border-[#004a9533]">
                  <li
                    title={item[0]}
                    className="font-poppins font-medium text-[1rem] h-10 leading-[30px] text-[#004B95] list-disc pt-[12px] discription truncate-1 group-hover:font-bold transition-all duration-300 flex items-center justify-between"
                  >
                    <p className="text-[15px] group-hover:scale-110 origin-left transition-all duration-300">
                      &nbsp;{item[0]}
                    </p>
                    {item[2].length !== 0 ? (
                      <svg
                        fill="currentColor"
                        className="bi bi-chevron-down w-4 h-4 text-kijeka-blue group-hover:rotate-180 transition-all duration-300"
                        viewBox="0 0 16 16"
                      >
                        <path
                          fillRule="evenodd"
                          d="M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z"
                        />
                      </svg>
                    ) : (
                      <></>
                    )}
                  </li>
                  {item[2].length !== 0 ? (
                    <div
                      id="subCategory"
                      class="grid grid-rows-[0fr] transition-[grid-template-rows] duration-500 rounded-lg group-hover:grid-rows-[1fr]"
                    >
                      <div class="overflow-hidden text-kijeka-blue">
                        <ul className="ml-3 mt-2 text-sm text-kijeka-blue flex flex-col gap-y-2">
                          {item[2].map((subItem, subIndex) => {
                            return (
                              <li key={subIndex}>
                                <a href={`/${item[1]}/${subItem[1]}`}>
                                  &#x2022;&nbsp;{subItem[0]}
                                </a>
                              </li>
                            );
                          })}
                        </ul>
                      </div>
                    </div>
                  ) : (
                    <></>
                  )}
                </div>
                {/* <div className="bg-[#004a9533] h-0.5 mt-3 rounded-full"></div> */}
              </a>
            );
          })}
        </ul>
      </div>
    </div>
  );
};

export default BlogsList;
