import React from "react";

const Map = () => {
  return (
    <div className="flex items-center justify-center py-5 z-0">
      <iframe
        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3673.347308475022!2d72.27832671506673!3d22.974252723897223!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x395ea303015a1033%3A0x8e9bde04c2eb5c9e!2sKijeka%20Engineers%20Private%20Limited!5e0!3m2!1sen!2sin!4v1678180633573!5m2!1sen!2sin"
        width="1246"
        height="571"
        className="w-[90%] rounded-xl shadow-2xl shadow-neutral-400"
      />
    </div>
  );
};

export default Map;
